import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/reports/transaction-details/:transactionid/:accountid',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const TransactionDetailContainer = require('./containers/TransactionDetailContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'transactionDetail', reducer, })
      injectSagas(store, sagas)

      callback(null, TransactionDetailContainer)
    }, 'transactionDetail')
  }
})
