import React from 'react'
import { OutlinedInput, FormControl, InputLabel, Select, MenuItem, } from '@mui/material'
import { createTheme, ThemeProvider, } from '@mui/material/styles'
import { withStyles } from '@mui/styles'

const styles = () => ({
  slim: {
    '&&': {
      paddingBottom: '10px',
      paddingTop: '10px',
    },
  },
  slimLabel: {
    '&&': {
      top: '-6px',
    }
  },
  slimLabelShrink: {
    '&&': {
      transform: 'translate(0px, -12px) scale(0.75)!important',
    },
  },
})

const theme = createTheme({
  palette: {
    primary: {
      light: '#167cd4',
      main: '#167cd4',
      dark: '#167cd4',
    },
  },
  typography: {
    useNextVariants: true,
  },
})


const MaterialSelect = ({
  classes,
  value,
  onChange,
  style = { width: '100%', marginTop: '30px', },
  required = false,
  disabled = false,
  label = false,
  name = '',
  displayEmpty,
  options = [{ value: 'test', label: 'test', }, ],
}) => (
  <ThemeProvider theme={theme}>
    <FormControl style={style} variant="outlined" required={required}>
      <InputLabel
        htmlFor={name}
        classes={{
          root: classes.slimLabel,
          shrink: classes.slimLabelShrink,
        }}
      >{label}</InputLabel>
      <Select
        value={value}
        disabled={disabled}
        displayEmpty={displayEmpty}
        onChange={((e) => onChange(e.target.value))}
        inputProps={{
          classes: {
            select: classes.slim,
          },
        }}
        input={
          <OutlinedInput
            name={name}
            id={name}
            labelWidth={0}
          />
        }
      >
        {
          options.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
        }
      </Select>
    </FormControl>
  </ThemeProvider>
)

export default withStyles(styles)(MaterialSelect)
