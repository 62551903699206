import React from 'react'
import PropTypes from 'prop-types'
import './Loader.scss'

const Loader = () => (
  <div className="overlay">
    <div className="loader" />
  </div>
)


export default Loader
