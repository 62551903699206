import { injectReducer, injectSagas } from 'store'

export default (store) => ({
  path: '/customers-report/:accountId/:customerId',
  getComponent(nextState, cb) {
    require.ensure([], (require) => {
      const CustomerReportContainer = require('./containers/CustomerDetailContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'customerDetail', reducer })
      injectSagas(store, sagas)

      cb(null, CustomerReportContainer)
    }, 'customerDetail')
  }
})
