import React from 'react'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, } from '@mui/material'
import { withStyles, } from '@mui/styles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const styles = () => ({
  root: {
    '&&': {
      color: '#167cd4',
      paddingTop: '5px',
      paddingBottom: '5px',
      '&$checked': {
        color: '#167cd4',
      },
    },
  },
  checked: {},
})

const RadioButtons = ({
  classes,
  value,
  row,
  style = { width: '100%', marginTop: '20px', },
  disabled = false,
  required = false,
  label = 'Test',
  onChange,
  options = [{ value: '1', label: 'test', help: '', }, ],
}) => (
  <FormControl style={style} required={required} disabled={disabled}>
    <FormLabel style={{ color: 'rgba(0, 0, 0, 0.54)', }}>{label}</FormLabel>
    <RadioGroup
      value={value}
      row={row}
      onChange={onChange}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={
            <Radio classes={{
              root: classes.root,
              checked: classes.checked,
            }}
            />
          }
          label={
            <span style={{ display: 'flex', alignItems: 'center', }}>
              {option.label}
              {(option.help && option.help !== '') &&
              <Tooltip title={option.help} style={{ marginLeft: '7px', }}>
                <HelpOutlineIcon/>
              </Tooltip>
              }
            </span>
          }
          style={{ marginBottom: 0, }}
        />
      ))}
    </RadioGroup>
  </FormControl>
)

export default withStyles(styles)(RadioButtons)
