import { createActions, } from 'reduxsauce'

// ------------------------------------
// Constants
// ------------------------------------
const { Types, Creators, } = createActions({
  fetchAccountRequest: ['loginData', ],
  fetchAccountSuccess: ['data', ],
  fetchAccountFailure: ['error', ],
  fetchAccountInvalid: ['data', ],
  updateLastLogin: ['loginData', ],
  updateLastLoginFailure: ['error', ],
  updateMerchantUserSettings: ['id', 'data', ],
  updateMerchantUserSettingsFailure: ['error', ],
  clearAccount: [],
  updateAccountRequest: ['data', ],
  updateAccountSuccess: ['data', ],
  updateAccountFailure: ['error', ],
  updateEmailRequest: ['data', ],
  updateEmailSuccess: ['data', ],
  updateEmailFailure: ['error', ],
  sendMerchantTokenRequest: ['payload', ],
  sendMerchantTokenSuccess: [],
  sendMerchantTokenFailure: ['error', ],
  validateToken: ['token', ],
  validateTokenSuccess: [],
  validateTokenFailure: ['error', ],
  setAccountDropdown: ['data', ],
  accessRulesRequest: ['accountId', ],
  accessRulesSuccess: ['result', ],
  accessRulesFailure: ['error', ],
  permissionsRequest: ['userId', ],
  permissionsSuccess: ['result', ],
  permissionsFailure: ['error', ],
  fetchAdminRoles: [],
  fetchAdminRolesAlreadyFectched: [],
  fetchAdminRolesSuccess: ['data', ],
  fetchAdminRolesError: ['error', ],
  fetchSiteSettings: [],
  fetchSiteSettingsSuccess: ['data', ],
  fetchSiteSettingsError: ['error', ],
  setMerchantAccountId: ['accountId'],
  setMerchantAccountIdSuccess: ['accountId'],
  setMerchantAccountIdError: ['error'],
  getSelectedMerchantAccountId: [],
  getSelectedMerchantAccountIdSuccess: [],
  getSelectedMerchantAccountIdError: ['error']
})

export const AccountActionTypes = Types
export default Creators
