const styles = () => ({
  cssLabel: {
    '&&': {
      top: '-7px',
      '&$cssFocused': {
        color: '#167cd4',
      },
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    '&&': {
      height: '40px',
      '&$cssFocused $notchedOutline': {
        borderColor: '#167cd4',
      },
    },
  },
  notchedOutline: {},
  slimLabelShrink: {
    '&&': {
      transform: 'translate(0px, -9px) scale(0.75)!important',
    },
  },
})

export default styles
