import immutablePersistenceTransform from 'services/ImmutablePersistenceTransform'
import { createTransform, } from 'redux-persist'
import _ from 'lodash'

const authTransform = createTransform(
  (inboundState, key) => {
    let state = null

    if (key === 'auth') {
      state = _.pick(inboundState, ['authToken', 'isAuthenticated', ])
    } else {
      state = inboundState
    }

    return state
  },
  (outboundState, key) => outboundState,
)

const accountTransform = createTransform(
  (inboundState, key) => {
    let state = null

    if (key === 'account') {
      state = _.pick(inboundState, ['data', ])
    } else {
      state = inboundState
    }

    return state
  },
  (outboundState, key) => outboundState,
)

const REDUX_PERSIST = {
  active: true,
  reducerVersion: '1',
  // blacklist: ['nav', 'startup'], // reducer keys that you do NOT want stored to persistence here
  whitelist: ['auth', 'account', ], // Optionally, just specify the keys you DO want stored to
  // persistence. An empty array means 'don't store any reducers' -> infinitered/ignite#409
  transforms: [
    immutablePersistenceTransform,
    authTransform,
    accountTransform,
  ],
}

export default REDUX_PERSIST
