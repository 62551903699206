export const DASHBOARD = 'dashboard'

export const MANAGE_MERCHANTS = 'manage_merchants'

export const MERCHANT_PROFILES = 'merchant_profiles'

export const PARTNER_PROFILES = 'partner_profiles'

export const RATE_PROFILES = 'rate_profiles'

export const USER_ADMIN = 'user_admin'

export const REPORTS = 'reports'

export const TOOLS = 'tools'
