import { injectReducer, injectSagas, } from 'store'


export default (store) => ({
  path: '/users/:mid',
  /*  Async getComponent when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack -require callback  */
      const UsersInfoContainer = require('./containers/UsersInfo.jsx').default
      const reducer = require('./modules/reducer.js').default
      const sagas = require('./modules/sagas.js').default

      injectReducer(store, { key: 'userPage', reducer, })
      injectSagas(store, sagas)

      /*  Return getComponent   */
      cb(null, UsersInfoContainer)

    /* Webpack named bundle   */
    }, 'merchant-info')
  },
})
