import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  /* Async getComponent when route matches */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack -require callback  */
      const SubscriptionsContainer = require('./containers/SubscriptionsContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      /*  Add the reducer to the store on key 'subscriptions'  */
      injectReducer(store, { key: 'subscriptions', reducer, })
      injectSagas(store, sagas)

      /*  Return getComponent   */
      cb(null, SubscriptionsContainer)
    }, 'subscriptions')
  },
})
