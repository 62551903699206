import React, { Component, } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { ThemeProvider, createTheme, } from '@mui/material/styles'
import Loader from 'components/Loader'
import './TOSAcceptModal.scss'

const theme = createTheme({
  palette: {
    primary: {
      light: '#0d2b40',
      main: '#0d2240',
      dark: '#0d1740',
    },
    secondary: {
      light: '#ed2121',
      main: '#d11010',
      dark: '#910505',
    },
  },
  typography: {
    useNextVariants: true,
  },
})

class TOSAcceptModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false,
    }
  }

  _handleClose = () => {
    this.props.handleClose()
  }

  handleChange = (value, property) => this.setState({ [property]: value, })

  _submitTOS = () => {
    this.setState({ isSubmitting: true, })
    this.props.onSubmit()
  }

  render() {
    const { open, } = this.props
    const { isSubmitting } = this.state

    return (
      <Dialog
        className='tos-accept-dialog'
        open={open}
        onClose={() => {
          this._handleClose()
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <React.Fragment>
          <DialogTitle id="responsive-dialog-title">
            <span>T.O.S Update</span>
          </DialogTitle>
          <DialogContent className='tos-accept'>
            <div>
              <p>
                Are you sure you would like to update T.O.S of this merchant?
              </p>
            </div>
          </DialogContent>

          {isSubmitting ?
            <Loader />
            :
            <DialogActions>
              <ThemeProvider theme={theme}>
                <div style={{ width: '100%', marginBottom: '10px', textAlign: 'center', }}>
                  <Button
                    onClick={() => {
                      this._handleClose()
                    }}
                    color="secondary"
                    variant="contained"
                    style={{ marginRight: '10px', }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      this._submitTOS()
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Yes
                  </Button>
                </div>
              </ThemeProvider>
            </DialogActions>
          }
        </React.Fragment>
      </Dialog>
    )
  }
}

export default TOSAcceptModal
