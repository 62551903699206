import React from 'react'
import gravatar from 'gravatar'

export default function Avatar({ email }) {
  const avatarURL = email
    ? gravatar.url(email, { s: '96', r: 'g', d: 'mp' }, true)
    : ''
  return (
    <span className='avatar-wrapper'>
      <span
        className='avatar'
        title='Gravatar.com user image'
        style={{ backgroundImage: `url(${avatarURL})` }}
      />
    </span>
  )
}
