import React from 'react'
import _ from 'lodash'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import './CheckboxGroup.scss'
/**
 * @param  Options Array/Object with value and label describing the checkbox
 * @param  values, Checkboxes values
 * @param  name,  String, the name of the checkboxgroup
 * @param  errors, Checkboxes errors
 * @param  onBlur, Func heckboxes values
 */
class CheckboxGroup extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: props.values ? props.values : []
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.values, prevProps.values)) {
      this.setState({
        values: this.props.values
      })
    }
  }

  _onCheck(name, checked) {
    const values = this.state.values

    if (checked) {
      values.push(name)
    } else {
      _.remove(values, (n) => n == name)
    }

    this.setState({
      values
    }, () => {
      this.props.onChange(values)
    })
  }

  render() {
    const { values, } = this.state
    const { options, name, errors, onBlur, styles } = this.props
    return (
      <div className="checkboxgroup-component">
        {
          options.map((option, idx) => (
            <FormControlLabel
              key={`checkbox-fc-${idx}`}
              classes={{
                label: styles.label,
              }}
              sx={styles.label}
              control={
                <Checkbox
                  key={`checkbox-${idx}`}
                  label={ option.label }
                  name={`${name}-${idx}`}
                  onChange={(evt) => { this._onCheck(option.value, evt.currentTarget.checked) }}
                  onBlur={() => { onBlur(values) }}
                  checked={ values.indexOf(option.value) > -1 }
                  sx={styles.root}
                  classes={{
                    root: styles.root,
                    checked: styles.checked,
                  }}
                  color="primary"
                />
              }
              label={ option.label }
            />
          ))
        }

        { errors.length > 0 &&
          <div className="checkbox-group-error">
            <ul>
              {
                errors.map((error, idx) => <li key={`${idx}-error`}>{ error }</li>)
              }
            </ul>
          </div>
        }
      </div>
    )
  }
}

export default CheckboxGroup
