import { injectReducer, injectSagas } from 'store'

export default (store) => ({
  path: '/transaction-detail/:accountId/:transactionId',
  getComponent(nextState, cb) {
    require.ensure([], (require) => {
      const TransactionReportContainer = require('./containers/TransactionDetailContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'transactionDetail', reducer })
      injectSagas(store, sagas)

      cb(null, TransactionReportContainer)
    }, 'transactionDetail')
  }
})
