import { injectReducer, } from 'store'

export default () => ({
  path: '/apply-confirmation',

  /*  Async getComponent when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack -require callback  */
      const ConfirmationLanding = require('./components/ConfirmationLandingView').default

      /*  Return getComponent   */
      cb(null, ConfirmationLanding)

    /* Webpack named bundle   */
    }, 'confirmation-landing')
  },
})
