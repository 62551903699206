import React, { Component, } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './NavigationItem.scss'

class NavigationItem extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isActive: false,
  }

  state = { isActive: this.props.isActive, }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive !== prevProps.isActive) {
      this.setState({ isActive: this.props.isActive, })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  _toggleVisibility = () => {
    this.setState({
      isActive: !this.state.isActive,
    }, () => {
      this.props.onClick(this.props.name)
    })
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return
    }

    this.setState({
      isActive: false,
    })
  }

  render() {
    const { onClick, name, url, render: renderProp, } = this.props
    const { isActive, } = this.state

    const isHome = window.location.pathname === '/admin'
    const isCurrentLocation = (name !== 'dashboard' && window.location.pathname.includes(url))
      || (name === 'tools' && window.location.pathname.includes('/bulk-tools'))

    return (
      <div
        ref={(node) => this.node = node}
        className={classnames('navigation-item',
          { active: isActive, current: isCurrentLocation || (name.toLowerCase() === 'dashboard' && isHome) })}
        onClick={() => { this._toggleVisibility() }}
      >
        {renderProp(isActive)}
      </div>
    )
  }
}

export default NavigationItem
