import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactPhoneInput from 'mui-phone-number'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import MaskedInput from 'react-text-mask'
import Selector from 'components/Selector'
import CheckboxGroup from 'components/CheckboxGroup'
import CheckboxGroupMUI from 'components/CheckboxGroupMUI'
import FileDropZone from 'components/FileDropZone'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'

import './FormField.scss'
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'


/**
 * Generic field component for all field types in a form
 * @prop {Func} onChange: Callback for the onChange envent of the input.
 * @prop {Object} restInput: All the normal attr for an html input.
 * @prop {String} type  : Type of the input to show (for now can be select, text, password, number, email)
 * @prop {String} label : Label for the field
 * @prop {Array} options: The options for the selector if the type is select
 * @prop {String} placeholder
 * @prop {Object} meta  : Object passed through the redux-form Field.
 */
export const FormField = ({
  input: { onChange, ...restInput },
  onKeyPress,
  isChecked,
  checkboxLabel,
  checkboxInput,
  toggleGroupStyles,
  toggleButtonStyles,
  toggleButtonSelectedStyles,
  checked,
  type,
  required,
  fullWidth,
  InputLabelProps,
  InputProps,
  SelectProps,
  FormHelperTextProps,
  label,
  labelName,
  boldLabel,
  options,
  placeholder,
  helperText,
  multiline,
  rows,
  meta: { touched, error = [], warning, },
  showLabel,
  onOpen,
  onClose,
  readonly,
  currentValue,
  disabled,
  className,
  ...restFormField
}) => {
  const showError = (touched && error.length > 0 && type !== 'checkboxgroup')

  let input = <input type={type} {...restInput} onChange={!readonly && onChange} disabled={disabled} className="field" placeholder={placeholder} />

  if (type === 'textarea') {
    input = <textarea {...restInput} rows={rows} onChange={!readonly && onChange} disabled={disabled} className="field" placeholder={placeholder} />
  }

  if (type === 'select') {
    input = (
      <Selector
        className={classnames(['field', className])}
        options={options}
        onChange={(option) => { onChange(option.value) }}
        {...restInput}
        onOpen={onOpen}
        onClose={onClose}
        placeholder={placeholder}
      />
    )
  }

  if (type === 'date') {
    input = (
      <MaskedInput
        {...restInput}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ]}
        className="field"
        placeholder={placeholder || 'XX/XX/XXXX'}
        guide
      />
    )
  }

  if (type === 'checkboxgroup') {
    input = (
      <CheckboxGroup
        {...restInput}
        onChange={(data) => { onChange(data.join(',')) }}
        onBlur={(data) => restInput.onBlur()}
        options={options}
        values={restInput.value ? restInput.value.split(',') : []}
        errors={touched && error.length > 0 ? error : []}
      />
    )
  }

  if (type === 'checkboxgroupmui') {
    input = (
      <CheckboxGroupMUI
        {...restInput}
        onChange={(data) => { onChange(data.join(',')) }}
        onBlur={(data) => restInput.onBlur()}
        options={options}
        values={restInput.value ? restInput.value.split(',') : []}
        errors={touched && error.length > 0 ? error : []}
        styles={{
          label: checkboxLabel,
          root: checkboxInput,
          checked,
        }}
      />
    )
  }

  if (type === 'buttongroupmui') {
    input = (
      <ToggleButtonGroup
        value={currentValue}
        {...restInput}
        {...restFormField}
        onChange={(e, v) => {
          onChange(v)
        }}
        classes={{
          root: toggleGroupStyles
        }}
      >
        {(options.map((amt) => (
          <ToggleButton
            key={amt.value}
            value={amt.value}
            name={name}
            classes={{
              root: toggleButtonStyles,
              selected: toggleButtonSelectedStyles
            }}
          >{amt.label}</ToggleButton>
        )))}
      </ToggleButtonGroup>
    )
  }

  if (type === 'file') {
    input = (
      <FileDropZone
        {...restInput}
        onChange={(data) => { onChange(data) }}
        values={(restInput.value ? restInput.value : [])}
      />
    )
  }

  let commonMaterialType
  if (type === 'material-text') {
    commonMaterialType = 'text'
  }
  if (type === 'material-password') {
    commonMaterialType = 'password'
  }
  if (type === 'material-email') {
    commonMaterialType = 'email'
  }
  if (type === 'material-hidden') {
    commonMaterialType = 'hidden'
  }
  if (type === 'material-number') {
    commonMaterialType = 'number'
  }

  if (commonMaterialType) {
    input = (<TextField
      type={commonMaterialType}
      error={showError}
      required={required}
      fullWidth={fullWidth}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      FormHelperTextProps={FormHelperTextProps}
      helperText={helperText}
      onKeyPress={onKeyPress}
      {...restInput}
      {...restFormField}
    />)
  }

  if (type === 'dollar-amount') {
    input = (<TextField
      type="number"
      error={showError}
      required={required}
      fullWidth={fullWidth}
      label={label}
      placeholder={placeholder}
      InputLabelProps={InputLabelProps}
      onChange={onChange}
      InputProps={{
        inputProps: {
          min: '0.01',
          max: '100000',
          step: '0.01',
          'data-number-to-fixed': 2,
          'data-number-step-factor': 100,
        },
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        ...InputProps
      }}
      FormHelperTextProps={FormHelperTextProps}
      helperText={helperText}
      onKeyPress={onKeyPress}
      {...restInput}
      {...restFormField}
    />)
  }

  if (type === 'material-countrycode') {
    input = (<ReactPhoneInput
      defaultCountry={'us'}
      onlyCountries={['us', 'ca', ]}
      disableAreaCodes
      countryCodeEditable={false}
      autoFormat={false}
      error={showError}
      required={required}
      fullWidth={fullWidth}
      label={label}
      onChange={onChange}
      inputClass="material-countrycode-label"
      inputProps={{
        disabled: true,
        id: 'countryCodeInput',
        onClick: (e) => {
          const lastChild = e.target.lastElementChild
          if (lastChild && lastChild.id === 'countryCodeInput') {
            e.currentTarget.children[0].children[0].click()
          }
        },
        ...InputProps,
      }}
      FormHelperTextProps={FormHelperTextProps}
      helperText={helperText}
      {...restInput}
    />)
  }

  if (type === 'material-checkbox') {
    input = (<FormControlLabel
      classes={{
        label: checkboxLabel,
      }}
      control={
        <Checkbox
          classes={{
            root: checkboxInput,
            checked,
          }}
          sx={checkboxInput}
          checked={isChecked}
          onChange={onChange}
          color="primary"
        />
      }
      label={label}
      sx={ checkboxLabel }
    />)
  }

  if (type === 'material-select') {
    input = (<TextField
      type={commonMaterialType}
      error={showError}
      required={required}
      fullWidth={fullWidth}
      select
      SelectProps={{
        SelectDisplayProps: {
          style: { color: 'white', },
        },
        ...SelectProps,
      }}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      helperText={helperText}
      {...restInput}
      {...restFormField}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>)
  }

  if (type === 'material-radio') {
    input = (<div>
      {options.map((opt, key) => {
        opt.value
        return (
          <div key={key}>
            <FormControlLabel
              key={key}
              control={
                <Radio
                  checked={currentValue === opt.value}
                  onChange={onChange}
                  value={opt.value}
                  inputProps={{
                    name: `radio-${labelName}-${opt.value}`
                  }}
                  aria-label={labelName}
                  color="primary"
                  label={opt.label}
                  {...restFormField}
                />
              }
              label={opt.label} />
          </div>
        )}
      )}
    </div>)
  }

  if (type === 'material-radio-twenty-three') {
    input = (<div>
      <div style={{ marginBottom: '5px', }}>{label}</div>
      {options.map((key, item) => (
        <FormControlLabel
          key={key}
          style={{ margin: '0 40px 0 -15px' }}
          control={
            <Radio
              checked={currentValue === item}
              onChange={onChange}
              value={item}
              inputProps={{
                name: `radio-${labelName}-${item}`
              }}
              aria-label={labelName}
              color="primary"
              label={key || item}
              {...restFormField}
            />
          }
          label={key || item} />
      ))}
    </div>)
  }

  return (
    <div className={classnames('form-field-component', { 'has-error': touched && error.length > 0, }, { 'disabled': disabled, }, )}>
      {(showLabel && type !== 'checkboxgroup' && type !== 'file') &&
        <span className="label">{label}</span>
      }
      <div className={classnames('field-container', { fullwidth: type === 'file', })}>
        {input}
        {showError && type !== 'file' && <span className="error">{error[0]}</span>}
        {showError && type === 'file' && <span className="fileError">{error[0]}</span>}
      </div>
    </div>
  )
}

FormField.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  placeholder: PropTypes.string,
  restInput: PropTypes.object,
  options: PropTypes.array,
  meta: PropTypes.object,
}

FormField.defaultProps = {
  showLabel: true,
}

export default FormField
