import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/about-us',

  getComponent(nextState, cb) {
    require.ensure([], (require) => {
      const AboutUsPage = require('./components/AboutUsPage').default
      cb(null, AboutUsPage)
    }, 'about-us')
  },
})
