import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router'
import _ from 'lodash'
import Avatar from 'components/Avatar'
import classnames from 'classnames'
import StackSportsMark from 'assets/stack-mark-red.svg.js'
import StackPayTextLogo from 'assets/stack-pay-text.svg.js'
import './Header2024.scss'
import NavigationBarContainer from '../../containers/NavigationBarContainer'
import { Drawer } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'


function Header(props) {
  const { account, settings, logout, router } = props
  const [showUserMenuDropdown, setShowUserMenuDropdown] = useState(false)
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)
  const [alphaByScroll, setAlphaByScroll] = useState(0)
  const [activeLink, setActiveLink] = useState(null)
  const [navClasses, setNavClasses] = useState(['default'])
  const [windowWidth, setWindowWidth] = useState(parseInt(window.innerWidth, 10))
  const [navCollapsed, setNavCollapsed] = useState(windowWidth < 1024)

  window.settings = settings
  window.checkURLforToolbar()

  const avatarEmail = account.data.profile ? account.data.profile.email : ''

  const handleScroll = () => {
    const newScrollYPosition = document.querySelector('#main-layout').scrollTop / 100
    setAlphaByScroll(newScrollYPosition)
  }

  const handleResize = () => {
    setWindowWidth(parseInt(window.innerWidth, 10))
  }

  const toggleNavCollapse = () => {
    delete navClasses.default
    if (navCollapsed) {
      setNavClasses(['expanded-nav'])
    } else {
      setNavClasses(['collapsed-nav'])
    }

    setShowUserMenuDropdown(false)
    setNavCollapsed(!navCollapsed)
  }

  const theme = createTheme({
    palette: { background: { paper: '#000C66' } },
    typography: { useNextVariants: true },
  })

  // initial / on updated effect
  useEffect(() => {
    if (account.data.profile && !settings) {
      props.fetchSiteSettings()
    }
  }, [ account ])

  useEffect(() => {
    if (navClasses.includes('default')) {
      setNavCollapsed(windowWidth < 1024)
    }
  }, [ windowWidth ])

  useEffect(() => {
    setActiveLink(router.location.pathname)
  }, [ router.location.pathname ])

  useEffect(() => {
    const mainLayout = document.querySelector('#main-layout')
    if (mainLayout) {
      mainLayout.addEventListener('scroll', handleScroll)
    }
    window.addEventListener('resize', _.debounce(handleResize, 100))
    return () => {
      const mainLayout = document.querySelector('#main-layout')
      if (mainLayout) {
        mainLayout.removeEventListener('scroll', handleScroll)
      }
      window.removeEventListener('resize', _.debounce(handleResize, 100))
    }
  }, [])

  return (
    <div className={classnames(['app-header-wrapper', ...navClasses])}>
      <Link to='/admin' onClick={() => {
        setActiveLink('dashboard')
        setShowUserMenuDropdown(false)
      }} className='logo-link'>
        <h1 className='site-logo' id='site-logo'>
          <span className='logo-svg'>
            <StackSportsMark id='logo-mark'/>
            <StackPayTextLogo id='logo-text'/>
          </span>
          <span className='site-name'>Stack Pay Merchant Portal</span>
        </h1>
      </Link>
      <div className='app-header-container admin-header no-print'>
        <div className='app-header' style={{ backgroundColor: `rgba(255,255,255,${alphaByScroll}` }}>
          <div className='account-menu'>
            <div
              className={classnames('click-away', { open: showUserMenuDropdown, })}
              onClick={() => setShowUserMenuDropdown(!showUserMenuDropdown)}
            />
            <div className={classnames('user-menu-wrapper', { 'dropdown-open': showUserMenuDropdown })}>
              {!_.isEmpty(account) && !_.isEmpty(account.data) && !_.isEmpty(account.data.profile) &&
                <React.Fragment>
                  <div className='user-menu-toggle' onClick={() => setShowUserMenuDropdown(!showUserMenuDropdown)}>
                    <span>{`Hi, ${account.data.profile.firstName || 'User'}`}</span>
                    <Avatar email={avatarEmail}/>
                    <span className='material-icons'>{showUserMenuDropdown ? 'expand_less' : 'expand_more'}</span>
                  </div>
                  <div className='user-menu-dropdown-wrapper'>
                    <div className='user-menu-dropdown-container'>
                      <a title='Log Out' onClick={() => {
                        logout(account.data.profile.email)
                      }}>
                        <span className='material-icons'>power_settings_new</span>
                        <span className='link-label'>Log out</span>
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
        </div>
        <div className='collapsable-menu-container'>
          <div className='collapsable-menu'>
            <NavigationBarContainer
              onNavItemClick={(link) => {
                setActiveLink(link)
                setShowUserMenuDropdown(false)
              }}
              onMenuOpen={() => setShowUserMenuDropdown(false)}
              activeLink={activeLink}/>
            <span className='collapse-menu-toggle-wrapper'>
              <span className='collapse-menu-toggle' onClick={toggleNavCollapse}>
                <span className='material-icons'>{ navCollapsed ? 'chevron_right' : 'chevron_left' }</span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div id='bottom-mobile-navbar'>
        <Link to={`/admin`} onClick={() => setShowUserMenuDropdown(false)}>
          <span className='material-icons mi-outlined'>home</span>
        </Link>
        {/* <button type='button' className='create-toggle'>
          <span className='material-icons'>add</span>
        </button>
        <button type='button' className='search-toggle'>
          <span className='material-icons'>search</span>
        </button> */}
        <button type='button' className='nav-toggle' onClick={() => setMobileNavIsOpen(!mobileNavIsOpen)}>
          <span className='material-icons'>menu</span>
        </button>
      </div>
      <ThemeProvider theme={theme}>
        <Drawer
          open={mobileNavIsOpen}
          className='mobile-navigation-wrapper'
          slotProps={{
            backdrop: {
              style: { backgroundColor: '#0C1114', opacity: 0.85 },
            },
          }}
          sx={{
            width: 220,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 220,
              overflow: 'visible',
              boxSizing: 'border-box',
            },
          }}
          anchor='right'
          elevation={0}
          disableScrollLock={true}
          onClose={() => setMobileNavIsOpen(!mobileNavIsOpen)}
        >
          <button type='button' className='nav-close-toggle' onClick={() => setMobileNavIsOpen(!mobileNavIsOpen)}>
            <span className='material-icons'>close</span>
          </button>
          <NavigationBarContainer onNavItemClick={() => {
            setShowUserMenuDropdown(false)
            setMobileNavIsOpen(!mobileNavIsOpen)
          }} onMenuOpen={() => setShowUserMenuDropdown(false) }/>
        </Drawer>
      </ThemeProvider>
    </div>
  )
}

export default withRouter(Header)
