import { createActions, } from 'reduxsauce'

const { Types, Creators, } = createActions({
  loginRequest: ['loginCredentials', ],
  loginSuccess: ['tokens', 'remember', ],
  loginFailure: ['error', ],
  authorizeUserLogin: ['key'],
  logout: ['email', ],
  passiveLogout: ['shouldRedirect'],
  loginRedirect: [],
  newPasswordChallenge: ['userData', ],
  recoverAccessRequest: ['email', ],
  recoverAccessSuccess: [],
  recoverAccessError: ['error', ],
  resetPasswordRequest: ['data', ],
  resetPasswordSuccess: [],
  resetPasswordError: [],
  resetFormFlag: [],
  refreshUserSession: ['email', 'refreshToken', ],
  refreshUserSessionSuccess: ['tokens', ],
  refreshUserSessionError: ['error', ],
})

export const LoginTypes = Types
export default Creators
