import { connect, } from 'react-redux'
import ReportPageHeader from 'components/ReportPageHeader'
import AccountActionCreators from 'ducks/Account/actions'
import ReportsActionsCreator from 'ducks/Reports/actions'
import NotificationActionCreators from 'ducks/Notifications/actions'

const mapStateToProps = ({ auth, account, reports, }, ownProps) => ({
  isAuthenticated: auth.isAuthenticated,
  selectedMerchantAccountId: account.selectedMerchantAccountId,
  merchantList: account.data.merchants,
  data: account.data,
  fromDateFilter: reports.fromDateFilter,
  toDateFilter: reports.toDateFilter,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAccountRequest: () => dispatch(AccountActionCreators.fetchAccountRequest()),
  showError: (title, message) => dispatch(NotificationActionCreators.showError(title, message)),
  getSelectedMerchantAccountId: () => dispatch(AccountActionCreators.getSelectedMerchantAccountId()),
  setMerchantAccountId: (accountId) => dispatch(AccountActionCreators.setMerchantAccountId(accountId)),
  updateDateFilter: (fromDate, toDate) => dispatch(ReportsActionsCreator.updateDateFilter(fromDate, toDate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportPageHeader)
