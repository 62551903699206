import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path : '/admin/rates',
  getComponent (nextState, callback) {
    require.ensure([], (require) => {
      const RatesListContainer = require('./containers/RatesListContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default
      const partnerListReducer = require('../PartnerList/modules/reducer').default
      const partnerListSagas = require('../PartnerList/modules/sagas').default

      injectReducer(store, { key: 'ratesList', reducer, })
      injectSagas(store, sagas)
      injectReducer(store, { key: 'partnerList', reducer: partnerListReducer, })
      injectSagas(store, partnerListSagas)

      callback(null, RatesListContainer)
    }, 'ratesList')
  }
})
