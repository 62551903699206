import _ from 'lodash'
import { call, } from 'redux-saga/effects'
import formDataBuilder from 'utils/formDataBuilder'
import moment from 'moment'
import axios from 'axios'
import utils from 'utils/utils'

// Artificial loading delay to simulate network response
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

// Wrap fixtures in a fake axios response, with an applicable { status: ?, data: passedInFixture }
const fakeAxiosWrap = (status, data) => ({ status, data, })

export default {
  auth: {
    * signup({ data, }) {
      yield sleep(1000)
      // If the email is muzza@rella.com we simulate a failed request.
      if (data.email === 'muzza@rella.com' || data.firstName === 'fail') {
        // throw an error to simulate non 200?
        throw require('pages/Signup/fixtures/failedSignup.json')
        // return yield call(axios.post, 'http://www.mocky.io/v2/59a6d29e1000002e0708fdf4')
      }
      return yield fakeAxiosWrap(200, {})
    },

    * validateToken() {
      yield sleep(1000)
      return yield fakeAxiosWrap(200, {})
    },

    * sendApplicationForm({ data, }) {
      yield sleep(1000)

      if (data.contactFirstName === 'Additional') {
        return fakeAxiosWrap(200, require('pages/ApplicationForm/fixtures/code66.json'))
      }
      return fakeAxiosWrap(200, {})
    },

    * sendAdditionalInfoForm({ data, }) {
      yield sleep(200)
      return fakeAxiosWrap(200, {})
    },

    * fetchAccountSummary({ accountId, }) {
      yield sleep(1000)
      const eventData = require('fixtures/accountSummary.json')

      return eventData
    },

    * fetchAccountInfo() {
      yield sleep(1000)
      const data = require('pages/AccountInfo/fixtures/accountData.json')
      return fakeAxiosWrap(200, data)
    },

    * updateAccountInfo({ data, }) {
      yield sleep(2000)
      return fakeAxiosWrap(200, { data, })
    },

    * updatePassword({ oldPassword, newPassword, }) {
      yield sleep(200)
      return fakeAxiosWrap(200, {})
    },

    * updateEmail({ oldEmail, newEmail, password, }) {
      yield sleep(200)
      return fakeAxiosWrap(200, {})
    },

    * fetchMerchantInfo(merchantId) {
      yield sleep(1000)
      const data = require('pages/AccountInfo/fixtures/merchantData.json')
      return fakeAxiosWrap(200, data)
    },

    * updateMerchantBankinfo({ merchantId, data, }) {
      yield sleep(3000)
      return fakeAxiosWrap(200, {})
    },
  },

  reports: {
    * fetchMerchantSummary({ accountId, }) {
      yield sleep(1000)
      const data = require('fixtures/accountSummary.json')

      return fakeAxiosWrap(200, data)
    },

    * fetchTransactionSummary({ accountId, }) {
      yield sleep(1000)
      const data = require('pages/TransactionReport/fixtures/transactionSummary.json')

      return fakeAxiosWrap(200, data)
    },

    * fetchTransactionReport({ accountId, transactionState, filters, pagination = { page: 1, perPage: 5, }, sort = { date: 'DESC', }, }) {
      yield sleep(1000)
      const transactionData = require('pages/TransactionReport/fixtures/transactionDetail.json')
      const response = { ...transactionData, }

      // Simulate the sorting.
      if (sort.date === 'ASC') {
        // response.data = response.data.sort((a, b) => {
        //   return moment(a.date) - moment(b.date)
        // })
        response.data.sort((a, b) => {
          const dateA = moment(a.date)
          const dateB = moment(b.date)
          return dateA.diff(dateB)
        })
      } else {
        // Return DESC order
        response.data.sort((a, b) => {
          const dateA = moment(a.date)
          const dateB = moment(b.date)
          return dateB.diff(dateA)
        })
      }

      if (transactionState === 'searching') {
        const filtered = response.data.filter((cv) => cv.number === filters.transaction_id)
        response.data = filtered

        return fakeAxiosWrap(200, response)
      }

      // Simulate the pagination
      response.meta = { ...response.meta, ...pagination, totalRecords: response.data.length, totalPages: response.data.length / pagination.perPage, }
      response.data = response.data.slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage)

      return fakeAxiosWrap(200, response)
    },

    * fetchTransactionSnapshot({ accountId, }) {
      yield sleep(1000)
      const snapshotData = require('pages/TransactionReport/fixtures/transactionSnapshot.json')

      return fakeAxiosWrap(200, snapshotData)
    },

    * downloadTransactions({ accountId, filters, sort, }) {
      yield sleep(1000)
      const transactionDetail = require('pages/TransactionReport/fixtures/transactionDetail.json')
      const data = utils.parseToExportToCSV(transactionDetail.data)

      return fakeAxiosWrap(200, { data, })
    },

    * downloadSnapshot({ accountId, filters, }) {
      yield sleep(1000)
      const snapshotData = require('pages/TransactionReport/fixtures/transactionSnapshot.json')
      const data = utils.parseToExportToCSV(snapshotData.data)

      return fakeAxiosWrap(200, { data, })
    },

    * fetchChargebackSummary({ accountId, filters, }) {
      yield sleep(1000)
      const chargebackData = require('pages/ChargebackReport/fixtures/chargebackSummary.json')

      return fakeAxiosWrap(200, chargebackData)
    },

    * fetchRetrievalDetails({ accountId, filters, pagination = { page: 1, perPage: 5, }, sort = { transactionDate: 'DESC', }, }) {
      yield sleep(1000)
      const retrievalDetails = require('pages/ChargebackReport/fixtures/retrievalDetails.json')
      const response = { ...retrievalDetails, }

      if (sort.date === 'ASC') {
        response.transactionDate.sort((a, b) => {
          const dateA = moment(a.transactionDate)
          const dateB = moment(b.transactionDate)
          return dateA.diff(dateB)
        })
      } else {
        // Return DESC order
        response.data.sort((a, b) => {
          const dateA = moment(a.transactionDate)
          const dateB = moment(b.transactionDate)
          return dateB.diff(dateA)
        })
      }

      // Simulate the pagination
      response.data = response.data.slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage)
      response.meta = { ...response.meta, ...pagination, }

      return fakeAxiosWrap(200, response)
    },

    * fetchChargebackDetails({ accountId, filters, pagination = { page: 1, perPage: 5, }, sort, }) {
      yield sleep(1000)
      const chargebackDetail = require('pages/ChargebackReport/fixtures/chargebackDetails.json')
      const response = { ...chargebackDetail, }

      if (sort.chargebackDate === 'ASC') {
        response.data = chargebackDetail.data.sort((a, b) => {
          const dateA = moment(a.chargebackDate)
          const dateB = moment(b.chargebackDate)
          return dateA.diff(dateB)
        })
      } else {
        // Return DESC order
        response.data = chargebackDetail.data.sort((a, b) => {
          const dateA = moment(a.chargebackDate)
          const dateB = moment(b.chargebackDate)
          return dateB.diff(dateA)
        })
      }

      // Simulate the pagination
      response.data = response.data.slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage)
      response.meta = { ...response.meta, ...pagination, }

      return fakeAxiosWrap(200, response)
    },

    * fetchOutageTransactions({ filters, pagination = { page: 1, perPage: 10, }, sort = { name: 'ASC', }, }) {
      yield sleep(1000)
      const outageTransactions = require('pages/AdminPortal/pages/OutageTransactionsReport/fixtures/outageTransactions.json')
      const response = { ...outageTransactions, }

      if (sort.datecreated === 'ASC') {
        response.datecreated.sort((a, b) => {
          const dateA = moment(a.datecreated)
          const dateB = moment(b.datecreated)
          return dateA.diff(dateB)
        })
      } else {
        // Return DESC order
        response.data.sort((a, b) => {
          const dateA = moment(a.datecreated)
          const dateB = moment(b.datecreated)
          return dateB.diff(dateA)
        })
      }

      // Simulate the pagination
      response.data = response.data.slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage)
      response.meta = { ...response.meta, ...pagination, }

      return fakeAxiosWrap(200, response)
    },

    * fetchACHReturnSummary({ accountId, }) {
      yield sleep(1000)
      const achReturnData = require('pages/ACHReturnReport/fixtures/achReturnSummary.json')

      return fakeAxiosWrap(200, achReturnData)
    },

    * fetchACHReturnDetails({ accountId, filters, pagination = { page: 1, perPage: 5, }, }) {
      yield sleep(1000)
      const achReturnDetail = require('pages/ACHReturnReport/fixtures/achReturnDetails.json')
      const response = { ...achReturnDetail, }

      response.meta = { ...response.meta, ...pagination, }

      // Simulate the pagination
      response.meta = { ...response.meta, ...pagination, totalRecords: response.data.length, totalPages: response.data.length / pagination.perPage, }
      response.data = response.data.slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage)

      return fakeAxiosWrap(200, response)
    },

    * fetchReconciliationReport({ accountId, filters, pagination = { page: 1, perPage: 25, }, sort, }) {
      yield sleep(1000)
      const reconciliationData = require('pages/ReconciliationReport/fixtures/reconciliationSummary.json')
      const response = { ...reconciliationData, }

      // Simulate the sorting.
      if (sort.transactionDate === 'ASC') {
        response.data.sort((a, b) => {
          const dateA = moment(a.transactionDate)
          const dateB = moment(b.transactionDate)
          return dateA.diff(dateB)
        })
      } else {
        // Return DESC order
        response.data.sort((a, b) => {
          const dateA = moment(a.transactionDate)
          const dateB = moment(b.transactionDate)
          return dateB.diff(dateA)
        })
      }

      response.meta = { ...response.meta, ...pagination, }

      // Simulate filter
      if (filters.batchId) {
        response.data = _.filter(response.data, (report) => report.batchId.indexOf(filters.batchId) > -1)
      }

      // Simulate the pagination
      response.meta = { ...response.meta, ...pagination, totalRecords: response.data.length, totalPages: response.data.length / pagination.perPage, }
      response.data = response.data.slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage)

      return fakeAxiosWrap(200, response)
    },

    * fetchReconciliationReportDetail({ batchId, }) {
      yield sleep(1000)
      const reconciliationDetailData = require('pages/ReconciliationReport/fixtures/reconciliationDetail.json')

      return fakeAxiosWrap(200, reconciliationDetailData)
    },
  },

  admin: {
    * fetchMerchantList(pagination, status, searchValue='', sorts) {
      yield sleep(1000)

      const { page, perPage, total, } = pagination
      const merchantListData = require('pages/AdminPortal/pages/MerchantList/fixtures/merchantList.json')
      let merchantList = merchantListData.data

      if (status) {
        merchantList = merchantList.filter((merchant) => merchant.status === status)
      }

      if (searchValue) {
        merchantList = merchantList.filter(merchant => {
          let value = 'merchant_search'

          return value.includes(searchValue)
        })
      }

      if (sorts) {
        merchantList = _.orderBy(merchantList, ['affiliate'], [sorts.affiliate.toLowerCase()])
      }

      const response = {
        data: merchantList.slice((page - 1) * perPage, page * perPage),
        meta: { ...merchantListData.meta, ...pagination, totalRecords: merchantList.length, totalPages: merchantList.length / perPage, },
      }

      return fakeAxiosWrap(200, response)
    },

    * exportSelectedMerchants(selectedMerchants) {
      yield sleep(2000)
      return fakeAxiosWrap(200, { data: selectedMerchants, })
    },

    * createMerchant(data) {
      yield sleep(3000)
      return fakeAxiosWrap(200, {})
    },

    * updateMerchantProfile(data) {
      yield sleep(2000)
      return fakeAxiosWrap(200, {})
    },

    //Partner Fixtures
    * fetchPartnerList(pagination, status, partnerProp='', searchValue='', sorts) {
      yield sleep(1000)

      const { page, perPage, total, } = pagination
      const partnerListData = require('pages/AdminPortal/pages/PartnerList/fixtures/partnerList.json')
      let partnerList = partnerListData.data

      if (status) {
        partnerList = partnerList.filter((partner) => partner.status === status)
      }

      if (partnerProp && searchValue) {
        partnerList = partnerList.filter(partner => {
          let value = partner[partnerProp].toLowerCase()

          return value.includes(searchValue)
        })
      }

      if (sorts) {
        partnerList = _.orderBy(partnerList, ['affiliate'], [sorts.affiliate.toLowerCase()])
      }

      const response = {
        data: partnerList.slice((page - 1) * perPage, page * perPage),
        meta: { ...partnerListData.meta, ...pagination, totalRecords: partnerList.length, totalPages: partnerList.length / perPage, },
      }

      return fakeAxiosWrap(200, response)
    },

    //Rates Fixtures
    * fetchRatesList(pagination, status, rateProp='', searchValue='', sorts) {
    yield sleep(1000)

    const { page, perPage, total, } = pagination
    const partnerListData = require('pages/AdminPortal/pages/RatesList/fixtures/ratesList.json')
    let ratesList = ratesListData.data

    if (rateProp && searchValue) {
      ratesList = ratesList.filter(rate => {
        let value = rate[rateProp].toLowerCase()

        return value.includes(searchValue)
      })
    }

    if (sorts) {
      ratesList = _.orderBy(ratesList, ['affiliate'], [sorts.affiliate.toLowerCase()])
    }

    const response = {
      data: ratesList.slice((page - 1) * perPage, page * perPage),
      meta: { ...ratesListData.meta, ...pagination, totalRecords: ratesList.length, totalPages: ratesList.length / perPage, },
    }

    return fakeAxiosWrap(200, response)
  },

    * exportSelectedPartners(selectedPartners) {
      yield sleep(2000)
      return fakeAxiosWrap(200, { data: selectedPartners, })
    },

    * exportRatesList(selectedRates) {
    yield sleep(2000)
    return fakeAxiosWrap(200, { data: selectedRates, })
  },

    * fetchPartnerProfile(id, searchValue = '') {
      yield sleep(1000)

      let partnerProfiles = require('pages/AdminPortal/pages/PartnerProfile/fixtures/partnerProfiles.json').data

      if (searchValue) {
        const partner = _.find(partnerProfiles, (partner) => new RegExp(searchValue, 'i').test(partner.partnerName))
        partnerProfiles = partner ? [partner, ] : partnerProfiles
      }

      return fakeAxiosWrap(200, { data: partnerProfiles, })
    },

    * createPartner(data) {
      yield sleep(3000)
      return fakeAxiosWrap(200, {})
    },

    * updatePartnerProfile(data) {
      yield sleep(2000)
      return fakeAxiosWrap(200, {})
    },

    //Users Fixtures
    * fetchUsers(pagination, sort, searchProperty='', searchValue='', userType='') {
      yield sleep(1000)

      const { page, perPage, total, } = pagination
      const userList = require('pages/AdminPortal/pages/UserAdministrator/fixtures/userList.json')


      let returnObj = Object.assign({}, userList)
      if (searchProperty && searchValue) {
        returnObj.data = returnObj.data.filter(user => {
          let value = user[searchProperty].toLowerCase()
          let compareValue = searchValue.toLowerCase()
          return value.includes(compareValue)
        })
      }

      const totalUsers = returnObj.data.length

      const response = {
        data: returnObj.data.slice((page - 1) * perPage, page * perPage),
        meta: { ...returnObj.meta, ...pagination, totalRecords: totalUsers, totalPages: totalUsers / perPage, }
      }

      return fakeAxiosWrap(200, response)
    },

    * newUser(userInfo) {
      yield sleep(1000)

      const userList = require('pages/AdminPortal/pages/UserAdministrator/fixtures/userList.json')
      userInfo.id = userList.data.length + 1

      const response = {
        data: _.concat(userInfo, userList.data)
      }
      return fakeAxiosWrap(200, response)
    },

    * updateUser(userInfo) {
        yield sleep(1000)

        const userList = require('pages/AdminPortal/pages/UserAdministrator/fixtures/userList.json')

        var result = userList.data.map((element) => {
          if (element.id == userInfo.id) {
            return userInfo
          } else {
            return element
          }
        })

        const response = {
          data: result
        }


        return fakeAxiosWrap(200, response)
    },


    * userResetPassword(data) {
      yield sleep(2000)
      return fakeAxiosWrap(200, {})
    },

    * disableUser(userInfo) {
      yield sleep(1000)

      const userList = require('pages/AdminPortal/pages/UserAdministrator/fixtures/userList.json')


      var result = userList.data.filter(function (element) {
        if (element.id == userInfo.id) return false
        return true
      })

      const response = {
        data: result
      }

      return fakeAxiosWrap(200, response)
    },

    //Dashboard Fixtures
    * fetchAdminDashboard() {
      yield sleep(1000)
      const adminDashboard = require('pages/AdminPortal/pages/Dashboard/fixtures/adminDashboard.json')

      const response = {
        data: adminDashboard.data
        // meta: { ...returnObj.meta, ...pagination, totalRecords: totalUsers, totalPages: totalUsers / perPage, }
      }

      return fakeAxiosWrap(200, response)
    },

    * fetchClients() {
      yield sleep(1000)
      const clients = require('pages/AdminPortal/pages/Dashboard/fixtures/clients.json')

      return fakeAxiosWrap(200, { data: clients.data, })
    },

    * fetchGrossSalesOverview(client, currency, startDate, endDate) {
      yield sleep(1000)
      const grossSales = require('pages/AdminPortal/pages/Dashboard/fixtures/overview/grossSales.json')

      return fakeAxiosWrap(200, { data: grossSales.data, })
    },

    * fetchACHReturnOverview(startDate, endDate, currency) {
      yield sleep(1000)
      const achReturns = require('pages/AdminPortal/pages/Dashboard/fixtures/overview/achReturns.json')

      return fakeAxiosWrap(200, { data: achReturns.data, })
    },

    * fetchChargebackOverview(startDate, endDate, currency) {
      yield sleep(1000)
      const chargebacks = require('pages/AdminPortal/pages/Dashboard/fixtures/overview/chargebacks.json')

      return fakeAxiosWrap(200, { data: chargebacks.data, })
    },

    * fetchRetrievalOverview(startDate, endDate, currency) {
      yield sleep(1000)
      const retrievals = require('pages/AdminPortal/pages/Dashboard/fixtures/overview/retrievals.json')

      return fakeAxiosWrap(200, { data: retrievals.data, })
    },

    * fetchTransactionOverview(startDate, endDate, currency) {
      yield sleep(1000)
      const transactions = require('pages/AdminPortal/pages/Dashboard/fixtures/overview/transactions.json')

      return fakeAxiosWrap(200, { data: transactions.data, })
    },

    * fetchMonthlyVolume() {
      yield sleep(1000)
      const monthlyVolume = require('pages/AdminPortal/pages/Dashboard/fixtures/monthlyVolume.json')

      const response = {
        data: monthlyVolume.data,
        meta: monthlyVolume.meta
      }

      return fakeAxiosWrap(200, response)
    },


    * fetchTransactionsVolume() {
      yield sleep(1000)
      const transactionsVolume = require('pages/AdminPortal/pages/Dashboard/fixtures/transactionsVolume.json')

      const response = {
        data: transactionsVolume.data
      }

      return fakeAxiosWrap(200, response)
    },

    * fetchVolumeAnalysis() {
      yield sleep(1000)
      const volumeAnalysis = require('pages/AdminPortal/pages/Dashboard/fixtures/volumeAnalysis.json')

      const response = {
        data: volumeAnalysis.data
      }

      return fakeAxiosWrap(200, response)
    },

    * fetchCardVolume() {
      yield sleep(1000)
      const cardVolume = require('pages/AdminPortal/pages/Dashboard/fixtures/cardVolume.json')

      const response = {
        data: cardVolume.data,
      }

      return fakeAxiosWrap(200, response)
    },

    * fetchUserTypes() {
      yield sleep(1000)
      const userTypes = require('pages/AdminPortal/pages/UserAdministrator/fixtures/userTypes.json')

      const response = {
        data: userTypes.data
      }
      return fakeAxiosWrap(200, response)
    },
    // * updateUserProfile(data) {
    //   yield sleep(2000)
    //   return fakeAxiosWrap(200, {})
    // },
  },

}
