import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/reports/ach-returns',

  getComponent(nextState, cb) {
    require.ensure([], (require) => {
      const ACHReturnReportContainer = require('./containers/ACHReturnReportContainer').default
      const reducer = require('../../../ACHReturnReport/modules/reducer').default
      const sagas = require('../../../ACHReturnReport/modules/sagas').default
      const merchantListReducer = require('../MerchantList/modules/reducer').default
      const merchantListSagas = require('../MerchantList/modules/sagas').default

      injectReducer(store, { key: 'achReturnReport', reducer, })
      injectSagas(store, sagas)
      injectReducer(store, { key: 'merchantList', reducer: merchantListReducer, })
      injectSagas(store, merchantListSagas)

      cb(null, ACHReturnReportContainer)

    }, 'ach-return-report')
  },
})
