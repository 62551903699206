import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/merchants/:id/misc',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const MerchantMiscContainer = require('./containers/MerchantMiscContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'merchantMisc', reducer, })
      injectSagas(store, sagas)

      callback(null, MerchantMiscContainer)
    }, 'ratesProfile')
  }
})
