/* eslint-disable no-underscore-dangle */
/* eslint no-param-reassign: ["error", { "props": false }] */
import { applyMiddleware, createStore, compose, combineReducers, } from 'redux'
import { reducer as formReducer, } from 'redux-form'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware, routerReducer, } from 'react-router-redux'
import { api, } from 'services/api'
import { autoRehydrate, } from 'redux-persist'
import { interceptorMiddleware, } from 'utils/interceptorMiddleware'
import refreshMiddleware from 'utils/refreshMiddleware'
import roleRedirector from 'middlewares/roleRedirector'
import reducers, { rootSaga, } from '../ducks'

export const makeRootReducer = (asyncReducers) => combineReducers({
  ...reducers,
  routing: routerReducer,
  form: formReducer,
  ...asyncReducers,
})

export default function (history) {
  const middlewares = []
  const sagaMiddleware = createSagaMiddleware()
  middlewares.push(
    sagaMiddleware,
    routerMiddleware(history),
    refreshMiddleware(),
    interceptorMiddleware(api),
    roleRedirector(),
  )

  // Check for redux devtool extension and use its compose function if in development
  let enhancedCompose = compose
  if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    enhancedCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  }

  // const store = createStore(
  //   makeRootReducer({
  //     ...reducers,
  //     router: routerReducer,
  //   }),
  //   composeEnhancers(applyMiddleware(...middlewares))

  // Create redux store with autoRehydrate store enhancer
  // and enhanced compose for browser plugin redux integration
  const store = compose(autoRehydrate())(createStore)(
    makeRootReducer({}),
    enhancedCompose(applyMiddleware(...middlewares))
  )

  // Allow for async injection of reducer + saga for code splitting
  store.asyncReducers = {}
  store.runSaga = sagaMiddleware.run

  sagaMiddleware.run(rootSaga)

  return store
}

/**
 * Inject an asynchronously loaded reducer.
 * @param {Object} store  : Global store of the app.
 * @param {String} key    : Key that will have the reducer injected into the store.
 * @param {Object} reducer: Specific reducer to inject in the store.
 */
export const injectReducer = (store, { key, reducer, }) => {
  if (!reducer) {
    throw new TypeError('You must provide a reducer.')
  } else if (!Object.hasOwnProperty.call(store.asyncReducers, key)) {
    store.asyncReducers[key] = reducer
    store.replaceReducer(makeRootReducer(store.asyncReducers))
  } // No `else` statement
}

/**
 * Inject an asynchronously loaded saga.
 * @param {Object} store: Global store of the app.
 * @param {Array}  sagas: List of saga functions to inject.
 */
export function injectSagas(store, sagas) {
  // TODO: Add check to validate that sagas is valid (is an array of generators)
  sagas.map(store.runSaga)
}
