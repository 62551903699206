import React from 'react'
import classnames from 'classnames'
import { Link, } from 'react-router'

import './AccountMenu.scss'

class AccountMenu extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false,
    }

    this.timmer = false
  }

  _resetTimmer = () => {
    clearTimeout(this.timmer)
  }

  _openMenu = () => {
    this._resetTimmer()
    this.setState({ open: true, })
  }

  _closeMenu = () => {
    this.timmer = setTimeout(() => {
      this.setState({ open: false, })
    }, 500)
  }

  render() {
    const { name, link, } = this.props
    const { open, } = this.state

    if (link) {
      return (
        <div className="accountmenu-component">
          <Link
            className="trigger"
            to="/account/info"
          >
            Welcome {name}
          </Link>
        </div>
      )
    }
    else {
      return (
        <div className="accountmenu-component">
          Welcome {name}
        </div>
      )
    }
  }
}

export default AccountMenu
