import React, { Component, } from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import _ from 'lodash'
import { MERCHANT_STATUSES } from '../../constants'

class MerchantStatus extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      merchant,
      merchantStatus
    } = this.props

    const currentStatus = _.find(MERCHANT_STATUSES, { status: merchantStatus, })

    return (
      <div className='merchant-status-info'>
        <p><strong>Status: </strong>
          <span
            className='merchant-status-marker'
            style={{
              background: currentStatus.color,
            }} />
          {(merchant.processorName === 'Stripe')
            ? currentStatus.label.replace(' - Pending', '')
            : currentStatus.label
          }
        </p>
        <p><strong>Merchant ID:</strong> {merchant.id}</p>
        <p><strong>Created: </strong>
          {moment(merchant.accountCreateDate).format('MM/DD/YYYY')}</p>
      </div>
    )
  }
}

export default withRouter(MerchantStatus)
