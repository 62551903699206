import api from 'services/api'

import { LOCATION_CHANGE, } from 'react-router-redux'
import { call, put, takeEvery, take, cancel, fork, takeLatest, select } from 'redux-saga/effects'
import ActionCreators, { AccountActionTypes, } from './actions'

const getState = (state) => state

/**
 * Fetch the account info for the current user.
 * @param {Object} action
 */
export function* fetchAccountInfo() {
  let turnstileEmail = ''

  try {
    const migrateResponse = yield call(api.auth.migrateUserToTurnstile)

    if (migrateResponse.status === 200) {
      const response = yield call(api.auth.fetchAccountInfo)

      if (response.status === 200 && response.data) {
        if (!response.data || !response.data.data) {
          console.error('white-screen-error', JSON.stringify(response))
        }
        yield put(ActionCreators.fetchAccountSuccess(response.data.data))

        if (!turnstileEmail) {
          turnstileEmail =
            response.data.data.profile &&
            response.data.data.profile.email
          localStorage.setItem('turnstileEmail', turnstileEmail || '')
        }
      }
    }
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    let errorSent = false

    if (errorResponse.status === 404 || errorResponse.status === '404') {
      yield put(ActionCreators.fetchAccountInvalid({ ...errorResponse, }))
    } else {
      const notify = {
        level: 'error',
        title: 'Network Error',
        message: 'A network error occurred and the database could not be reached to validate your user account. ' +
          'Please reload the page.',
      }

      errorSent = true

      yield put(ActionCreators.fetchAccountFailure({ ...errorResponse, notify }))
    }

    if (errorResponse.status === 401 || errorResponse.status === '401') {
      yield put(ActionCreators.fetchAccountInvalid({ ...errorResponse, }))
    } else {
      const notify = {
        level: 'error',
        title: 'Network Error',
        message: 'An error occurred and the page attempted to make a ' +
          'request before being fully authenticated.',
      }
      errorSent = true

      yield put(ActionCreators.fetchAccountFailure({ ...errorResponse, notify }))
    }

    if (!errorSent) {
      console.error('white-screen-error-catch-failed', JSON.stringify(error))
    }
  }
}
/**
 * Update Last Login of the user.
 * @param {Object} action
 */
export function* updateLastLogin() {
  try {
    yield call(api.auth.updateLastLogin)
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Network Error',
      message: 'An error occurred while updating last login.',
    }

    yield put(ActionCreators.updateLastLoginFailure({ ...errorResponse, notify, }))
  }
}

/**
 * Update Merchant User Settings
 * @param {Number} id - user ID
 * @param {Object} data - settings to update
 */
export function* updateMerchantUserSettings({ id, data }) {
  try {
    yield call(api.auth.updateMerchantUserSettings, { id, data })
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Network Error',
      message: 'An error occurred while updating user settings.',
    }

    yield put(ActionCreators.updateMerchantUserSettingsFailure({ ...errorResponse, notify, }))
  }
}

/**
 * Fetch the account info for the current user.
 * @param {Object} action
 */
export function* fetchAccessRules({ accountId, }) {
  try {
    const response = yield call(api.auth.fetchAccessRules, { accountId, })

    if (response.status === 200 && response.data) {
      yield put(ActionCreators.accessRulesSuccess(response.data.data))
    }
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Network Error',
      message: 'An error occurred while fetching your data. Please reload.',
    }

    yield put(ActionCreators.accessRulesFailure({ ...errorResponse, notify, }))
  }
}

/**
 * Fetch the account info for the current user.
 * @param {Object} action
 */
export function* fetchPermissions({ userId, }) {
  try {
    const response = yield call(api.auth.fetchPermissions, { userId, })

    if (response.status === 200 && response.data) {
      yield put(ActionCreators.permissionsSuccess(response.data.data))
    }
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Network Error',
      message: 'An error occurred while fetching your data. Please reload.',
    }

    yield put(ActionCreators.permissionsFailure({ ...errorResponse, notify, }))
  }
}


/**
 * Send the merchant token to the okta account
 * @param {String} token
 */
export function* sendMerchantToken({ payload, }) {
  let response

  try {
    response = yield call(api.auth.updateAccountInfo, { data: { ...payload, }, })
    if (response.status === 200) {
      yield put(ActionCreators.sendMerchantTokenSuccess())
    }
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Error',
      message: errorResponse.error_message,
    }

    yield put(ActionCreators.sendMerchantTokenFailure({ ...errorResponse, notify, }))
  }
}

/**
 * Validates the token from Salesforce
 * @param {String} token
 */
export function* validateToken({ token, }) {
  let response

  try {
    response = yield call(api.auth.validateToken, { token, })
    if (response.status === 200) {
      const result = response.data.data
      yield put(ActionCreators.validateTokenSuccess({ result, }))
    }
  } catch (error) {
    const errorResponse = error.response
    const notify = {
      level: 'error',
      title: 'Error',
      message: errorResponse.data.error_message,
    }

    yield put(ActionCreators.validateTokenFailure({ ...errorResponse, notify, }))
  }
}

/**
 * Fetch the roles of the current admin user.
 * @param {Object} action
 */
export function* fetchAdminRoles() {
  let response

  try {
    const state = yield select(getState)
    const roles = state.account.roles
    if (roles) {
      yield put(ActionCreators.fetchAdminRolesAlreadyFectched())
      return
    }
    response = yield call(api.admin.fetchAdminRoles)

    if (response.status === 200 && response.data) {
      yield put(ActionCreators.fetchAdminRolesSuccess(response.data.data))
    }
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Network Error',
      message: 'An error occurred while fetching your data. Please reload.',
    }

    yield put(ActionCreators.fetchAdminRolesError({ ...errorResponse, notify, }))
  }
}

/**
 * Fetch the site settings
 */
export function* fetchSiteSettings() {
  try {
    const response = yield call(api.admin.fetchSiteSettings)

    if (response.status === 200 && response.data) {
      yield put(ActionCreators.fetchSiteSettingsSuccess(response.data))
    }
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Network Error',
      message: 'An error occurred while fetching your data. Please reload.',
    }

    yield put(ActionCreators.fetchSiteSettingsError({ ...errorResponse, notify, }))
  }
}

/**
 * Fetch the account info for the current user.
 * @param {Object} action
 */
export function* setMerchantAccountId({ accountId, }) {
  try {
    yield put(ActionCreators.setMerchantAccountIdSuccess(accountId))
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Network Error',
      message: 'An error occurred while setting the account ID. Please reload.',
    }

    yield put(ActionCreators.setMerchantAccountIdFailure({ ...errorResponse, notify, }))
  }
}

/**
 * Fetch the account info for the current user.
 * @param {Object} action
 */
export function* getSelectedMerchantAccountId() {
  try {
    yield put(ActionCreators.getSelectedMerchantAccountIdSuccess())
  } catch (error) {
    const errorResponse = (error.response && error.response.data) ? error.response.data : error
    const notify = {
      level: 'error',
      title: 'Network Error',
      message: 'An error occurred while setting the account ID. Please reload.',
    }

    yield put(ActionCreators.getSelectedMerchantAccountIdFailure({ ...errorResponse, notify, }))
  }
}
