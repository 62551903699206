import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path : '/admin/partners/create',
  getComponent (nextState, callback) {
    require.ensure([], (require) => {
      const PartnerCreationContainer = require('./containers/PartnerCreationContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'partnerCreation', reducer, })
      injectSagas(store, sagas)

      callback(null, PartnerCreationContainer)
    }, 'partnerCreation')
  }
})
