import { call, } from 'redux-saga/effects'

/**
 * Expose all endpoints for subscriptions
 */

const subscriptionsApi = (api) => ({
  * readSubscriptions({ accessToken, id, }) {
    return yield call(api.get, `/subscriptions/${id}`, { headers: { Authorization: `Bearer ${accessToken}`, }, })
  },

  * patchSubscription({ accessToken, id, changedItems, }) {
    return yield call(api.patch, `/subscriptions/${id}/scheduled-transactions`, changedItems, { headers: { Authorization: `Bearer ${accessToken}`, }, })
  },
})

export default subscriptionsApi
