import LoginActionCreator, { LoginTypes, } from 'ducks/Auth/actions'
import cognitoUtils from 'utils/cognitoUtils'

export default () => (store) => (next) => (action) => {
  if (action.type === LoginTypes.REFRESH_USER_SESSION) return next(action)
  try {
    const session = cognitoUtils.getSession()
    if (!session || !window.localStorage.getItem('remember')) next(action)

    const expDate = new Date(session.getIdToken().getExpiration() * 1000)
    const now = new Date()
    if (expDate <= now) {
      store.dispatch(LoginActionCreator.refreshUserSession('', window.localStorage.getItem('refreshToken')))
    }
  } catch (error) {
    return next(action)
  }
  return next(action)
}
