import { injectReducer, injectSagas } from 'store'

export default (store) => ({
  path: '/payouts/:accountId/:depositId',
  getComponent(nextState, cb) {
    require.ensure([], (require) => {
      const DepositDetailContainer = require('./containers/DepositDetailContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'depositDetail', reducer })
      injectSagas(store, sagas)

      cb(null, DepositDetailContainer)
    }, 'depositDetail')
  }
})
