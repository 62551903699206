import React, { Component, } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { ThemeProvider, createTheme, } from '@mui/material/styles'
import { MaterialSelect, } from 'components/MaterialInputs'
import TextField from '@mui/material/TextField'
import Selector from 'components/Selector'
import Loader from 'components/Loader'
import './FundingHoldModal.scss'
import {
  PAYOUT_FREQUENCY_OPTIONS,
  PAYOUT_FREQUENCY_WEEKLY_OPTIONS,
  PAYOUT_FREQUENCY_MONTHLY_OPTIONS,
} from '../../constants'

const theme = createTheme({
  palette: {
    primary: {
      light: '#0d2b40',
      main: '#0d2240',
      dark: '#0d1740',
    },
    secondary: {
      light: '#ed2121',
      main: '#d11010',
      dark: '#910505',
    },
  },
  typography: {
    useNextVariants: true,
  },
})

class FundingHoldModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      holdReason: '',
      holdReasonOther: '',
      isSubmitting: false,
      showFrequency: false,
      selectedPayoutFrequency: null,
      selectedPayoutDay: null,
      selectReason: false,
      fieldChanges: false,
      error: false,
    }
  }

  _handleClose = () => {
    this._resetState()
    this.props.handleClose()
  }

  handleChange = (value, property) => this.setState({ [property]: value, })

  _resetState = () => {
    this.setState({
      showFrequency: false,
      selectedPayoutFrequency: null,
      selectedPayoutDay: null,
      holdReason: '',
      selectReason: false,
      error: false,
    })
  }

  _onChangePayout = (payout) => {
    this.setState({ selectedPayoutFrequency: payout.value, fieldChanges: true })
  }

  _onChangePayoutDay = (payout) => {
    this.setState({ selectedPayoutDay: payout.value, fieldChanges: true })
  }

  _selectReason = () => {
    this.setState({ selectReason: true, })
  }

  _submitHold = () => {
    const valid = this._validate(true)
    if (this.state.holdReason !== 'other') this.state.holdReasonOther = ''

    const payload = {
      funding_hold_reason: this.state.holdReason,
      funding_hold_reason_other: this.state.holdReasonOther,
    }

    if (valid) {
      this.setState({ isSubmitting: true, })
      this.props.onSubmit(payload)
    } else {
      this.setState({ error: true, })
    }
  }

  _submitRelease = () => {
    const valid = this._validate(false)
    if (this.state.selectedPayoutFrequency === 'daily') this.state.selectedPayoutDay = ''

    const payload = {
      payout_frequency: this.state.selectedPayoutFrequency,
      payout_frequency_anchor: this.state.selectedPayoutDay,
    }

    if (valid) {
      this.setState({ isSubmitting: true, })
      this.props.onSubmit(payload)
    } else {
      this.setState({ error: true, })
    }
  }

  _revealFrequency = () => {
    this.setState({ showFrequency: true, })
  }

  _validate = (isHold) => {
    if (isHold) {
      if (!_.isEmpty(this.state.holdReason)) {
        switch (this.state.holdReason) {
          case 'other':
            return !_.isEmpty(this.state.holdReasonOther) && this.state.holdReasonOther.length <= 500
          default:
            return true
        }
      } else {
        return false
      }

    } else {
      switch (this.state.selectedPayoutFrequency) {
        case 'weekly':
          return !_.isEmpty(this.state.selectedPayoutDay)
        case 'monthly':
          return !_.isEmpty(this.state.selectedPayoutDay)
        case 'daily':
          return true
      }
    }
  }

  render() {
    const { open, hasFundingHold, balance } = this.props
    const { isSubmitting, holdReason, holdReasonOther, selectReason, showFrequency, selectedPayoutFrequency, selectedPayoutDay, error } = this.state

    if (error === true)
      setTimeout(() => this.setState({ error: false, }), 4000)

    return (
      <Dialog
        className='funding-hold-dialog'
        open={open}
        onClose={() => {
          this._handleClose()
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <React.Fragment>
          <DialogTitle id="responsive-dialog-title">
            <span>Funding Hold</span>
          </DialogTitle>
          {hasFundingHold ?
            <div style={{
              width: '360px',
              fontSize: '14px',
              lineHeight: '24px',
              textAlign: 'center',
              fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
              color: '#666'
            }}>
              <DialogContent className='funding-hold'>
                {!showFrequency ?
                  <div>
                    <h3 className='balance'>${balance || 0}</h3>
                    <p>
                      Are you sure you would like to release the above balance to be paid out to the merchant?
                    </p>
                  </div>
                  :
                  <div>
                    <p>
                      Set the payout frequency for the merchant to receives their deposits going forward.
                    </p>
                    <Selector
                      label="Deposit Frequency"
                      options={PAYOUT_FREQUENCY_OPTIONS}
                      value={selectedPayoutFrequency}
                      searchable={false}
                      onChange={(option) => { this._onChangePayout(option) }}
                    />
                    {selectedPayoutFrequency === 'monthly' &&
                      <Selector
                        label="Day"
                        options={PAYOUT_FREQUENCY_MONTHLY_OPTIONS}
                        value={selectedPayoutDay}
                        searchable={false}
                        onChange={(option) => { this._onChangePayoutDay(option) }}
                      />
                    }
                    {selectedPayoutFrequency === 'weekly' &&
                      <Selector
                        label="Day"
                        options={PAYOUT_FREQUENCY_WEEKLY_OPTIONS}
                        value={selectedPayoutDay}
                        searchable={false}
                        onChange={(option) => { this._onChangePayoutDay(option) }}
                      />
                    }
                    {error &&
                      <span style={{
                        fontSize: '12px',
                        fontWeight: '700',
                        marginTop: '-14px',
                        marginLeft: '2px',
                        fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                        color: '#c00',
                        display: 'block',
                        width: '350px',
                      }}>Please select a valid day for the payout.</span>
                    }
                  </div>
                }
              </DialogContent>

              {isSubmitting ?
                <Loader />
                :
                <DialogActions>
                  <ThemeProvider theme={theme}>
                    <div style={{ width: '100%', marginBottom: '10px', textAlign: 'center', }}>
                      <Button
                        onClick={() => {
                          this._handleClose()
                        }}
                        color="secondary"
                        variant="contained"
                        style={{ marginRight: '10px', }}
                      >
                        {!showFrequency ? 'No' : 'Cancel'}
                      </Button>
                      {!showFrequency ?
                        <Button
                          onClick={() => {
                            this._revealFrequency()
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Yes
                        </Button>
                        :
                        <Button
                          onClick={() => {
                            this._submitRelease()
                          }}
                          color="primary"
                          variant="contained"
                          disabled={!this.state.fieldChanges}
                        >
                          Complete Release
                        </Button>
                      }
                    </div>
                  </ThemeProvider>
                </DialogActions>
              }
            </div>
            :
            <div>
              {!selectReason ?
                <DialogContent>
                  <p style={{
                    width: '360px',
                    fontSize: '14px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                    color: '#666'
                  }}>
                    Placing this account on hold with stop all deposits/payouts to the merchant account. <br /><br />
                    <strong>Are you sure you want to do this?</strong></p>
                </DialogContent>
                :
                <DialogContent>
                  <p style={{
                    width: '360px',
                    fontSize: '14px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                    color: '#666',
                  }}>
                    Select a reason for placing the account on Funding Hold. </p>
                  <MaterialSelect
                    value={holdReason}
                    name="holdReason"
                    onChange={((val) => this.handleChange(val, 'holdReason'))}
                    style={{ width: '360px', marginTop: '14px', marginBottom: '24px', }}
                    dispayEmpty={true}
                    options={[{
                      value: 'suspiciousActivity',
                      label: 'Suspicious Activity'
                    }, {
                      value: 'confirmedFraud',
                      label: 'Confirmed Fraud'
                    }, {
                      value: 'limitsIssue',
                      label: 'Limits Issue'
                    }, {
                      value: 'invalidBankAccount',
                      label: 'Invalid Bank Account'
                    }, {
                      value: 'other',
                      label: 'Other'
                    }]}
                  />
                  {holdReason === 'other' &&
                    <div>
                      <TextField
                        style={{
                          width: '360px',
                          display: 'flex',
                          fontSize: '14px',
                          lineHeight: '24px',
                          fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                          backgroundColor: '#ebe9e9',
                          borderRadius: '4px',
                        }}
                        placeholder="Describe Reason for Funding Hold"
                        multiline
                        rows={4}
                        rowsMax={6}
                        onChange={(e) => this.handleChange(e.target.value.toString(), 'holdReasonOther')}
                      />
                      <span className='char-count'>{holdReasonOther.length}/500</span>
                      {error && holdReason === 'other' &&
                        <span style={{
                          fontSize: '12px',
                          fontWeight: '700',
                          marginTop: '-14px',
                          marginLeft: '2px',
                          fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                          color: '#c00',
                          width: '355px',
                        }}>{holdReasonOther.length === 0 ?
                          'Please type a reason for the funding hold.' :
                          'Reason cannot exceed 500 characters.'}</span>
                      }
                    </div>
                  }
                  {error && holdReason !== 'other' &&
                    <span style={{
                      fontSize: '12px',
                      fontWeight: '700',
                      marginTop: '-14px',
                      marginLeft: '2px',
                      fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                      color: '#c00',
                      display: 'block',
                      width: '355px',
                    }}>Please select a reason for the funding hold.</span>
                  }
                </DialogContent>
              }

              {isSubmitting ?
                <Loader />
                :
                <DialogActions>
                  <ThemeProvider theme={theme}>
                    <div style={{ width: '100%', marginBottom: '10px', textAlign: 'center', }}>
                      <Button
                        onClick={() => {
                          this._handleClose()
                        }}
                        color="secondary"
                        variant="contained"
                        style={{ marginRight: '10px', }}
                      >
                        {!selectReason ? 'No' : 'Cancel'}
                      </Button>
                      {!selectReason ?
                        <Button
                          onClick={() => {
                            this._selectReason()
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Yes
                        </Button> :
                        <Button
                          onClick={() => {
                            this._submitHold()
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Submit Hold
                        </Button>
                      }
                    </div>
                  </ThemeProvider>
                </DialogActions>
              }
            </div>
          }
        </React.Fragment>
      </Dialog>
    )
  }
}

export default FundingHoldModal
