import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/reports/partner-volume',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const PartnerVolumeReportContainer = require('./containers/PartnerVolumeReportContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'partnerVolumeReport', reducer, })
      injectSagas(store, sagas)

      callback(null, PartnerVolumeReportContainer)
    }, 'partnerVolumeReport')
  },
})
