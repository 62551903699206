import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/ach-return-detail/:accountId',

  /*  Async getComponent when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack -require callback  */
      const ACHReturnReportContainer = require('./containers/ACHReturnReportContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      /*  Add the reducer to the store on key 'achReturnReport'  */
      injectReducer(store, { key: 'achReturnReport', reducer, })
      injectSagas(store, sagas)

      /*  Return getComponent   */
      cb(null, ACHReturnReportContainer)

    /* Webpack named bundle   */
    }, 'ach-return-report')
  },
})
