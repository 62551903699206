/**
* Build a valid FormData Object to make a jsonapi Rest Request
*  Parse object to FormData object for send to server.
*  @param {Object} obj to parse
*  @param {Object} form. Recursive param.
*  @param {String} name. Recursive param.
*  @return {Object} formData
*/
export default function objectToFormData(obj, form = false, name = false) {
  const formData = form || new FormData()
  let formKey

  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (name) {
        formKey = `${name}[${property}]`
      } else {
        formKey = property
      }

      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], formData, formKey)
      } else if (obj[property] instanceof File) {
        // if it's a File object
        const fileName = obj[property].name
        formData.append(fileName.slice(0, fileName.indexOf('.')), obj[property])
      } else {
        // if it's a string
        formData.append(formKey, obj[property])
      }
    }
  }

  return formData
}
