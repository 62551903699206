import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path : '/admin/partners/:id/profile',
  getComponent (nextState, callback) {
    require.ensure([], (require) => {
      const PartnerProfileContainer = require('./containers/PartnerProfileContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'partnerProfile', reducer, })
      injectSagas(store, sagas)

      callback(null, PartnerProfileContainer)
    }, 'partnerProfile')
  }
})
