const styles = () => ({
  cssLabel: {
    '&&': {
      top: '-7px',
      '&$cssFocused': {
        color: '#167cd4',
      },
      transform: 'translate(14px, 24px) scale(1)',
    },
  },
  notchedOutline: {
    '&&': {
      borderColor: '#fff !important',
      borderRadius: '10px',
    },
  },
  cssFocused: {},
  cssDisabled: {
    '&&': {
      opacity: '0.5',
    },
  },
  cssOutlinedInput: {
    '&&': {
      height: '50px',
      borderRadius: '10px',
      '&:hover $notchedOutline': {
        borderColor: '#eee !important',
      },
      '&$cssFocused $notchedOutline': {
        borderColor: '#167cd4 !important',
      },
    },
  },
  slimLabelShrink: {
    '&&': {
      transform: 'translate(0px, -9px) scale(0.75)!important',
    },
  },
  input: {
    '&&': {
      padding: '14px',
      backgroundColor: '#fff',
      borderRadius: '10px',
    },
  }
})

export default styles
