import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/reports/residual-fees',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const ResidualFeesReportContainer = require('./containers/ResidualFeesReportContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'residualFeesReport', reducer, })
      injectSagas(store, sagas)

      callback(null, ResidualFeesReportContainer)
    }, 'residualFeesReport')
  },
})
