import React from 'react'
import HeaderContainer from '../../containers/HeaderContainer'
import Notification from 'containers/Notification/NotificationContainer'
import './BulkToolsLayout.scss'
import BulkToolsContainer from '../../containers/BulkToolsContainer'

class BulkToolsLayout extends React.Component {
  render() {
    return (
      <div className='main-layout' id='main-layout'>
        <HeaderContainer/>
        <main className='main-content bulk-tools'>
          <BulkToolsContainer {...this.props} />
        </main>
        <Notification/>
      </div>
    )
  }
}

export default BulkToolsLayout
