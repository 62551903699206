import { connect, } from 'react-redux'
import AppHeader from 'components/AppHeader'
import LoginActions from 'ducks/Auth/actions'
import AccountActionCreators from 'ducks/Account/actions'

const mapStateToProps = ({ auth, account, }, ownProps) => ({
  isAuthenticated: auth.isAuthenticated,
  selectedMerchantAccountId: account.selectedMerchantAccountId,
  data: account.data,
  settings: account.settings
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: (email) => dispatch(LoginActions.logout(email)),
  fetchSiteSettings: () => dispatch(AccountActionCreators.fetchSiteSettings()),
  getSelectedMerchantAccountId: () => dispatch(AccountActionCreators.getSelectedMerchantAccountId()),
  setMerchantAccountId: (accountId) => dispatch(AccountActionCreators.setMerchantAccountId(accountId)),
  fetchAccountRequest: () => dispatch(AccountActionCreators.fetchAccountRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
