import React, { Component, } from 'react'
import { Link, withRouter } from 'react-router'

class MerchantHeader extends Component {
  constructor(props) {
    super(props)
  }

  _goBack = () => {
    this.props.router.goBack()
  }

  render() {
    const {
      merchant,
      location
    } = this.props
    const useHistoryBack = 'back' in location.query
    const backText = `Back to ${_.get(location.query, 'back', 'Merchant List')}`

    return (
      <div className="merchant-profile-header">
        <div className="profile-header-child top-bar">
          {useHistoryBack ?
            (<a onClick={ () => { this._goBack() }}>&lt;&lt; {backText}</a>) :
            (<Link to="/admin/merchants">&lt;&lt; {backText}</Link>)
          }
        </div>
        <h3 className='headerTitle'>
          {merchant.businessCommonName}
          <span className='merchantId'>{merchant.id}</span>
        </h3>
      </div>
    )
  }
}

export default withRouter(MerchantHeader)
