import React, { Component } from 'react'
import _ from 'lodash'
import Loader from 'components/Loader'
import {
  Dialog,
  DialogTitle,
  Button,
} from '@mui/material'
import { createTheme, } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import './SendBeneficialDataModal.scss'

const theme = createTheme({
  palette: {
    primary: {
      light: '#0d2b40',
      main: '#0d2240',
      dark: '#0d1740'
    },
    secondary: {
      light: '#ed2121',
      main: '#d11010',
      dark: '#910505'
    }
  },
  typography: {
    useNextVariants: true
  }
})

class SendBeneficialDataModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      changedStatus: '',
      closureReason: '',
      showConfirmationModal: false,
      showErrorModal: false,
      isSending: false,
      errorMessage: '',
    }
    this.isMountedFlag = false
  }

  componentDidMount() {
    this.isMountedFlag = true
  }

  componentWillUnmount() {
    this.isMountedFlag = false
  }

  componentDidUpdate(prevProps) {
    if (this.isMountedFlag) {
      if (prevProps.isSubmitting && !this.props.isSubmitting) {
        if (this.props.beneficialData && this.props.beneficialData !== prevProps.beneficialData) {
          this.setState({ showConfirmationModal: true, isSending: false })
        } else if (this.props.error && this.props.error !== prevProps.error) {
          let errorMessage = this.props.error.message || 'An error occurred'

          if (errorMessage.includes(`Beneficiary Owner Count provided in Signup Request is invalid.`)) {
            errorMessage = 'The beneficial owner data has already been sent to the procesor for this merchant.'
          }
          this.setState({ showErrorModal: true, isSending: false, errorMessage })
        }
      }
    }
  }

  handleChange = (value, property) => {
    this.setState({ [property]: value })
  }

  resetState = () => {
    this.setState({
      changedStatus: '',
      closureReason: '',
      showPostSubmit: false,
      showConfirmationModal: false,
      showErrorModal: false,
      isSending: false,
      errorMessage: '',
    })
  }

  submit = () => {
    const owner = this.props.merchant.contacts.owners[0]
    const payload = {
      owner: {
        owner_id: owner.id,
        first_name: owner.first_name,
        last_name: owner.last_name,
        date_of_birth: owner.date_of_birth,
        phone: owner.phone,
        ssn_last_4: owner.ssn_last_4,
        ssn_encrypted: owner.ssn_encrypted
      }
    }

    this.setState({ isSending: true })

    this.props.onSubmit(payload)
  }

  render() {
    const {
      fullScreen,
      open,
      handleClose,
      merchant
    } = this.props
    const { showConfirmationModal, showErrorModal, isSending, errorMessage } = this.state

    const formatPhoneNumber = (phoneNumberString) => {
      const cleaned = (`${phoneNumberString}`).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`
      }
      return null
    }

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          this.resetState()
          handleClose()
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <React.Fragment>
          {(!showConfirmationModal && !showErrorModal) &&
            <div className='beneficial-modal-wrapper'>
              {(_.has(merchant, 'contacts.owners') && !_.isEmpty(merchant.contacts.owners))
                ? <div className='beneficial-container send-beneficial-container'>
                  <DialogTitle className="dialog-title">
                    <div className="beneficial-title">
                      <div><span style={{ color: '#0072EB' }}>Send Beneficial Data</span></div>
                      <div>
                        <IconButton className="close-button" onClick={handleClose} style={{ color: 'red', border: '2px solid red' }}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </DialogTitle>
                  <div className='center-content'>
                    <p>Are you sure you want to send the below beneficial data to the processor?</p>
                    <ul className='data-list'>
                      <li><span className='bold-key'>First Name:</span> {merchant.contacts.owners[0].first_name}</li>
                      <li><span className='bold-key'>Last Name:</span> {merchant.contacts.owners[0].last_name}</li>
                      <li><span className='bold-key'>Date of Birth:</span>
                        {merchant.contacts.owners[0].date_of_birth}</li>
                      <li><span className='bold-key'>Phone Number:</span> {formatPhoneNumber(merchant.contacts.owners[0].phone)}</li>
                      <li><span className='bold-key'>Last 4 SSN:</span> {merchant.contacts.owners[0].ssn_last_4}</li>
                    </ul>
                    <div className='buttons-beneficial'>
                      <Button
                        onClick={this.submit}
                        color="primary"
                        variant="contained"
                        disabled={isSending}
                      >
                        {isSending ? 'Sending data...' : 'Yes, send'}
                      </Button>
                    </div>
                  </div>
                </div>
                : <div className='beneficial-container'>
                  No Beneficial Owners listed on the account
                </div>
              }
            </div>
          }
          { showConfirmationModal &&
            <div className='beneficial-modal-wrapper'>
              <div className='beneficial-container confirmation-container'>
                <span className="material-icons"
                  style={{ fontSize: '56px', color: '#4CAF50', marginLeft: '5px', marginBottom: '20px' }}
                >
                  verified
                </span>
                <div className='confirmation-body'>
                  <p>
                    Beneficial data sent successfully to the processor.
                  </p>
                </div>
                <div className='buttons-beneficial'>
                  <Button onClick={() => {
                    handleClose()
                    this.resetState()
                  }}>
                    close
                  </Button>
                </div>
              </div>
            </div>
          }
          { showErrorModal &&
            <div className='beneficial-modal-wrapper'>
              <div className='beneficial-container confirmation-container'>
                <span className="material-icons"
                  style={{ fontSize: '56px', color: '#F44336', marginLeft: '5px', marginBottom: '20px' }}
                >
                  error
                </span>
                <div className='confirmation-body'>
                  <p>
                    Unable to send Beneficial data to the processor.
                  </p>
                  <p>{errorMessage}</p>
                </div>
                <div className='buttons-beneficial'>
                  <Button onClick={() => {
                    handleClose()
                    this.resetState()
                  }}>
                    close
                  </Button>
                </div>
              </div>
            </div>
          }
        </React.Fragment>
      </Dialog>


    )
  }
}

export default SendBeneficialDataModal
