import React, { Component } from 'react';

class StackOwnedBanner extends Component {
  render() {
    const { merchant } = this.props;
    if (merchant.stackOwned) {
      return (
        <div className="stack-owned-banner-wrapper">
          <div className="stack-owned-banner">
            <div className="banner-header">
            <div className='icon icon-alert'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                  <path d="M12 1l-12 22h24l-12-22zm-1 8h2v7h-2v-7zm1 11.25c-.69
                    0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/>
                </svg>
              </div>
              <div className="alert-label">
                <span className="alert-headline">Alert: This is a Stack Sports owned merchant account.</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default StackOwnedBanner;

