import axios from 'axios'
import fixtureApi from './FixtureApi'

// Import the entities apis
import adminApi from './AdminApi'
import authApi from './AuthApi'
import reportsApi from './ReportsApi'
import paymentPlanApi from './PaymentPlanApi'
import subscriptionsApi from './SubscriptionsApi'

export const api = axios.create({
  baseURL: process.env.SP_API_BASE_URL,
  headers: {
    'Cache-Control': 'no-cache',
  },
  timeout: 60000,
})

let apiObj = {}

if (process.env.NODE_ENV === 'test') {
  apiObj = fixtureApi
}

if (process.env.NODE_ENV === 'development'
  || process.env.NODE_ENV === 'staging'
  || process.env.NODE_ENV === 'staging-2'
  || process.env.NODE_ENV === 'production') {
  apiObj = {
    admin: adminApi(api),
    auth: authApi(api),
    reports: reportsApi(api),
    paymentPlans: paymentPlanApi(api),
    subscriptions: subscriptionsApi(api),
  }
}

const apiObject = apiObj

export default apiObject
