import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/checkout/:accountId/payment-links/:paymentLinkToken',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const Container = require('./containers/PaymentLinkDetailsContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'paymentLinksDetails', reducer, })
      injectSagas(store, sagas)

      callback(null, Container)
    }, 'paymentLinksDetails')
  }
})
