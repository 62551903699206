import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/account',

  /*  Async getComponent when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack -require callback  */
      const AccountLayout = require('./components/AccountLayout').default

      /*  Return getComponent   */
      cb(null, AccountLayout)

    /* Webpack named bundle   */
    }, 'account')
  },

  getChildRoutes(nextState, callback) {
    require.ensure([], (require) => {
      callback(null, [
        {
          path: '/account/info',
          getComponent(nextState, cb) {
            require.ensure([], (require) => {
              const AccountInfoContainer = require('./containers/AccountInfoContainer').default
              const reducer = require('./modules/reducer').default
              const sagas = require('./modules/sagas').default

              /*  Add the reducer to the store on key 'dashboard'  */
              injectReducer(store, { key: 'accountPage', reducer, })
              injectSagas(store, sagas)
              cb(null, AccountInfoContainer)
            }, 'account-info')
          },
        },
        {
          path: '/account/merchants/:id',
          getComponent(nextState, cb) {
            require.ensure([], (require) => {
              const MerchantInfoContainer = require('./containers/MerchantInfoContainer').default
              const reducer = require('./modules/reducer').default
              const sagas = require('./modules/sagas').default

              injectReducer(store, { key: 'accountPage', reducer, })
              injectSagas(store, sagas)
              cb(null, MerchantInfoContainer)
            }, 'merchant-info')
          },
        },

        {
          path: '/account/merchants/:id/:type',
          getComponent(nextState, cb) {
            require.ensure([], (require) => {
              const MerchantInfoContainer = require('./containers/MerchantInfoContainer').default
              const reducer = require('./modules/reducer').default
              const sagas = require('./modules/sagas').default

              /*  Add the reducer to the store on key 'account'  */
              injectReducer(store, { key: 'accountPage', reducer, })
              injectSagas(store, sagas)
              cb(null, MerchantInfoContainer)
            }, 'merchant-info')
          },
        },
      ])
    })
  },
})
