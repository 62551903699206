import React, { Component, } from 'react'
import moment from 'moment'
import utils from 'utils/utils'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Tooltip} from '@mui/material';

class CtaBanner extends Component {
  render() {
    const {
      merchant,
    } = this.props

    const ctaDate = moment(merchant.ctaAssignedAt).format('l')

    return (
      <div className="cta-banner-wrapper">
        {(merchant.hasCTA) &&
          <div className='cta-banner'>
            <div className="banner-header">
              <div className='icon icon-alert'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                  <path d="M12 1l-12 22h24l-12-22zm-1 8h2v7h-2v-7zm1 11.25c-.69
                    0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/>
                </svg>
              </div>
              <div className='alert-label'>
                <span className='alert-headline'>Alert: Account has an active CTA set</span>
                <span className='alert-details'>
                  {ctaDate && <React.Fragment>Set on <strong>{ctaDate}</strong><br/></React.Fragment>}
                  {(merchant.ctaTitle) &&
                    <React.Fragment>CTA Name: <strong>{merchant.ctaTitle}</strong></React.Fragment>}
                </span>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default CtaBanner
