import React from 'react'
import './AppFooter.scss'

const AppFooter = (props) => (
  <footer className="app-footer-component">
    <div className="container-fluid flex spaced v-centered">
      <div className="flex-1 legal">
        <span>
          <a href="https://stacksports.com/contact/" target="_BLANK" style={{ color: '#FFF', }}>Support</a> |&nbsp;
          <a href="https://stacksports.com/legal-terms/" target="_BLANK" style={{ color: '#FFF', }}>Privacy & Terms</a>
        </span>
      </div>

      <div className="powered flex-1">
        <span className="logo">Stack Sports</span>
        <span className="copyright">©{new Date().getFullYear()}. All rights reserved.</span>
      </div>
    </div>
  </footer>
)
export default AppFooter
