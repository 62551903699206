import { injectReducer, injectSagas, } from 'store'
import DashboardContainer from './containers/DashboardContainer.jsx'

export default (store) => ({
  path: '/dashboard(/:accountId)',

  /*  Async getComponent when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      /*  Add the reducer to the store on key 'dashboard'  */
      injectReducer(store, { key: 'dashboard', reducer, })
      injectSagas(store, sagas)

      /*  Return getComponent   */
      cb(null, DashboardContainer)

    /* Webpack named bundle   */
    }, 'dashboard')
  },
})
