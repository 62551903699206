import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  /* Async `getComponent` when route matches */
  getComponent(nextState, cb) {
    /* Webpack (split point) - Async module loader (jsonp) when bundling */
    require.ensure([], (require) => {
      /* Webpack - Require callback */
      const SignInContainer = require('./containers/SignInContainer').default
      const reducer = require('../ApplicationForm/modules/reducer').default

      injectReducer(store, { key: 'applicationForm', reducer, })

      /* Return getComponent */
      cb(null, SignInContainer)

    /* Webpack named bundle */
    }, 'signIn')
  },
})
