import React, { Component, } from 'react'
import { Link, withRouter } from 'react-router'
import Loader from 'components/Loader'
import './BulkToolsView.scss'
import classnames from 'classnames'


class BulkToolsView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    // check this.props against prevProps to see if an update was made
  }

  render() {
    const { children } = this.props
    const activeTab =
      ['add-users', 'update-rates', 'update-account-status'].includes(this.props.location.pathname.split('/').pop())
        ? this.props.location.pathname.split('/').pop()
        : 'add-users'

    return (
      <div className='bulk-tools-view'>
        <div className='container-fluid'>
          <div className='admin-page-header'>
            <h2>Bulk Operations</h2>
          </div>
          <div className='bulk-tools-page'>
            <div className='tool-tabs'>
              <ul>
                <Link to={`/admin/bulk-tools/add-users`}>
                  <li className={classnames({ active: activeTab === 'add-users' })}>
                    <span className='icon'><span className='material-icons'>account_box</span></span>
                    <span className='label'>Add/Assign Users</span>
                  </li>
                </Link>
                <Link to={`/admin/bulk-tools/update-rates`}>
                  <li className={classnames({ active: activeTab === 'update-rates' })}>
                    <span className='icon'><span className='material-icons mi-outlined'>assessment</span></span>
                    <span className='label'>Update Rates</span>
                  </li>
                </Link>
                <Link to={`/admin/bulk-tools/update-account-status`}>
                  <li className={classnames({ active: activeTab === 'update-account-status' })}>
                    <span className='icon'><span className='material-icons mi-outlined'>task_alt</span></span>
                    <span className='label'>Update Acct Status</span>
                  </li>
                </Link>
              </ul>
            </div>
            <div className='tool-view'>
              {React.cloneElement(children)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(BulkToolsView)
