import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/rates/:id/profile',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const RatesProfileContainer = require('./containers/RatesProfileContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default
      const partnerListReducer = require('../PartnerList/modules/reducer').default
      const partnerListSagas = require('../PartnerList/modules/sagas').default

      injectReducer(store, { key: 'ratesProfile', reducer, })
      injectSagas(store, sagas)
      injectReducer(store, { key: 'partnerList', reducer: partnerListReducer, })
      injectSagas(store, partnerListSagas)

      callback(null, RatesProfileContainer)
    }, 'ratesProfile')
  }
})
