
/**
 * A list of whitelisted/safe errors
 * @namespace
 * @property {string} errorKey - an identifying key for the error
 * @property {Object} [properties]
 * @property {string} [properties.level] - (not implemented yet) which level of user can see the full error. Accepts "all", "admin", "merchantAdmin". Defaults to "all".
 * @property {string} [properties.errorTitle] - a custom title to show instead of the default title.
 * @property {string} [properties.errorMessage] - a custom message to show instead of the default error message.
 */
export const ERROR_WHITELIST = [
  {
    errorKey: 'adminFetchTransDetailError',
  },
  {
    errorKey: 'merchantFetchTransDetailError',
    properties: {
      level: 'all',
      errorTitle: 'Error finding transaction',
      errorMessage: 'Unable to find transaction with IDs specified'
    }
  },
  {
    errorKey: 'adminUploadDisputeFileError'
  },
  {
    errorKey: 'adminRemoveDisputeFileError'
  }
]

// these words are paired to create a semi-unique error code that can be used for a user to quickly reference an error
/**
 * A list of describing words
 * @type {Array}
 * */
export const ERROR_FIRST_WORDS = [
  'Chartreuse','Sepia','Red','Yellow','Blue','Green','Orange','Purple','Gold','Silver','Crimson','Maroon','Lime',
  'Pink','Indigo','Brown','Khaki','Turquoise','Teal','Lavender','Encouraging','Excellent','Quick','Polite',
  'Elastic','Brawny','Fabulous','Fair','Friendly','Beneficial','Functional','Upbeat','Groovy','Elegant','Tidy',
  'Ultra','Kind','Bouncy','Necessary','Nice',
]

/**
 * A list of objects
 * @type {Array}
 * */
export const ERROR_SECOND_WORDS = [
  'Papaya','Strawberry','Mango','Grape','Apple','Raspberry','Persimmon','Pineapple','Watermelon','Lemon','Tuba',
  'Banjo','Cowbell','Piano','Oboe','Ukulele','Kazoo','Violin','Piccolo','Guitar','Corn','Celery','Habanero',
  'Artichoke','Cucumber','Potato','Okra','Jalapeno','Arugula','Lettuce','Cabbage','Shirt','Baseball','Marble',
  'Coffee','Lamp','Wallet','Stone','Truck','Boat','Car','Tiger','Badger','Cheetah','Baboon','Bison','Porcupine',
  'Rabbit','Pony','Armadillo','Bear','Burro',
]
