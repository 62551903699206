import { createActions, } from 'reduxsauce'

const { Types, Creators, } = createActions({
  fetchMerchantProfile: ['merchantId', ],
  fetchMerchantProfileSuccess: ['data', ],
  fetchMerchantProfileError: ['error', ],

  fetchFullRatesList: ['merchantId', ],
  fetchFullRatesListSuccess: ['data', ],
  fetchFullRatesListError: ['error', ],

  updateMerchantStatus: ['merchantId', 'merchantStatus', 'closureReason'],
  updateMerchantStatusSuccess: ['data', ],
  updateMerchantStatusSuccessNoReload: ['data', ],
  updateMerchantStatusError: ['error', ],

  updateFundingHold: ['merchantId', 'data'],
  updateFundingHoldSuccess: ['data', ],
  updateFundingHoldError: ['error', ],

  sendBeneficialData: ['merchantId', 'data'],
  sendBeneficialDataSuccess: ['data', ],
  sendBeneficialDataError: ['error', ],

  // fetchMerchantGatewayStatus: ['merchantId', 'gatewayId', 'gatewayAltId', 'processorId', ],
  // fetchMerchantGatewayStatusSuccess: ['data', ],
  // fetchMerchantGatewayStatusError: ['error', ],

  revertProcessor: ['merchantId', 'data', ],
  revertProcessorSuccess: ['data', ],
  revertProcessorError: ['error', ],

  acceptTOS: ['merchantId', ],
  acceptTOSSuccess: ['data', ],
  acceptTOSError: ['error', ],

  fetchMerchantAlerts: ['merchantId'],
  fetchMerchantAlertsSuccess: ['data', ],
  fetchMerchantAlertsError: ['error', ],

  uploadCheckoutFile: ['file', 'localFileIdentifier', ],
  uploadCheckoutFileSuccess: ['data', ],
  uploadCheckoutFileError: ['error', ],

  removeCheckoutFile: ['fileId', 'fileType', ],
  removeCheckoutFileSuccess: ['data', 'fileType', ],
  removeCheckoutFileError: ['error', 'fileType', ],

  updateUploadedFilesStore: ['data', 'fileType', ],
  resetUploadedFilesStore: [],
  removeExternalFile: ['fileType', ]
})

export const MerchantActionTypes = Types
export default Creators
