import React from 'react'
import _ from 'lodash'
import NotificationSystem from 'react-notification-system'

//TODO: check if is necesary to set this property throw the action.
const NOTIFICATION_TIMEOUT = 5
const ERROR_NOTIFICATION_TIMEOUT = 15

/**
 * Component used to display the notifications to the user.
 */
class NotificationCenter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notifications: props.messages
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.messages.length > this.state.notifications.length) {
      this.setState({
        notifications: this.props.messages
      })
      const newNotification = _.last(this.props.messages)
      this._addNotification(newNotification)
    } else if (this.props.messages.length !== this.state.notifications.length) {
      this.setState({
        notifications: this.props.messages
      }, () => {
        if (this.state.notifications.length === 0) {
          this.notificationSystem.clearNotifications()
        }
      })
    }
  }

  /**
   * Add a notifications to the system.
   */
  _addNotification = ({title, message, level, uid, }) => {
    this.notificationSystem.addNotification({
      title: title,
      message: message,
      level: level,
      uid: uid,
      position: 'bl',
      dismissible: level == 'error',
      onRemove: this._onRemove,
      autoDismiss: level == 'error' ? ERROR_NOTIFICATION_TIMEOUT : NOTIFICATION_TIMEOUT
    });
  }

  /**
   * Callback to remove a notification from the store.
   */
  _onRemove = (notification) => {
    this.props.removeNotification(notification.uid)
  }

  render() {
    return <NotificationSystem ref={ ref => this.notificationSystem = ref } />
  }
}

export default NotificationCenter
