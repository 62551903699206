import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/device-management',

  getComponent(nextState, callback) {

    require.ensure([], (require) => {
      const DeviceManagementContainer = require('./containers/DeviceManagementContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'deviceManagement', reducer, })
      injectSagas(store, sagas)

      callback(null, DeviceManagementContainer)

    }, 'deviceManagement')
  },
})
