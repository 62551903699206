import React, { Component, } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { ThemeProvider, createTheme, } from '@mui/material/styles'
import Selector from 'components/Selector'
import Loader from 'components/Loader'
import _ from 'lodash'
import './RevertProcessorModal.scss'
import {
  PAYOUT_FREQUENCY_OPTIONS,
  PAYOUT_FREQUENCY_WEEKLY_OPTIONS,
  PAYOUT_FREQUENCY_MONTHLY_OPTIONS,
} from '../../constants'

const theme = createTheme({
  palette: {
    primary: {
      light: '#0d2b40',
      main: '#0d2240',
      dark: '#0d1740',
    },
    secondary: {
      light: '#ed2121',
      main: '#d11010',
      dark: '#910505',
    },
  },
  typography: {
    useNextVariants: true,
  },
})

class RevertProcessorModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false,
      selectedRateId: null,
      selectedRateName: null,
      selectedPayoutFrequency: null,
      selectedPayoutDay: null,
      isFormReady: false,
      error: false,
      // gatewayAccountActive: this.props.merchantGatewayStatus
    }
  }

  _handleClose = () => {
    this._resetState()
    this.props.handleClose()
  }

  handleChange = (value, property) => this.setState({ [property]: value, })

  _resetState = () => {
    this.setState({
      isSubmitting: false,
      selectedRateId: null,
      selectedRateName: null,
      selectedPayoutFrequency: null,
      selectedPayoutDay: null,
      isFormReady: false,
      error: false,
    })
  }

  checkIfReady = () => {
    // const gatewayAccountActive = this.props.merchantGatewayStatus
    const rateIsSelected = !_.isEmpty(this.state.selectedRateId)
    const shouldPayoutFreqBeSelected = (parseInt(this.props.oldProcessorId, 10) === 3)
    const payoutFreqIsSelected = !_.isEmpty(this.state.selectedPayoutFrequency)
    const shouldPayoutDayBeSelected = this.state.selectedPayoutFrequency !== 'daily'
    const payoutDayIsSelected = !_.isEmpty(this.state.selectedPayoutDay)

    const isReady = (/* gatewayAccountActive && */ rateIsSelected && !shouldPayoutFreqBeSelected) ||
      (/* gatewayAccountActive && */ rateIsSelected && shouldPayoutFreqBeSelected && payoutFreqIsSelected &&
        !shouldPayoutDayBeSelected) ||
      (/* gatewayAccountActive && */ rateIsSelected && shouldPayoutDayBeSelected && payoutFreqIsSelected
        && shouldPayoutDayBeSelected && payoutDayIsSelected)

    this.setState({ isFormReady: isReady })
  }

  _onChangeRate = (rate) => {
    this.setState({ selectedRateId: rate.value, selectedRateName: rate.label }, () => this.checkIfReady())
  }

  _onChangePayout = (payout) => {
    this.setState({ selectedPayoutFrequency: payout.value, selectedPayoutDay: null }, () => this.checkIfReady())
  }

  _onChangePayoutDay = (payout) => {
    this.setState({ selectedPayoutDay: payout.value, }, () => this.checkIfReady())
  }

  _submitRevert = () => {
    const payload = {
      selectedRateId: parseInt(this.state.selectedRateId, 10),
      oldProcessorGatewayId: this.props.oldProcessorGatewayId,
      oldProcessorGatewayAltId: this.props.oldProcessorGatewayAltId,
      oldProcessorId: this.props.oldProcessorId,
    }

    if (this.props.oldProcessorId === 3) {
      payload.selectedPayoutFrequency = this.state.selectedPayoutFrequency
      payload.selectedPayoutDay = this.state.selectedPayoutDay
      payload.selectedRateName = this.state.selectedRateName
    } else {
      payload.selectedPayoutFrequency = 'daily'
      payload.selectedPayoutDay = null
      payload.selectedRateName = `${this.props.baseTier} ${this.state.selectedRateName}`
    }

    if (this.state.isFormReady) {
      this.setState({ isSubmitting: true, })
      this.props.onSubmit(payload)
    } else {
      this.setState({ error: true, })
    }
  }

  render() {
    const { open, oldProcessorGatewayId, oldProcessorGatewayAltId, oldProcessorId, rateOptions, /*isFetchingMerchantGatewayStatus*/ } = this.props
    const { isSubmitting, selectedRateId, selectedPayoutFrequency,
      selectedPayoutDay, error, /*gatewayAccountActive*/ } = this.state

    if (error === true) { setTimeout(() => this.setState({ error: false, }), 4000) }

    return (
      <Dialog
        className='revert-processor-dialog'
        open={open}
        onClose={() => {
          this._handleClose()
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <React.Fragment>
          <DialogTitle id="responsive-dialog-title">
            <span>Revert Processor Migration</span>
          </DialogTitle>
          <div style={{
            width: '360px',
            fontSize: '14px',
            lineHeight: '24px',
            fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
            color: '#444'
          }}>
            <DialogContent className='revert-processor'>
              <React.Fragment>
                <Selector
                  label="Rate"
                  options={rateOptions}
                  value={selectedRateId}
                  searchable={false}
                  onChange={(option) => { this._onChangeRate(option) }}
                />
                <p style={{ marginTop: '14px' }}>
                  <strong>Old Processor:</strong><br/>
                  {parseInt(oldProcessorId, 10) === 2 && <span>ProPay</span>}
                  {parseInt(oldProcessorId, 10) === 3 && <span>Stripe</span>}
                </p>
                <p>
                  <strong>Old Processor MID:</strong><br/>
                  <span className='processorMID'>
                    {parseInt(oldProcessorId, 10) === 2 && <span>{oldProcessorGatewayAltId}</span>}
                    {parseInt(oldProcessorId, 10) === 3 && <span>{oldProcessorGatewayId}</span>}
                    {/* commenting this out because I think it would be good... but we don't have the
                        endpoints to support it or the time to build them on this story.
                    {isFetchingMerchantGatewayStatus &&
                      <span className='material-icons loading' title='Checking Account Status...'>
                        sync</span>
                    }
                    {!isFetchingMerchantGatewayStatus &&
                      (gatewayAccountActive ?
                        <span className='material-icons active' title='Account on target processor is Active'>
                        check_circle_outline</span> :
                        <span className='material-icons inactive' title='Account on target processor is Inactive'>
                        highlight_off</span>
                      )
                    } */}
                  </span>
                </p>
                {parseInt(oldProcessorId, 10) === 3 &&
                  <div className='payout-picker'>
                    <Selector
                      label="Deposit Frequency"
                      options={PAYOUT_FREQUENCY_OPTIONS}
                      value={selectedPayoutFrequency}
                      searchable={false}
                      onChange={(option) => { this._onChangePayout(option) }}
                    />
                    {selectedPayoutFrequency === 'monthly' &&
                      <Selector
                        label="Day"
                        options={PAYOUT_FREQUENCY_MONTHLY_OPTIONS}
                        value={selectedPayoutDay}
                        searchable={false}
                        onChange={(option) => { this._onChangePayoutDay(option) }}
                      />
                    }
                    {selectedPayoutFrequency === 'weekly' &&
                      <Selector
                        label="Day"
                        options={PAYOUT_FREQUENCY_WEEKLY_OPTIONS}
                        value={selectedPayoutDay}
                        searchable={false}
                        onChange={(option) => { this._onChangePayoutDay(option) }}
                      />
                    }
                    {error &&
                      <span style={{
                        fontSize: '12px',
                        fontWeight: '700',
                        marginTop: '-14px',
                        marginLeft: '2px',
                        fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                        color: '#c00',
                        display: 'block',
                        width: '350px',
                      }}>Please select a valid day for the payout.</span>
                    }
                  </div>
                }
              </React.Fragment>
            </DialogContent>

            {isSubmitting ?
              <Loader />
              :
              <DialogActions>
                <React.Fragment>
                  <ThemeProvider theme={theme}>
                    <div style={{ width: '100%', marginBottom: '10px', textAlign: 'center', }}>
                      <Button
                        onClick={() => {
                          this._handleClose()
                        }}
                        color="secondary"
                        variant="contained"
                        style={{ marginRight: '10px', }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this._submitRevert()
                        }}
                        color="primary"
                        variant="contained"
                        disabled={!this.state.isFormReady}
                      >
                        Revert
                      </Button>
                    </div>
                  </ThemeProvider>
                </React.Fragment>
              </DialogActions>
            }
          </div>
        </React.Fragment>
      </Dialog>
    )
  }
}

export default RevertProcessorModal
