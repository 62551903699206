import React from 'react'
export default function StackMark() {
  return (
    <svg id="stack-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 72">
      <path id="middle_line" d="M2.98,12.05c-1.65-1.12-2.98-.42-2.98,1.55v10.83c.12,2.21,1.21,4.26,2.98,5.59l45.09,30.09c1.65,1.12,2.99.38,2.99-1.59v-10.83c-.13-2.2-1.22-4.22-2.99-5.54L2.98,12.05Z" fill="#de0d26" strokeWidth="0"/>
      <path id="top_right_triangle" d="M31.14.59c-1.59-1.2-2.9-.51-2.9,1.47v14.2c0,1.99,1.62,3.61,3.61,3.61h19.19c1.97,0,2.3-.96.69-2.12L31.14.59Z" fill="#de0d26" strokeWidth="0"/>
      <path id="bottom_left_triangle" d="M22.29,72.63c1.59,1.2,2.91.51,2.91-1.49v-14.18c0-1.99-1.62-3.61-3.61-3.61H2.41c-1.96,0-2.28.96-.67,2.13l20.56,17.15Z" fill="#de0d26" strokeWidth="0"/>
    </svg>
  )
}
