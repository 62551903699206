import React, { Component, } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Provider, } from 'react-redux'
import { Router, } from 'react-router'
import { persistStore, } from 'redux-persist'
import persistConfig from 'config/PersistConfig'
import Loader from 'components/Loader'
import AccountActions from 'ducks/Account/actions'
import AuthActions from 'ducks/Auth/actions'

/**
 * This provider wrapper is provided in order to allow rehydration to complete before app loads.
 * Once the store has been persisted in willMount() the app will load with any whitelisted reducers
 * rehydrated
 */
export default class AppProvider extends Component {
  constructor() {
    super()
    this.state = {
      rehydrated: false,
      loaded: false,
      showSessionTimeoutModal: false,
      loggedOut: false,
    }
  }

  _initIdleTimeoutCheck() {
    window.checkTimeout = window.setInterval(() => {
      if (window.idleUser === true) {
        this._handleIdleUser()
      }
    }, 1000)
  }

  _handleIdleUser() {
    if (!window.localStorage.getItem('turnstileEmail') || !window.localStorage.getItem('refreshToken')) {
      this.setState({ loggedOut: true, })
    }
    this.setState({ showSessionTimeoutModal: true, })
    clearInterval(window.checkTimeout)
    clearTimeout(window.idleTimeout)

    const countdownNumberEl = document.getElementById('countdown-number')
    let countdown = 30

    if (countdownNumberEl) {
      countdownNumberEl.textContent = countdown
    }

    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      countdown = --countdown <= 0 ? 0 : countdown

      if (countdownNumberEl) {
        countdownNumberEl.textContent = countdown
      }
    }, 1000)

    window.logoutTimer = window.setTimeout(() => {
      this._onTimeoutIgnore()
    }, 30000)
  }

  componentDidMount() {
    this._initIdleTimeoutCheck()

    // Run rehydration of persisted stores with config options required for immutable transforms
    persistStore(this.props.store, persistConfig, (error, state) => {
      // TODO check for rehydration error before continuing with loading the app
      this.setState({ rehydrated: true, })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rehydrated && !prevState.rehydrated) {
      // When the data is rehidrated we suscribe a callback to the store and dispatch an
      // action to fetch the user data.
      const auth = this.props.store.getState().auth

      if (auth.isAuthenticated) {
        this.unsubscribe = this.props.store.subscribe(this._onStoreChange)
        this.props.store.dispatch(AccountActions.fetchAccountRequest())
      } else {
        this.setState({ loaded: true })
      }
    }
  }

  _onStoreChange = () => {
    let { account, } = this.props.store.getState()
    if (!_.isEmpty(account.data) || account.expiredToken || account.notFound) {
      // If the account data was fetched or the token expired we kill the subscription to changes.
      this.unsubscribe()
      this.setState({ loaded: true, })
    }
  }

  _onTimeoutIgnore = () => {
    persistStore(this.props.store, persistConfig, () => { }).purge()
    this.props.store.dispatch(AuthActions.passiveLogout())
  }

  _onTimeoutReset = () => {
    this.setState({ showSessionTimeoutModal: false, })
    this.props.store.dispatch(AuthActions.refreshUserSession(
      window.localStorage.getItem('turnstileEmail'),
      window.localStorage.getItem('refreshToken')
    ))
    clearTimeout(window.logoutTimer)
    this._initIdleTimeoutCheck()
    window.resetIdleTimeout()
  }

  render() {
    const { rehydrated, loaded, showSessionTimeoutModal, loggedOut } = this.state
    if (!rehydrated || !loaded) {
      return <Loader />
    }

    const message = loggedOut ? (
      <React.Fragment>
        <h2>Your session has ended</h2>
        <p>You were logged out in another tab or window.</p>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <h2>Are you still here?</h2>
        <p>You will be logged out shortly for security...</p>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <Provider store={this.props.store}>
          <Router history={this.props.routerHistory} routes={this.props.routes} />
        </Provider>

        {showSessionTimeoutModal && <div className='timeoutModal'>
          <div className='timeoutModalOverlay'></div>
          <div className='timeoutModalWrapper'>
            <div className='timeoutModalMessage'>
              { message }
              <div id="countdown">
                <div id="countdown-number"></div>
                <svg>
                  <circle r="42" cx="50" cy="50"></circle>
                </svg>
              </div>
            </div>
            <div className='timeoutModalActions'>
              <div className='ignore'><button type='button' onClick={() => this._onTimeoutIgnore()}>
                I'm done, log me out.</button></div>
              {!loggedOut && <div className='reset'><button type='button' onClick={() => this._onTimeoutReset()}>
                I'm still here, keep me logged in</button></div>}
            </div>
          </div>
        </div>}
      </React.Fragment>
    )
  }
}

AppProvider.propTypes = {
  store: PropTypes.object.isRequired,
  routerHistory: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
}
