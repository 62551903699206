import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path : '/admin/reports/transaction-details',
  getComponent (nextState, callback) {
    require.ensure([], (require) => {
      const TransactionDetailsReportContainer = require('./containers/TransactionDetailsReportContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default
      const merchantListReducer = require('../MerchantList/modules/reducer').default
      const merchantListSagas = require('../MerchantList/modules/sagas').default

      injectReducer(store, { key: 'transactionDetailsReport', reducer, })
      injectSagas(store, sagas)
      injectReducer(store, { key: 'merchantList', reducer: merchantListReducer, })
      injectSagas(store, merchantListSagas)

      callback(null, TransactionDetailsReportContainer)
    }, 'transactionDetailsReport')
  }
})
