import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path : '/admin/merchants',
  getComponent (nextState, callback) {
    require.ensure([], (require) => {
      const MerchantListContainer = require('./containers/MerchantListContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'merchantList', reducer, })
      injectSagas(store, sagas)

      callback(null, MerchantListContainer)
    }, 'merchantList')
  }
})
