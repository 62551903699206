import React from 'react'
// import Header from '../../components/Header'
import HeaderContainer from '../../containers/HeaderContainer'
import NavigationBarContainer from '../../containers/NavigationBarContainer'
import Notification from 'containers/Notification/NotificationContainer'
import './MainLayout.scss'

const MainLayout = ({ children }) => (
  <div className='main-layout' id='main-layout'>
    <HeaderContainer />
    <main className='main-content'>
      {children}
    </main>
    <Notification />
  </div>
)

export default MainLayout
