import { delay, } from 'redux-saga'
import { put, call, } from 'redux-saga/effects'
import { push, replace, } from 'react-router-redux'
import _ from 'lodash'
import api from 'services/api'
import ActionCreators, { LoginTypes, } from './actions'
import AccountActions from '../Account/actions'
import DashboardActions from '../../pages/Dashboard/modules/actions'
import cognitoUtils from 'utils/cognitoUtils'
import Immutable from 'seamless-immutable'
import errorHandling from 'utils/errorHandling'

export function* loginRequest({ loginCredentials, }) {
  const { email, password, remember, } = loginCredentials
  let session

  try {
    // We try to authenticate the user against Cognito.
    session = yield call(cognitoUtils.authenticateUser, email.toLowerCase(), password)
  } catch (error) {
    try {
      // If we receive an error, it means that the user was registered using Okta, so
      // we hit an endpoint to migrate the user from Okta to Cognito.
      const migrationResult = yield call(api.auth.migrateUserIntoCognito, email.toLowerCase(), password)

      // If the migration went well, we try to authenticate the user against Cognito again.
      if (migrationResult.status === 200) {
        session = yield call(cognitoUtils.authenticateUser, email.toLowerCase(), password)
      }
    } catch (err) {
      // If we receive an error, it means the login credentials are invalid.
      yield put(ActionCreators.loginFailure({ err, }))
    }
  } finally {
    if (session) {
      if (session.userAttributes && session.requiredAttributes) {
        yield put(ActionCreators.newPasswordChallenge(session))
      } else {
        const tokens = cognitoUtils.getTokens(session)
        yield put(ActionCreators.loginSuccess(tokens, remember))
      }
    }
  }
}

export function* authorizeUserLogin({ key, }) {
  // Call to grab tokens stored in DB based on key
  try {
    const response = yield call(api.auth.authorizeUserLogin, { key, })
    const remember = ''
    const normalizedTokens = _.transform(response.data.tokens, (result, val, k) => {
      // eslint-disable-next-line no-param-reassign
      result[k[0].toLowerCase() + k.slice(1)] = val
    })
    yield put(ActionCreators.loginSuccess(normalizedTokens, remember))
  } catch (error) {
    yield put(ActionCreators.loginFailure({ error, }))
  }
}

export function* refreshUserSession({ email, refreshToken, }) {
  try {
    const emailAddress = email.toLowerCase()

    const session = yield call(api.auth.refreshSession, { email: emailAddress, refreshToken })
    if (session.status === 200) {
      const tokens = session.data.response.data.AuthenticationResult
      if (tokens) {
        const normalizedTokens = _.transform(tokens, (result, val, k) => {
          // eslint-disable-next-line no-param-reassign
          result[k[0].toLowerCase() + k.slice(1)] = val
        })

        if (normalizedTokens) {
          yield put(ActionCreators.refreshUserSessionSuccess(normalizedTokens))
        }
      } else {
        const error = session.error
        const notify = {
          level: 'error',
          title: 'Error',
          message: 'Unable to refresh your session. You will be logged out.',
        }

        yield put(ActionCreators.refreshUserSessionError({ error, notify, }))
      }
    } else {
      const error = session.error
      const notify = {
        level: 'error',
        title: 'Error',
        message: error.message,
      }

      yield put(ActionCreators.refreshUserSessionError({ error, notify, }))
    }
  } catch (error) {
    const notify = {
      level: 'error',
      title: 'Error',
      message: error.message,
    }

    yield put(ActionCreators.refreshUserSessionError({ error, notify, }))
  }
}

export function* resetPassword(data) {
  const { code, newPassword, email, } = data.data.data
  const { newPasswordChallenge, newPasswordUserAttributes, loginCredentials, recoveryEmail, } = data.data
  let result = null

  if (!newPasswordChallenge) {
    try {
      const cognitoEmail = email || recoveryEmail
      result = yield call(cognitoUtils.confirmPassword, code, newPassword, cognitoEmail.toLowerCase())
    } catch (err) {
      yield put(ActionCreators.resetPasswordError({ err, }))
    } finally {
      if (result) {
        yield put(ActionCreators.resetPasswordSuccess())
      }
    }
  } else {
    try {
      result = yield call(cognitoUtils.confirmPasswordChallenge,
        newPassword,
        Immutable.asMutable(newPasswordUserAttributes, { deep: true, }),
        loginCredentials.email.toLowerCase()
      )
    } catch (err) {
      yield put(ActionCreators.resetPasswordError({ err, }))
    } finally {
      if (result) {
        yield put(ActionCreators.resetPasswordSuccess())
      }
    }
  }
}

export function* loginRedirect() {
  localStorage.clear()
  yield put(push('/login'))
}

export function* logout(logoutAction) {
  let email

  if (logoutAction.email) {
    email = logoutAction.email
  } else {
    email = logoutAction
  }
  const currentUser = cognitoUtils.getUser(email)

  if (currentUser !== null) {
    yield call([currentUser, 'signOut', ])
  }

  const turnstileEmail = localStorage.getItem('turnstileEmail') || email
  if (turnstileEmail && typeof turnstileEmail === 'string') {
    yield call(api.auth.logout, turnstileEmail)
  }

  localStorage.removeItem('spay-mid')
  localStorage.removeItem('spay-client')
  localStorage.removeItem('marketplace')
  localStorage.removeItem('marketplace-toolbar-settings')
  localStorage.removeItem('lastURL')
  localStorage.removeItem('turnstileEmail')
  yield put(AccountActions.clearAccount())
  yield put(DashboardActions.clearMerchantSummary())
  yield put(replace('/login'))
}

export function* passiveLogout({ shouldRedirect = true }) {
  yield put(AccountActions.clearAccount())
  yield put(DashboardActions.clearMerchantSummary())
  localStorage.removeItem('spay-mid')
  localStorage.removeItem('spay-client')
  localStorage.removeItem('marketplace')
  localStorage.removeItem('marketplace-toolbar-settings')
  localStorage.removeItem('lastURL')
  localStorage.removeItem('turnstileEmail')
  if (shouldRedirect) {
    yield put(replace('/login'))
  }
}

export function* recoverAccess({ email, }) {
  const { recoveryEmail, } = email
  let response

  try {
    response = yield call(api.auth.migrateForgotPassword, recoveryEmail.toLowerCase())

    if (response && response.status === 200) {
      try {
        const result = yield call(cognitoUtils.forgotPassword, recoveryEmail.toLowerCase())

        if (result) {
          yield put(ActionCreators.recoverAccessSuccess())
        }
      } catch (error) {
        const ehr = yield errorHandling.errorify(error, 'recoverAccessError')
        const notify = {
          level: 'error',
          title: 'Error',
          message: error.message + '. Error reference code: ' + ehr.code,
        }

        yield put(ActionCreators.recoverAccessError({ error, notify, }))
      }
    }
  } catch (error) {
    const ehr = yield errorHandling.errorify(error, 'recoverAccessError-known')
    const notify = {}

    if (error.response.status === 400) {
      notify.level = 'error'
      notify.title = 'Non-existent email'
      notify.message = 'The email does not exist. Error reference code:' + ehr.code
    } else {
      notify.level = 'error'
      notify.title = ehr.title
      notify.message = ehr.message
    }

    yield put(ActionCreators.recoverAccessError({ error, notify, }))
  }
}
