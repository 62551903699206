import React, { Component } from 'react'
import { Link, withRouter, } from 'react-router'
import moment from 'moment'
import _ from 'lodash'
import DateRangeSelector from 'components/DateRangeSelector'
import Loader from 'components/Loader'
import { Line } from 'react-chartjs-2'
import classnames from 'classnames'
import ModalWrapper from 'components/TwentyThreeModal'
import './Dashboard.scss'
import MerchantInfoCTA from '../MerchantInfoCTA/MerchantInfoCTA.jsx'
import PercentagePill from '../PercentagePill/PercentagePill.jsx'

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
const moneyFormatterCompact = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 2,
})

const chartSettings = (color = 'rgb(11, 160, 251)') => ({
  lineTension: 0.001,
  pointHitRadius: 10,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 0,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  borderColor: color,
  borderWidth: 2,
  pointBackgroundColor: color,
  pointBorderColor: color,
  pointRadius: 0,
  pointBorderWidth: 0,
  fill: 'origin',
})

const percentageDifference = (current, lastYear) => {
  const copyCurrent = current ? parseFloat(current) : 0
  const copyLastYear = lastYear ? parseFloat(lastYear) : 0
  if (copyCurrent === copyLastYear) {
    return '0%'
  }
  if (copyCurrent === 0) {
    return '-100%'
  }
  if (copyLastYear === 0) {
    return '+100%'
  }
  const difference = copyCurrent - copyLastYear
  const percentage = Math.round(((difference / Math.abs(copyLastYear)) * 100) * 10) / 10
  return `${percentage >= 0 ? '+' : ''}${new Intl.NumberFormat().format(percentage)}%`
}

const pillColor = (current, lastYear, pillColorFlip = false) => {
  const copyCurrent = current ? parseFloat(current) : 0
  const copyLastYear = lastYear ? parseFloat(lastYear) : 0
  const negativeColor = pillColorFlip ? '#90EE90' : '#FFCCCB'
  const positiveColor = pillColorFlip ? '#FFCCCB' : '#90EE90'
  if (copyCurrent === copyLastYear) {
    return 'rgba(0, 0, 0, 0.1)'
  }
  if (copyCurrent === 0) {
    return negativeColor
  }
  if (copyLastYear === 0) {
    return positiveColor
  }
  const difference = copyCurrent - copyLastYear
  return difference >= 0 ? positiveColor : negativeColor
}

const pillMaker = (current, lastYear, pillColorFlip = false) => `
    <div class='pill' style='background:${pillColor(current, lastYear, pillColorFlip)}'>
      ${percentageDifference(current, lastYear)}
    </div>
  `

const chartOptions = (format = 'number', title = '', min = 0, max = 0, pillColorFlip = false) => ({
  legend: {
    display: false,
    labels: {
      usePointStyle: true,
      fontSize: 9,
      fontFamily: 'Roboto, sans-serif',
    }
  },
  layout: {
    padding: 5,
  },
  maintainAspectRatio: false,
  tooltips: {
    position: 'nearest',
    mode: 'index',
    xPadding: 10,
    yPadding: 10,
    cornerRadius: 10,
    callbacks: {
      label(tooltipItem, data) {
        return (format === 'money')
          ? `${moneyFormatterCompact.format(tooltipItem.value)}`
          : `${new Intl.NumberFormat().format(tooltipItem.value)}`
      },
      beforeLabel(tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label
          ? `${data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index]}: `
          : ''
      },
      title(tooltipItem, data) {
        return title
      }
    },
    enabled: false,
    custom(tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip')

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        tooltipEl.classList.add('custom-tooltip')
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set caret position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      // Set Text
      if (tooltipModel.body) {
        const titleLines = tooltipModel.title || []
        const bodyLines = tooltipModel.body.map((bodyItem) => bodyItem)

        let innerHtml = '<thead>'

        titleLines.forEach(() => {
          const currentValue = parseFloat(tooltipModel.dataPoints[0].value || 0)
          const lastYearValue = parseFloat(tooltipModel.dataPoints[1]
            ? tooltipModel.dataPoints[1].value
            : 0
          )
          innerHtml += `<tr><th>
          <div class='title'><b>${title}</b></div>
          ${tooltipModel.body.length === 2 && tooltipModel.dataPoints[1]
    ? pillMaker(currentValue, lastYearValue, pillColorFlip)
    : ''
  }
          </th></tr>
          `
        })
        innerHtml += '</thead><tbody>'

        bodyLines.forEach((body, i) => {
          const date = body.before[0]
          const colors = tooltipModel.labelColors[i]
          let style = `background: ${colors.backgroundColor}`
          style += `; border-color: ${colors.borderColor}`
          style += '; border-width: 2px'
          style += '; width: 10px'
          style += '; height: 10px'
          style += '; display: inline-block'
          style += '; border-radius: 50%'
          style += '; margin-right: 5px'
          const span = `<span class="chartjs-tooltip-key" style="${style}"></span>`
          innerHtml += `<tr><td><div class='row-one'>${span}${date}</div><div>${body.lines[0]}</div></td></tr>`
        })
        innerHtml += '</tbody>'

        let tableRoot = tooltipEl.querySelector('table')
        if (!tableRoot) {
          tableRoot = document.createElement('table')
          tooltipEl.appendChild(tableRoot)
        }
        tableRoot.innerHTML = innerHtml
      }

      // Calculate tooltip position
      const canvas = this._chart.canvas.getBoundingClientRect()
      const positionY = canvas.top
      const positionX = canvas.left
      const topMargin = 10
      const chartCenterX = canvas.width / 2

      // Display, position, and set styles for font
      if (tooltipModel.caretX < chartCenterX) {
        tooltipEl.style.left = `${positionX + tooltipModel.caretX}px`
      } else {
        tooltipEl.style.left = `${(positionX + tooltipModel.caretX) - tooltipEl.offsetWidth}px`
      }
      tooltipEl.style.opacity = 1
      tooltipEl.style.position = 'fixed'
      tooltipEl.style.top = `${(positionY + tooltipModel.caretY) - tooltipEl.clientHeight - topMargin}px`
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
      tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
      tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`
      tooltipEl.style.pointerEvents = 'none'
    }
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  scales: {
    xAxes: [{
      display: false,
      gridLines: {
        display: false,
      },
    }],
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        stack: 1,
        stackWeight: 1,
        display: false,
        position: 'bottom',
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          min,
          max,
          callback(value, index, values) {
            return (value === Math.min(...values) || value === Math.max(...values))
              ? value
              : null
          }
        }
      },
      {
        id: 'B',
        type: 'linear',
        stack: 1,
        stackWeight: 2,
        display: false,
        position: 'bottom',
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          min,
          max,
        },
      },
    ],
  },
})

/**
 * Dashboard screen
 */
class Dashboard extends Component {
  constructor(props) {
    super(props)

    // TODO?: (jd) Move dropdown state into dedicated redux store for dropdowns?
    this.state = {
      errors: [],
      showRequestLimitModal: false,
      showMerchantInfoCTA: false,
      showDatePresetDropdown: false,
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('day').toDate(),
      pendingStartDate: moment().startOf('month').toDate(),
      pendingEndDate: moment().endOf('day').toDate(),
      dateLabel: 'Month - To - Date',
      closeDisputeMessage: false,
      dismissClosedAccountMessage: false,
      dismissStackOwnedMessage: false,
    }
  }

  componentDidMount() {
    const { fromDateFilter, toDateFilter } = this.props

    if (_.isEmpty(this.props.merchantList)) {
      this.props.fetchAccountRequest()
    }

    const fromDate = fromDateFilter ? moment(fromDateFilter).startOf('day') : moment().startOf('day').toDate()
    this.setState({ startDate: fromDate, pendingStartDate: fromDate })

    const toDate = toDateFilter ? moment(toDateFilter).endOf('day') : moment().endOf('day').toDate()
    this.setState({ endDate: toDate, pendingEndDate: toDate })

    const datePresets = [
      { startDate: moment().startOf('day').toDate(), label: 'Current Day' },
      { startDate: moment().subtract(1, 'day').startOf('day').toDate(), label: 'Prior Day' },
      { startDate: moment().startOf('month').toDate(), label: 'Month - To - Date' },
      { startDate: moment().subtract(1, 'week').startOf('day').toDate(), label: 'Last 7 Days' },
      { startDate: moment().subtract(3, 'months').startOf('month').toDate(), label: 'Last 3 Months' },
      { startDate: moment().subtract(6, 'months').startOf('month').toDate(), label: 'Last 6 Months' },
      { startDate: moment().startOf('year').toDate(), label: 'Year - To - Date' },
    ]

    const datePreset = datePresets.find((k) => moment(fromDate).isSame(k.startDate, 'day'))
    this.setState({ dateLabel: (datePreset && datePreset.label) || 'Custom Date Range' })

    if (this.props.merchantList) {
      const merchantList = this._mapMerchantAccounts(this.props.merchantList)
      const currentMID = this.getMerchantId()

      if (currentMID) {
        const merchant = _.find(merchantList, { value: currentMID.toString(), })
        if (merchant) {
          this._onAccountChange(merchant.value)
        }
      }
    }
    this.props.fetchPermissions(this.props.profile.remoteId)
  }

  componentDidUpdate(prevProps) {
    if (this.props.merchantList !== prevProps.merchantList) {
      const merchantList = this._mapMerchantAccounts(this.props.merchantList)
      const currentMID = this.getMerchantId()

      if (currentMID) {
        const merchant = _.find(merchantList, { value: currentMID.toString(), })
        if (merchant) {
          this._onAccountChange(merchant.value)
        }
      }
    }
    if (this.props.ctaInfo && (this.props.ctaInfo !== prevProps.ctaInfo)) {
      if (this.props.ctaInfo.merchant_id.toString() === this.state.selectedAccountId.toString() &&
          this._checkCTASettings(this.props.ctaInfo.cta_settings,
            this.props.merchantContactType, this.props.profile.isMerchantAdmin)) {
        this.setState({ showMerchantInfoCTA: true })
      }
    }
    if (this.props.ctaSuccessfullySubmitted === true && prevProps.isUpdating
      && (this.props.isUpdating !== prevProps.isUpdating)) {
      const ctaCategories = this.props.ctaInfo && this.props.ctaInfo.cta_cta_categories
      const ctaDocumentsRequested = this.props.ctaInfo.cta_documents_requested
      if ((ctaCategories && (ctaCategories.some((c) => c.category_name === 'business')
        || ctaCategories.some((c) => c.category_name === 'identity')))
      || (ctaDocumentsRequested && ctaDocumentsRequested.length > 0)) {
        this.setState({ showConfirmationModal: true, showMerchantInfoCTA: false })
      } else {
        this.setState({ showMerchantInfoCTA: false })
      }
    }
  }

  getMerchantId() {
    let currentMID = null
    if (_.get(this.props, 'params.id')) {
      currentMID = parseInt(_.get(this.props, 'params.id'), 10)
    } else if (_.get(this.props, 'selectedMerchantAccountId')) {
      currentMID = _.get(this.props, 'selectedMerchantAccountId')
    } else if (_.get(this.state, 'selectedAccountId')) {
      currentMID = _.get(this.state, 'selectedAccountId')
    } else if (_.get(this.props, 'router.params.accountId')) {
      currentMID = parseInt(_.get(this.props, 'router.params.accountId'), 10)
    } else if (_.get(this.props, 'routeParams.accountId')) {
      currentMID = parseInt(_.get(this.props, 'routeParams.accountId'), 10)
    } else if (!_.isEmpty(this.props.merchantList)) {
      const merchantList = this._mapMerchantAccounts(this.props.merchantList)
      currentMID = parseInt(merchantList[0].value, 10)
    }

    return currentMID
  }

  _closeConfirmationModal = () => {
    this.setState({ showConfirmationModal: false })
    const mid = this.state.selectedAccountId.toString()
    this._fetchMerchantData(mid)
  }

  /**
   * Respond to new account id
   * @param mid
   */
  _onAccountChange = (mid) => {
    if (mid) {
      const rootPath = this.props.location.pathname.split('/')
      if (parseInt(rootPath[2], 10) !== parseInt(mid, 10)) {
        this.props.router.push(`/${rootPath[1]}/${mid}`)
      }

      window.localStorage.setItem('spay-mid', mid)
      this.props.setMerchantAccountId(mid)
      this.setState({
        selectedAccountId: mid,
      }, () => {
        this._fetchMerchantData(mid)
      })
    }
  }

  _fetchMerchantData = (mid, fetchOnlyCharts = false) => {
    if (!fetchOnlyCharts) {
      this.props.fetchMerchantSummary(mid)
      this.props.fetchMerchantContactType(mid)
      this.props.fetchCtaSummary(mid)
      this.props.fetchMerchantInfoRequest(mid)
    }

    const startDate = this.state.startDate
    const endDate = this.state.endDate
    this.props.fetchChargebackCountOverview(mid, startDate, endDate)
    this.props.fetchChargebackVolumeOverview(mid, startDate, endDate)
    this.props.fetchGrossVolumeOverview(mid, startDate, endDate)
    this.props.fetchSuccessfulTransactionsOverview(mid, startDate, endDate)
    this.props.fetchAverageTransactionOverview(mid, startDate, endDate)
    this.props.fetchEvidenceNeededChargebackCountOverview(mid, moment('2017-01-01').utc(), endDate)
    this.props.fetchPayoutVolumeOverview(mid, startDate, endDate)
  }

  _checkCTASettings = (ctaSettings, merchantContactType, merchantRole) => {
    const contactTypeMapping = {
      'primary-contact': 'Primary Contact',
      'beneficial-owner': 'Beneficial Owner/Officer',
    }
    const userRole = merchantRole ? 'merchant-admin' : 'merchant-user'
    if (!ctaSettings) {
      return false
    }
    const hasPermission = ctaSettings.some((cta) => {
      if (cta.setting === 'viewable_by') {
        const viewableBy = cta.value
        if (viewableBy === userRole) {
          return true
        }

        const contactEmails = {}

        if (merchantContactType) {
          merchantContactType.map(contact => {
            const typeMap = Object.keys(contactTypeMapping).find(key => contactTypeMapping[key] === contact.contact_type)
            contactEmails[typeMap] = contact.email
          })
        }
        const userIsBeneficialOwner = contactEmails['beneficial-owner'] === this.props.profile.email
        const userIsPrimaryContact = contactEmails['primary-contact'] === this.props.profile.email

        if (
          (viewableBy === 'beneficial-owner' && userIsBeneficialOwner) ||
          (viewableBy === 'primary-contact' && userIsPrimaryContact)
        ) {
          return true
        }
      }
      return false
    })
    return hasPermission
  }

  /**
   * Maps merchant accounts array
   * @param {Array} merchantList
   */
  _mapMerchantAccounts = (merchantList) =>
    _.map(_.orderBy(merchantList, [
        (merchant) => parseInt(merchant.id, 10), (merchant) => merchant.businessCommonName.toLowerCase()],
    ['asc', 'asc', ]), (merchant) => ({
      value: merchant.id.toString(), label: merchant.businessCommonName,
    }))

  _mapMerchantAccountsToLinks = (merchantList) =>
    _.map(_.orderBy(merchantList, [
        (merchant) => parseInt(merchant.id, 10), (merchant) => merchant.businessCommonName.toLowerCase()],
    ['asc', 'asc', ]), (merchant) => (
      <span key={merchant.id} onClick={() => this._onAccountChange(merchant.id)}>
        {merchant.businessCommonName} / {merchant.id}</span>
    ))

  /**
   * Respond to new account id
   * @param {Object} newReport
   */
  _onReportChange = (newReport) => {
    if (newReport && newReport.hasOwnProperty('value')) {
      this.props.router.push(`/${newReport.value}/${this.state.selectedAccountId}`)
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  _submitCTAUpdate = (data) => {
    this.props.submitCtaSummary(data)
  }

  // TODO: create a separated component for this message (if other modal is used.)
  // eslint-disable-next-line class-methods-use-this
  _buildModalMessage() {
    return this.state.showRequestLimitModal &&
      (
        <div className="message">
          <p>If you need higher limits, please contact <a href="mailto:limits@mystackpay.com">limits@mystackpay.com</a>.
            <br/>Each request will be processed within 2-3 business days.</p>
        </div>
      )
  }

  _toggleRequestLimitModal = (open) => {
    this.setState({ showRequestLimitModal: open, })
  }

  _toggleAccountDropdown = () => {
    this.setState({ showAccountDropdown: !this.state.showAccountDropdown, })
  }

  _toggleDatePresetDropdown = () => {
    this.setState({ showDatePresetDropdown: !this.state.showDatePresetDropdown, })
  }

  _setDatesTo = (preset) => {
    const endDate = moment().endOf('day').toDate()

    const datePresets = {
      today: { startDate: moment().startOf('day').toDate(), label: 'Current Day' },
      yesterday: { startDate: moment().subtract(1, 'day').startOf('day').toDate(), label: 'Prior Day' },
      week: { startDate: moment().subtract(1, 'week').startOf('day').toDate(), label: 'Last 7 Days' },
      m2d: { startDate: moment().startOf('month').toDate(), label: 'Month - To - Date' },
      last3: { startDate: moment().subtract(3, 'months').startOf('month').toDate(), label: 'Last 3 Months' },
      last6: { startDate: moment().subtract(6, 'months').startOf('month').toDate(), label: 'Last 6 Months' },
      y2d: { startDate: moment().startOf('year').toDate(), label: 'Year - To - Date' },
      custom: { startDate: null, label: 'Custom Date Range' }
    }

    const startDate = datePresets[preset].startDate || datePresets.m2d.startDate
    return this.setState({ pendingStartDate: startDate, pendingEndDate: endDate, dateLabel: datePresets[preset].label })
  }

  _applyDateFilters = () => {
    const dates = this.dateRangeSelector.getDates()
    this.setState({
      pendingStartDate: dates.fromDate,
      pendingEndDate: dates.toDate,
      startDate: dates.fromDate,
      endDate: dates.toDate,
    }, () => {
      this.props.updateDateFilter(dates.fromDate, dates.toDate)
      this._fetchMerchantData(this.state.selectedAccountId, true)
    })
  }

  _onDateFilterChange = () => {
    if (this.state.dateLabel !== 'Custom Date Range') {
      this._applyDateFilters()
    }
  }

  _stripRoutePath = (urlPath) => {
    // urlPath -> /transaction-detail/:accountId
    const rootPath = urlPath.split('/')

    return rootPath[1]
  }


  _formatOverviewData = (a, b) => {
    const aOverviewData = Object.values(a)
    const aValues = []
    const aLabels = []
    const bOverviewData = b ? Object.values(b) : []
    const bValues = []
    const bLabels = []

    const formatData = (data, valueHolder, labelHolder) => {
      data.map((d) => {
        valueHolder.push(d.amount || d.count)
        if (d.time) {
          labelHolder.push(moment.utc(d.time).format('M/D/YYYY, ha'))
        }
        if (d.date) {
          labelHolder.push(moment.utc(d.date).utc().format('M/D/YYYY'))
        }
        return false
      })
    }

    formatData(aOverviewData, aValues, aLabels)
    if (b) formatData(bOverviewData, bValues, bLabels)

    return {
      labels: aLabels,
      datasets: [{
        yAxisID: 'A',
        label: aLabels,
        data: aValues,
        ...chartSettings('rgb(11, 160, 251)')
      }, {
        yAxisID: 'B',
        label: bLabels,
        data: bValues,
        ...chartSettings('rgba(13, 34, 64, 0.5)')
      }]
    }
  }

  _closeDisputeMessage = () => this.setState({ closeDisputeMessage: true })
  _dismissClosedAccountMessage = () => this.setState({ dismissClosedAccountMessage: true })
  _dismissStackOwnedMessage = () => this.setState({ dismissStackOwnedMessage: true })

  render() {
    const { isAccountDataFetching, isMerchantDataFetching, merchantData, merchantList, merchantContactType, ctaInfo, merchantInfo,
      isUpdating, error, isMerchantOverviewFetching, merchantOverview, permissions, } = this.props
    const { showRequestLimitModal, showAccountDropdown, startDate, endDate,
      pendingStartDate, pendingEndDate, showDatePresetDropdown, closeDisputeMessage, dismissClosedAccountMessage, dismissStackOwnedMessage } = this.state
    let disputeMessageDisabled = true
    const accountClosed = merchantInfo && [33, 133].includes(parseInt(merchantInfo.status, 10))
    const stackOwned = merchantInfo && merchantInfo.stackOwned
    const isAnyMerchantOverviewFetching = (isMerchantOverviewFetching.grossVolume ||
      isMerchantOverviewFetching.successfulTransactions ||
      isMerchantOverviewFetching.chargebackVolume ||
      isMerchantOverviewFetching.chargebackCount ||
      isMerchantOverviewFetching.evidenceNeededchargebackCount ||
      isMerchantOverviewFetching.averageTransaction)

    const displayListItem = (k, v, link = null, withBreak = false, iteratorKey = null) => {
      return (
        <React.Fragment key={ iteratorKey || k }>
          <div className='list-item'>
            <span className='key'>{ k }:</span>
            <span className='val'>
              { link === null ? v || '--' : (<Link to={ link }>{ v || '--' }</Link>) }
            </span>
          </div>
          { withBreak && <hr/> }
        </React.Fragment>
      )
    }
    const RequestLimitModal = ModalWrapper(() => this._buildModalMessage())
    const canManageDisputes = permissions ?
      permissions.permissions.filter((el) => el.slug === 'can-manage-disputes' && el.enabled === true) :
      []

    if (canManageDisputes && canManageDisputes.length > 0 && merchantOverview.evidenceNeededChargebackCount) {
      if (merchantOverview.evidenceNeededChargebackCount.summedData.count > 0) {
        disputeMessageDisabled = false
      }
    }

    const merchantURL = `/account/merchants/${merchantInfo && merchantInfo.id}`

    if (merchantInfo && merchantInfo.clientId) {
      window.localStorage.setItem('spay-client', merchantInfo.clientId)
    }

    return (
      <div className="dashboard-page">
        <div className='responsive-container'>
          <div className="header">
            <h1>Dashboard</h1>
            {merchantList && merchantList.length > 0 && merchantInfo &&
              <div
                className={classnames('account-selector', { open: showAccountDropdown === true })}
                onClick={() => this._toggleAccountDropdown()}
              >
                <div className='selected-account'>
                  <span className='name'>
                    <span className='dba'>{merchantInfo.businessCommonName}</span>
                    <span className='mid'>&nbsp;/ {merchantInfo.id}</span>
                  </span>
                  <span className='material-icons'>expand_more</span>
                </div>
                <div className='option-list'>
                  {this._mapMerchantAccountsToLinks(merchantList)}
                </div>
              </div>
            }

            <div className='date-filters'>
              {isAnyMerchantOverviewFetching && <span className='loading'>Loading Merchant Overview...</span>}
              {!isAnyMerchantOverviewFetching &&
                <React.Fragment>
                  <div
                    className={classnames('quick-dates-selector', { open: showDatePresetDropdown === true })}
                    onClick={() => this._toggleDatePresetDropdown()}>
                    <div className='selected-option'>
                      <div className='label'>{ this.state.dateLabel }</div>
                      <span className='material-icons'>expand_more</span>
                    </div>
                    <div className='option-list'>
                      <span onClick={() => this._setDatesTo('today')}>Current Day</span>
                      <span onClick={() => this._setDatesTo('yesterday')}>Prior Day</span>
                      <span onClick={() => this._setDatesTo('week')}>Last 7 Days</span>
                      <span onClick={() => this._setDatesTo('m2d')}>Month - To - Date</span>
                      <span onClick={() => this._setDatesTo('last3')}>Last 3 Months</span>
                      <span onClick={() => this._setDatesTo('last6')}>Last 6 Months</span>
                      <span onClick={() => this._setDatesTo('y2d')}>Year - To - Date</span>
                      <span onClick={() => this._setDatesTo('custom')}>Custom Date Range</span>
                    </div>
                  </div>
                  <div
                    className={classnames('date-calendar', { disabled: this.state.dateLabel !== 'Custom Date Range' })}>
                    <DateRangeSelector
                      ref={(element) => this.dateRangeSelector = element}
                      fromDate={moment(pendingStartDate)}
                      toDate={moment(pendingEndDate)}
                      onDateChange={this._onDateFilterChange}
                      useMaterialIcon={true}
                    />
                  </div>
                  {this.state.dateLabel === 'Custom Date Range' &&
                    <button onClick={this._applyDateFilters}>
                      <span>Apply</span><span className='material-icons'>navigate_next</span>
                    </button>}
                </React.Fragment>
              }
            </div>
          </div>
          {(stackOwned === true && !dismissStackOwnedMessage) &&
            <div className='stack-owned-message no-action-button'>
              <button className='dismiss-button' onClick={ this._dismissStackOwnedMessage }>
                <span className='material-icons'>close</span>
              </button>
              <div>
                <h2 className='message-title'>Alert</h2>
                <p className="content-group">
                  This is a Stack Sports owned merchant account.
                </p>
              </div>
            </div>
          }
          {(accountClosed === true && !dismissClosedAccountMessage) &&
            <div className='closed-account-message no-action-button'>
              <button className='dismiss-button' onClick={ this._dismissClosedAccountMessage }>
                <span className='material-icons'>close</span>
              </button>
              <div>
                <h2 className='message-title'>Account Closed</h2>
                <p className="content-group">Your account is closed and cannot accept payments.
                  If this is unexpected or, for more details, please contact us
                  at <a href='mailto:stackpaysupport@stacksports.com'>stackpaysupport@stacksports.com</a>.
                  For us to quickly and better serve you, please include your Merchant ID of
                  (<span className='merchant-info-item'>{merchantInfo.id}</span>) and DBA Name
                  of (<span className='merchant-info-item'>{merchantInfo.businessCommonName}</span>) in your email.
                </p>
              </div>
            </div>
          }
          {(!disputeMessageDisabled && !closeDisputeMessage) &&
          <div className='dispute-message'>
            <button className='dismiss-button' onClick={ this._closeDisputeMessage }>
              <span className='material-icons'>close</span>
            </button>
            <div>
              <h2 className='message-title'>Response Needed</h2>
              <p className="content-group">
                {'You have disputes that require a response. It is important to respond to your dispute(s) ' +
                  'immediately, as you have a limited timeframe to provide your supporting evidence.'}
              </p>
            </div>
            <span className='action-link'>
              <Link to={`/disputes/${this.state.selectedAccountId}`}>
                <span className='label'>Respond Now</span>
              </Link>
            </span>
          </div>
          }
          <div className='content-wrapper'>
            <div className='graphs'>
              <div className='card-row'>
                <div className='dashboard-card gross-volume'>
                  <h2>Gross Volume
                    {(!isMerchantOverviewFetching.grossVolume && merchantOverview.grossVolume)
                      && merchantOverview.grossVolume.summedData.data2
                      ? <PercentagePill percentage={percentageDifference(
                        merchantOverview.grossVolume.summedData.data[0].amount,
                        merchantOverview.grossVolume.summedData.data2[0].amount
                      )}
                      color={pillColor(
                        merchantOverview.grossVolume.summedData.data[0].amount,
                        merchantOverview.grossVolume.summedData.data2[0].amount
                      )}
                      />
                      : ''
                    }
                  </h2>
                  <ReportLink url={`/transaction-detail/${this.state.selectedAccountId}`} />
                  {(!isMerchantOverviewFetching.grossVolume && merchantOverview.grossVolume) ?
                    <React.Fragment>
                      <p className='numeric-data-value'>
                        {merchantOverview.grossVolume.summedData.data[0].amount
                          ? moneyFormatter.format(merchantOverview.grossVolume.summedData.data[0].amount)
                          : '$0'
                        }
                        {merchantOverview.grossVolume.summedData.data2
                          ? <span className='sub-label'>
                            {merchantOverview.grossVolume.summedData.data2[0].amount
                              ? moneyFormatter.format(merchantOverview.grossVolume.summedData.data2[0].amount)
                              : '$0'
                            }
                            {' '}previous year
                          </span>
                          : ''
                        }
                      </p>
                      <div className='chart'>
                        {(merchantOverview.grossVolume.segmentedData.data.length > 0) ?
                          <Line
                            data={this._formatOverviewData(
                              merchantOverview.grossVolume.segmentedData.data,
                              merchantOverview.grossVolume.segmentedData.data2
                            )}
                            options={ chartOptions(
                              'money',
                              'Gross Volume',
                              merchantOverview.grossVolume.bounds.min,
                              merchantOverview.grossVolume.bounds.max
                            )}
                          />
                          : <p>No data</p>
                        }
                      </div>
                      <div className='legend'>
                        <span>{moment(startDate).format('MMMM Do, YYYY')}</span>
                        <span>{moment(endDate).format('MMMM Do, YYYY')}</span>
                      </div>
                    </React.Fragment>
                    : <Loader />
                  }
                </div>
                <div className='dashboard-card successful-transactions'>
                  <h2>Successful Transactions
                    {(!isMerchantOverviewFetching.successfulTransactions && merchantOverview.successfulTransactions)
                      && merchantOverview.successfulTransactions.summedData.data2
                      ? <PercentagePill percentage={percentageDifference(
                        merchantOverview.successfulTransactions.summedData.data[0].count,
                        merchantOverview.successfulTransactions.summedData.data2[0].count
                      )}
                      color={pillColor(
                        merchantOverview.successfulTransactions.summedData.data[0].count,
                        merchantOverview.successfulTransactions.summedData.data2[0].count
                      )}
                      />
                      : ''
                    }</h2>
                  <ReportLink url={`/transaction-detail/${this.state.selectedAccountId}`} />
                  {(!isMerchantOverviewFetching.successfulTransactions && merchantOverview.successfulTransactions) ?
                    <React.Fragment>
                      <p className='numeric-data-value'>
                        {merchantOverview.successfulTransactions.summedData.data[0].count
                          ? new Intl.NumberFormat().format(
                            merchantOverview.successfulTransactions.summedData.data[0].count
                          )
                          : 0
                        }
                        {merchantOverview.successfulTransactions.summedData.data2
                          ? <span className='sub-label'>
                            {merchantOverview.successfulTransactions.summedData.data2[0].count
                              ? new Intl.NumberFormat().format(
                                merchantOverview.successfulTransactions.summedData.data2[0].count
                              )
                              : '0'
                            }
                            {' '}previous year
                          </span>
                          : ''
                        }
                      </p>
                      <div className='chart'>
                        {(merchantOverview.successfulTransactions.segmentedData.data.length > 0) ?
                          <Line
                            data={this._formatOverviewData(
                              merchantOverview.successfulTransactions.segmentedData.data,
                              merchantOverview.successfulTransactions.segmentedData.data2
                            )}
                            options={ chartOptions(
                              '',
                              'Successful Transactions',
                              merchantOverview.successfulTransactions.bounds.min,
                              merchantOverview.successfulTransactions.bounds.max
                            )}
                          />
                          : <p>No data</p>
                        }
                      </div>
                      <div className='legend'>
                        <span>{moment(startDate).format('MMMM Do, YYYY')}</span>
                        <span>{moment(endDate).format('MMMM Do, YYYY')}</span>
                      </div>
                    </React.Fragment>
                    : <Loader />
                  }
                </div>
                <div className='dashboard-card payout-volume'>
                  <h2>Payouts
                    {(!isMerchantOverviewFetching.payoutVolume && merchantOverview.payoutVolume)
                      && merchantOverview.payoutVolume.summedData.data2
                      ? <PercentagePill percentage={percentageDifference(
                        merchantOverview.payoutVolume.summedData.data[0].amount,
                        merchantOverview.payoutVolume.summedData.data2[0].amount
                      )}
                      color={pillColor(
                        merchantOverview.payoutVolume.summedData.data[0].amount,
                        merchantOverview.payoutVolume.summedData.data2[0].amount
                      )}
                      />
                      : ''
                    }</h2>
                  <ReportLink url={`/payouts/${this.state.selectedAccountId}`} />
                  {(!isMerchantOverviewFetching.payoutVolume && merchantOverview.payoutVolume) ?
                    <React.Fragment>
                      <p className='numeric-data-value'>
                        {merchantOverview.payoutVolume.summedData.data[0].amount
                          ? moneyFormatter.format(merchantOverview.payoutVolume.summedData.data[0].amount)
                          : '$0'
                        }
                        {merchantOverview.payoutVolume.summedData.data2
                          ? <span className='sub-label'>
                            {merchantOverview.payoutVolume.summedData.data2[0].amount
                              ? moneyFormatter.format(merchantOverview.payoutVolume.summedData.data2[0].amount)
                              : '$0'
                            }
                            {' '}previous year
                          </span>
                          : ''
                        }
                      </p>
                      <div className='chart'>
                        {(merchantOverview.payoutVolume.segmentedData.data.length > 0) ?
                          <Line
                            data={this._formatOverviewData(
                              merchantOverview.payoutVolume.segmentedData.data,
                              merchantOverview.payoutVolume.segmentedData.data2
                            )}
                            options={ chartOptions(
                              'money',
                              'Payouts',
                              merchantOverview.payoutVolume.bounds.min,
                              merchantOverview.payoutVolume.bounds.max
                            )}
                          />
                          : <p>No data</p>
                        }
                      </div>
                      <div className='legend'>
                        <span>{moment(startDate).format('MMMM Do, YYYY')}</span>
                        <span>{moment(endDate).format('MMMM Do, YYYY')}</span>
                      </div>
                    </React.Fragment>
                    : <Loader />
                  }
                </div>
                <div className='dashboard-card avg-customer-spend'>
                  <h2>Average Customer Spend
                    {(!isMerchantOverviewFetching.averageTransaction && merchantOverview.averageTransaction)
                      && merchantOverview.averageTransaction.summedData.data2
                      ? <PercentagePill percentage={percentageDifference(
                        merchantOverview.averageTransaction.summedData.data[0].amount,
                        merchantOverview.averageTransaction.summedData.data2[0].amount
                      )}
                      color={pillColor(
                        merchantOverview.averageTransaction.summedData.data[0].amount,
                        merchantOverview.averageTransaction.summedData.data2[0].amount
                      )}
                      />
                      : ''
                    }</h2>
                  {(!isMerchantOverviewFetching.averageTransaction && merchantOverview.averageTransaction) ?
                    <React.Fragment>
                      <p className='numeric-data-value'>
                        {merchantOverview.averageTransaction.summedData.data[0].amount
                          ? moneyFormatter.format(merchantOverview.averageTransaction.summedData.data[0].amount)
                          : '$0'
                        }
                        {merchantOverview.averageTransaction.summedData.data2
                          ? <span className='sub-label'>
                            {merchantOverview.averageTransaction.summedData.data2[0].amount
                              ? moneyFormatter.format(merchantOverview.averageTransaction.summedData.data2[0].amount)
                              : '$0'
                            }
                            {' '}previous year
                          </span>
                          : ''
                        }
                      </p>
                      <div className='chart'>
                        {(merchantOverview.averageTransaction.segmentedData.data.length > 0) ?
                          <Line
                            data={this._formatOverviewData(
                              merchantOverview.averageTransaction.segmentedData.data,
                              merchantOverview.averageTransaction.segmentedData.data2
                            )}
                            options={ chartOptions(
                              'money',
                              'Average Customer Spend',
                              merchantOverview.averageTransaction.bounds.min,
                              merchantOverview.averageTransaction.bounds.max
                            )}
                          />
                          : <p>No data</p>
                        }
                      </div>
                      <div className='legend'>
                        <span>{moment(startDate).format('MMMM Do, YYYY')}</span>
                        <span>{moment(endDate).format('MMMM Do, YYYY')}</span>
                      </div>
                    </React.Fragment>
                    : <Loader />
                  }
                </div>
                <div className='dashboard-card chargeback-volume'>
                  <h2>Dispute Volume
                    {(!isMerchantOverviewFetching.chargebackVolume && merchantOverview.chargebackVolume)
                      && merchantOverview.chargebackVolume.summedData.data2
                      ? <PercentagePill percentage={percentageDifference(
                        merchantOverview.chargebackVolume.summedData.data[0].amount,
                        merchantOverview.chargebackVolume.summedData.data2[0].amount
                      )}
                      color={pillColor(
                        merchantOverview.chargebackVolume.summedData.data[0].amount,
                        merchantOverview.chargebackVolume.summedData.data2[0].amount,
                        true
                      )}
                      />
                      : ''
                    }</h2>
                  <ReportLink url={`/disputes/${this.state.selectedAccountId}`} />
                  {(!isMerchantOverviewFetching.chargebackVolume && merchantOverview.chargebackVolume) ?
                    <React.Fragment>
                      <p className='numeric-data-value'>
                        {merchantOverview.chargebackVolume.summedData.data[0].amount
                          ? moneyFormatter.format(merchantOverview.chargebackVolume.summedData.data[0].amount)
                          : '$0'
                        }
                        {merchantOverview.chargebackVolume.summedData.data2
                          ? <span className='sub-label'>
                            {merchantOverview.chargebackVolume.summedData.data2[0].amount
                              ? moneyFormatter.format(merchantOverview.chargebackVolume.summedData.data2[0].amount)
                              : '$0'
                            }
                            {' '}previous year
                          </span>
                          : ''
                        }
                      </p>
                      <div className='chart'>
                        {(merchantOverview.chargebackVolume.segmentedData.data.length > 0) ?
                          <Line
                            data={this._formatOverviewData(
                              merchantOverview.chargebackVolume.segmentedData.data,
                              merchantOverview.chargebackVolume.segmentedData.data2
                            )}
                            options={ chartOptions(
                              'money',
                              'Dispute Volume',
                              merchantOverview.chargebackVolume.bounds.min,
                              merchantOverview.chargebackVolume.bounds.max,
                              true
                            )}
                          />
                          : <p>No data</p>
                        }
                      </div>
                      <div className='legend'>
                        <span>{moment(startDate).format('MMMM Do, YYYY')}</span>
                        <span>{moment(endDate).format('MMMM Do, YYYY')}</span>
                      </div>
                    </React.Fragment>
                    : <Loader />
                  }
                </div>
                <div className='dashboard-card chargeback-count'>
                  <h2>Dispute Count
                    {(!isMerchantOverviewFetching.chargebackCount && merchantOverview.chargebackCount)
                      && merchantOverview.chargebackCount.summedData.data2
                      ? <PercentagePill percentage={percentageDifference(
                        merchantOverview.chargebackCount.summedData.data[0].count,
                        merchantOverview.chargebackCount.summedData.data2[0].count
                      )}
                      color={pillColor(
                        merchantOverview.chargebackCount.summedData.data[0].count,
                        merchantOverview.chargebackCount.summedData.data2[0].count,
                        true
                      )}
                      />
                      : ''
                    }</h2>
                  <ReportLink url={`/disputes/${this.state.selectedAccountId}`} />
                  {(!isMerchantOverviewFetching.chargebackCount && merchantOverview.chargebackCount) ?
                    <React.Fragment>
                      <p className='numeric-data-value'>
                        {merchantOverview.chargebackCount.summedData.data[0].count
                          ? new Intl.NumberFormat().format(
                            merchantOverview.chargebackCount.summedData.data[0].count
                          )
                          : 0
                        }
                        {merchantOverview.chargebackCount.summedData.data2
                          ? <span className='sub-label'>
                            {merchantOverview.chargebackCount.summedData.data2[0].count
                              ? new Intl.NumberFormat().format(
                                merchantOverview.chargebackCount.summedData.data2[0].count
                              )
                              : '0'
                            }
                            {' '}previous year
                          </span>
                          : ''
                        }
                      </p>
                      <div className='chart'>
                        {(merchantOverview.chargebackCount.segmentedData.data.length > 0) ?
                          <Line
                            data={this._formatOverviewData(
                              merchantOverview.chargebackCount.segmentedData.data,
                              merchantOverview.chargebackCount.segmentedData.data2
                            )}
                            options={ chartOptions(
                              '',
                              'Dispute Count',
                              merchantOverview.chargebackCount.bounds.min,
                              merchantOverview.chargebackCount.bounds.max,
                              true
                            )}
                          />
                          : <p>No data</p>
                        }
                      </div>
                      <div className='legend'>
                        <span>{moment(startDate).format('MMMM Do, YYYY')}</span>
                        <span>{moment(endDate).format('MMMM Do, YYYY')}</span>
                      </div>
                    </React.Fragment>
                    : <Loader />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        { showRequestLimitModal &&
            <RequestLimitModal title="Request Limit Increase" onRequestClose={() => {
              this._toggleRequestLimitModal()
            }} />
        }

        <MerchantInfoCTA
          isUpdating={isUpdating}
          open={this.state.showMerchantInfoCTA}
          handleClose={() => this.setState({ showMerchantInfoCTA: false })}
          onSubmit={this._submitCTAUpdate}
          submitError={error && error.data}
          formInfo={merchantInfo && merchantInfo}
          ctaInfo={ctaInfo && ctaInfo}
          uploadFile={this.props.uploadFile}
          isRemoving={ this.props.isRemoving}
          uploadedFilesStore={this.props.uploadedFilesStore}
          isUploading={this.props.isUploading}
          removeFile={this.props.removeFile}
          showConfirmationModal={this.state.showConfirmationModal}
          closeConfirmationModal={this._closeConfirmationModal}
          formSuccessfullySubmitted = {this.props.ctaSuccessfullySubmitted}
        />
      </div>
    )
  }
}

export default withRouter(Dashboard)

/*
--------------------------------------------------------------------------------
ReportLink component
This component is right here (and not in its own folder) because of its size.
-------------------------------------------------------------------------------- */
const ReportLink = (props) => (
  <Link to={props.url} className='dashboard-report-link'>
    <span className='icon'>
      <span className='material-icons'>{props.icon || 'analytics'}</span>
    </span>
    <span className='label'>{props.label || 'View Report'}</span>
  </Link>
)
