import React from 'react'
import _ from 'lodash'
import Checkbox from 'components/Checkbox'

import './CheckboxGroup.scss'
/**
 * @param  Options Array/Object with value and label describing the checkbox
 * @param  values, Checkboxes values
 * @param  name,  String, the name of the checkboxgroup  
 * @param  errors, Checkboxes errors
 * @param  onBlur, Func heckboxes values
 */
class CheckboxGroup extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: props.values ? props.values : []
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.values, prevProps)) {
      this.setState({
        values: this.props.values
      })
    }
  }

  _onCheck(name, checked) {
    let values = this.state.values
    
    if (checked) {
      values.push(name)
    } else {
      _.remove(values, (n) => {
        return n == name
      }) 
    }

    this.setState({
      values: values
    }, () => {
      this.props.onChange(values)
    })
  }

  render() {
    const { vales, } = this.state
    const { options, values, name, errors, onBlur, } = this.props
    return ( 
      <div className="checkboxgroup-component">
        {
          options.map((option, idx) => {
            return (
              <Checkbox 
                key={`checkbox-${idx}`}
                label={ option.label }
                name={`${name}-${idx}`} 
                onCheck={(evt) => { this._onCheck(option.value, evt.currentTarget.checked) }} 
                onBlur={() => { onBlur(values) }}
                checked={ values.indexOf(option.value) > -1 }
              />
            )
          })
        }

        { errors.length > 0 &&
          <div className="checkbox-group-error">
            <ul>
              {
                errors.map((error, idx) => {
                  return <li key={`${idx}-error`}>{ error }</li>
                })
              }
            </ul>
          </div>
        }
      </div>
    )
  }
}

export default CheckboxGroup;
