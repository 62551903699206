import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/pending-applications',

  getComponent(nextState, callback) {

    require.ensure([], (require) => {
      const PendingApplicationsContainer = require('./containers/PendingApplicationsContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'pendingApplications', reducer, })
      injectSagas(store, sagas)

      callback(null, PendingApplicationsContainer)

    }, 'pendingApplications')
  },
})
