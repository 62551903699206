import React from 'react'
import { TextField, InputAdornment, } from '@mui/material'
import { withStyles, } from '@mui/styles'
import styles from './styles'

const DecimalInput = ({
  classes,
  value,
  onChange,
  style = { width: '100%', marginTop: '30px', },
  required = false,
  disabled = false,
  error = false,
  helperText = false,
  label = false,
  type = 'currency',
  max = 100000,
  min = 0,
  onBlur = null,
}) => (
  <TextField
    style={style}
    value={value || ''}
    type="number"
    onChange={(e) => onChange(e.target.value.toString().replace(/([^.0-9]+)/gi, ''))}
    variant="outlined"
    required={required}
    disabled={disabled}
    error={error}
    helperText={helperText}
    label={label && label}
    onBlur={onBlur}
    InputLabelProps={{
      classes: {
        root: classes.cssLabel,
        shrink: classes.slimLabelShrink,
        focused: classes.cssFocused,
      },
    }}
    InputProps={{
      inputProps: {
        min,
        max,
        step: '0.01',
        'data-number-to-fixed': 2,
        'data-number-step-factor': 100,
      },
      notched: false,
      startAdornment: type === 'currency' && value ? <InputAdornment position="start">$</InputAdornment> : '',
      endAdornment: type === 'percent' && value ? <InputAdornment position="end">%</InputAdornment> : '',
      classes: {
        root: classes.cssOutlinedInput,
        focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
      },
    }}
  />
)

export default withStyles(styles)(DecimalInput)
