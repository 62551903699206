import { createActions, } from 'reduxsauce'

// ------------------------------------
// Constants
// ------------------------------------
const { Types, Creators, } = createActions({
  // This generates MERCHANT_SUMMARY_REQUEST action type, and action creator
  //  => Types.MERCHANT_SUMMARY_REQUEST
  //  => Creators.merchantSummaryRequest() => { type: 'MERCHANT_SUMMARY_REQUEST', accountId: '1234' }
  merchantSummaryRequest: ['accountId', ],
  merchantSummarySuccess: ['result', ],
  merchantSummaryFailure: ['error', ],
  merchantContactTypeRequest: ['merchantId', ],
  merchantContactTypeSuccess: ['contactType', ],
  merchantContactTypeFailure: ['error', ],
  clearMerchantSummary: [],
  ctaSummaryRequest: ['merchantId'],
  ctaSummarySuccess: ['ctaInfo'],
  ctaSummaryFailure: ['error'],
  submitCtaSummary: ['data', ],
  submitCtaSummarySuccess: ['data', ],
  submitCtaSummaryError: ['error', ],
  fetchMerchantInfoRequest: ['merchantId', ],
  fetchMerchantInfoSuccess: ['data', ],
  fetchMerchantInfoFailure: ['error', ],

  fetchChargebackCountOverview: ['mid', 'startDate', 'endDate'],
  fetchEvidenceNeededChargebackCountOverview: ['mid', 'startDate', 'endDate'],
  fetchChargebackVolumeOverview: ['mid', 'startDate', 'endDate'],
  fetchGrossVolumeOverview: ['mid', 'startDate', 'endDate'],
  fetchSuccessfulTransactionsOverview: ['mid', 'startDate', 'endDate'],
  fetchAverageTransactionOverview: ['mid', 'startDate', 'endDate'],
  fetchPayoutVolumeOverview: ['mid', 'startDate', 'endDate'],

  fetchChargebackCountOverviewSuccess: ['data'],
  fetchEvidenceNeededChargebackCountOverviewSuccess: ['data'],
  fetchChargebackVolumeOverviewSuccess: ['data'],
  fetchGrossVolumeOverviewSuccess: ['data'],
  fetchSuccessfulTransactionsOverviewSuccess: ['data'],
  fetchAverageTransactionOverviewSuccess: ['data'],
  fetchPayoutVolumeOverviewSuccess: ['data'],

  fetchChargebackCountOverviewError: ['error'],
  fetchEvidenceNeededChargebackCountOverviewError: ['error'],
  fetchChargebackVolumeOverviewError: ['error'],
  fetchGrossVolumeOverviewError: ['error'],
  fetchSuccessfulTransactionsOverviewError: ['error'],
  fetchAverageTransactionOverviewError: ['error'],
  fetchPayoutVolumeOverviewError: ['error'],

  uploadFile: ['file', 'fileType'],
  uploadFileSuccess: ['data', 'fileType' ],
  uploadFileError: ['error', 'fileType' ],

  removeFile: ['fileId', 'fileType', ],
  removeFileSuccess: ['data', 'fileType', ],
  removeFileError: ['error', 'fileType', ]
})

export const DashboardsTypes = Types
export default Creators
