import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path : '/admin/partners',
  getComponent (nextState, callback) {
    require.ensure([], (require) => {
      const PartnerListContainer = require('./containers/PartnerListContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'partnerList', reducer, })
      injectSagas(store, sagas)

      callback(null, PartnerListContainer)
    }, 'partnerList')
  }
})
