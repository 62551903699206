import _ from 'lodash'
import { alphaNumericOnlyWithComma } from 'utils/validations'

const FIELDS_WITH_VALIDATIONS = [
  'file-uploader',
]

export const validator = (data) => {
  const errors = {}

  FIELDS_WITH_VALIDATIONS.map((name) => {
    errors[name] = []

    const emptyOrNullData = (!data[name] || data[name].trim() === '')

    if (name === 'file-uploader') {
      // validations here
    }

    return true
  })

  return _.omitBy(errors, (err) => err.length === 0)
}

export default validator
