import React from 'react'
import AppHeader from 'containers/AppHeader/AppHeaderContainer'
import AppFooter from 'components/AppFooter'
import Notification from 'containers/Notification/NotificationContainer'
import './MerchantApplicationLayout.scss'

const MerchantApplicationLayout = ({ props, children, }) => (
  <div id="app" className="merchant-application-layout">
    <div className='header-bg'>
      <div className='header-gradient'></div>
      <div className='header-octogons'></div>
    </div>
    <div className='main-parent'>
      <AppHeader {...props}/>
      <main id='main'>
        {children}
      </main>
    </div>
    <AppFooter/>
    <Notification/>
  </div>
)

export default MerchantApplicationLayout
