import React, { Component, } from 'react'
import { ALERT_TYPES } from '../../constants'
import './AlertBanner.scss'

class AlertBanner extends Component {
  render() {
    const {
      merchantAlerts
    } = this.props

    return (
      <div className="alert-banner-wrapper">
        {merchantAlerts && merchantAlerts.map((alert, idx) => {
          const alertTypeConfig = ALERT_TYPES[alert.type]
          if (alert.is_active) {
            return (
              <div className='alert-banner' key={idx}>
                <span className='icon-wrapper' style={{ backgroundColor: alertTypeConfig.color }}>
                  <span className='material-icons'>{alertTypeConfig.icon}</span>
                </span>
                <span className='alert-label'><span><strong>{alert.title}</strong>: {alert.message}</span></span>
              </div>
            )
          }
        })}
      </div>
    )
  }
}

export default AlertBanner
