import React from 'react'
import Notification from '../../containers/Notification/NotificationContainer'
import HeaderContainer from '../../pages/AdminPortal/containers/HeaderContainer'
import MerchantContainer from '../../containers/MerchantContainer'
import './MerchantLayout.scss'

class MerchantLayout extends React.Component {
  render() {
    return (
      <div className='main-layout' id='main-layout'>
        <HeaderContainer />
        <main className='main-content'>
          <MerchantContainer { ...this.props } />
        </main>
        <Notification />
      </div>
    )
  }
}

export default MerchantLayout
