import { connect, } from 'react-redux'
import AccountActionCreators from 'ducks/Account/actions'
import Header from '../../components/Header'
import AuthActionCreators from '../../../../ducks/Auth/actions'

const mapStateToProps = ({ account }) => ({
  account,
  settings: account.settings
})

const mapDispatchToProps = (dispatch) => ({
  logout: (email) => dispatch(AuthActionCreators.logout(email)),
  fetchSiteSettings: () => dispatch(AccountActionCreators.fetchSiteSettings()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
