import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path : '/admin/tools/migrate-accounts',
  getComponent (nextState, callback) {
    require.ensure([], (require) => {
      const MigrationToolContainer = require('./containers/MigrationToolContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'migrationTool', reducer, })
      injectSagas(store, sagas)

      callback(null, MigrationToolContainer)
    }, 'migration')
  }
})
