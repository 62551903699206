import moment from 'moment'

/**
 * Check if the email its valid
 * @param {String} value
 * @return {Bool}
 */
export const validEmail = (value) => {
  if (!value) {
    return false
  } else if (!/^(?!.*\.\.)(?!^\.)[A-Z0-9._%+-]+(?<!\.)@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(value)) {
    return false
  }

  return true
}

export const validStackSportsEmail = (value) => {
  if (!value) {
    return false
  } else if (!/^(?!.*\.\.)(?!^\.)[A-Z0-9._%+-]+(?<!\.)@stacksports\.com$/i.test(value)) {
    return false
  }

  return true
}

/**
 * Check if the Website URL is valid. https/http and WWW. are optional
 * @param {String} value
 * @return {Bool}
 */
export const validUrl = (value) => {
  if (!value) {
    return false
  } else if (!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(value)) {
    return false
  }

  return true
}

export const validBluesombreroUrl = (value) => {
  if (!value) {
    return false;
  } else if (!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.bluesombrero\.com(:[0-9]{1,5})?(\/.*)?$/i.test(value)) {
    return false;
  }

  return true;
}

/**
 * Check for alpha characters only
 * @param {String} value
 * @return {Bool}
 */
export const alphaCharactersOnly = (string = '') => {
  const lettersAndSpaces = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s-]+$/
  if (string && string.valueOf().match(lettersAndSpaces)) {
    return true
  }
  return false
}

/**
 * Loose check for valid ZIP codes for USA, CAN, AUS
 * @param {String} value
 * @return {Bool}
 */
export const validZipCodes = (string = '') => {
  // Valid ZIP CODES: USA, CAN, AUS
  const validZipCode = /^[0-9a-zA-Z ]{1,9}$/
  if (string.valueOf().match(validZipCode)) {
    return true
  }
  return false
}

export const validZipCodesUSA = (string = '') => {
  // Valid Zip CODE: USA
  const validZipCode = /^[0-9]{5}(?:-[0-9]{4})?$/
  if (string.valueOf().match(validZipCode)) {
    return true
  }
  return false
}

export const validPostalCodes = (string = '') => {
  // Valid Postal CODE: CAN
  const validPostalCode = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/
  if (string.valueOf().match(validPostalCode)) {
    return true
  }
  return false
}

/**
 * Check for alpha numeric only
 * @param {String} value
 * @return {Bool}
 */
export const alphaNumericOnly = (string = '') => {
  const alphaNumeric = /^[a-zA-Z0-9À-ÖØ-öø-ÿ\s-]+$/i
  if (string.valueOf().match(alphaNumeric)) {
    return true
  }
  return false
}

/**
 * Check for alpha numeric only with comma
 * @param {String} value
 * @return {Bool}
 */
export const alphaNumericOnlyWithComma = (value = '') => {
  const alphaNumeric = /^[a-zA-Z0-9À-ÖØ-öø-ÿ\s-,]+$/i
  return !!value.valueOf().match(alphaNumeric);
}


/**
 * Check for the existence of digits only
 * @param {String} value
 * @return {Bool}
 */
export const numericOnly = (string = '') => {
  const numeric = /^[0-9]+$/i
  if (string.valueOf().match(numeric)) {
    return true
  }
  return false
}

/**
 * Check for valid ZIP codes for USA and Canada
 * @return {Bool}
 * @param string
 * @param withInternationalPrefix
 */
export const validPhoneNumbers = (string = '') => {
  const validPhoneNumber = /^(\+)?(1|61{1,2})?\d{10}$/

  return string.valueOf().match(validPhoneNumber)
}

export const validUKPhoneNumbers = (string = '') => {
  const validUKPhoneNumbers = /^((\+44)|(0))?\d{4}?\d{6}$/

  return string.valueOf().match(validUKPhoneNumbers)
}

export const validPhoneNumbersFrom7To12 = (string = '') => {
  const validPhoneNumber = /^(\+?\d+)?[-. ]*?\(?([0-9]{3})\)?[-. ]*?([0-9]{3,4})[-. ]*?([0-9]{4})$/

  return string.valueOf().match(validPhoneNumber)
}

export const validMaskedPhoneNumbers = (string = '') => {
  const validPhoneNumber = /^\d{3}-\d{3}-\d{4}(\sx\d{0,6})?$/
  return string.valueOf().match(validPhoneNumber)
}

export const validPhoneExtension = (string = '') => {
  const validExtension = /^\d{0,6}$/

  return string.valueOf().match(validExtension)
}

export const validCountryCode = (string = '') => {
  const validCodes = ['+1', '+61', ]
  return validCodes.includes(string)
}
/**
 * Check for valid date of birth
 * @param {String} value
 * @return {Bool}
 */
export const validDateOfBirth = (string = '') => {
  // Valid validDateOfBirth, taking into account valid years.
  const validDate = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
  if (string.valueOf().match(validDate)) {
    return moment(string, 'MM/DD/YYYY').isValid()
  }
  return false
}

/**
 * Check for valid expiration date format (DD/MM/YYYY)
 * @param {String} value
 * @return {Bool}
 */
export const validExpirationDate = (string = '') => {
  const validDate = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
  if (string.valueOf().match(validDate)) {
    return true
  }
  return false
}

/**
 * Check for valid expiration date format (MM/YYYY)
 * @param {String} value
 * @return {Bool}
 */
export const validCardValidToDate = (string = '') => {
  const validDate = /^(0[1-9]|1[0-2])\/(19|20)\d{2}$/
  if (string.valueOf().match(validDate)) {
    return true
  }
  return false
}

/**
 * Check for valid medicare number
 * @param {String} value
 * @return {Bool}
 */
export const validMedicareCardNumber = (string = '') => {
  const validDate = /^[0-9]{10}\b$/
  if (string.valueOf().match(validDate)) {
    return true
  }
  return false
}

/**
 * Valid Insurance number has to b up to 9 characters only, without spaces.
 * @param {String} value
 * @return {Bool}
 */
export const validInsuranceNumber = (string = '') => {
  // Valid Insurance number has to b up to 9 characters only.
  const validInsurance = /^[0-9]{9}\b$/
  if (string.valueOf().match(validInsurance)) {
    return true
  }
  return false
}

/**
 * Valid Social Security Number must not be repeated numbers or incremental secuence.
 * @param {String} value
 * @return {Bool}
 */
export const invalidSocialSecurityNumber = (string = '') => {
  if (string.length === 9) {
    // These weird validations were required by the PO
    if (string.valueOf().match('123456789') || string.valueOf().match('000000000')) {
      return true
    }
  }
  return false
}

/**
 * Valid Social Security Number 9 characters, numbers only
 * @param {String} value
 * @return {Bool}
 */
export const validSSNNumber = (string = '') => {
  const validSSN = /^[0-9]{9}\b$/
  if (string.valueOf().match(validSSN)) {
    return true
  }
  return false
}

/**
 * Valid Social Security Last 4 Number 4 characters, numbers only
 * @param {String} value
 * @return {Bool}
 */
export const validSSNLast4Number = (string = '') => {
  const validSSNLast4 = /^[0-9]{4}\b$/
  if (string.valueOf().match(validSSNLast4)) {
    return true
  }
  return false
}

/**
 * Valid Account number has to b up to 17 digits only, without spaces.
 * @param {String}validPhoneNumbers value
 * @return {Bool}
 */
export const validBankAccountNumber = (string = '') => {
  // Valid Insurance number has to b 4 to 17 characters only.
  const validAccountNumber = /^[0-9]{4,17}\b$/
  if (string.valueOf().match(validAccountNumber)) {
    return true
  }
  return false
}

/**
 * Valid Account number has to b up to 17 digits only, without spaces.
 * @param {String}validPhoneNumbers value
 * @return {Bool}
 */
export const validSoftDescriptors = (string = '') => {
  const validSoftDescriptor = /^[a-zA-Z0-9_ !@#$%^&()\-+=~`[\]{}|;:,.?]+$/

  return string.valueOf().match(validSoftDescriptor)
}

/**
 * Check if the passowrd its valid
 * @param {String} value
 * @return {String} the error  (Empty string if its valid.)
 */
export const validPassword = (value) => {
  const upperCaseRegex = new RegExp(/^(?=.*[A-Z]).+$/g)
  const lowerCaseRegex = new RegExp(/^(?=.*[a-z]).+$/g)
  const numberRegex = new RegExp(/^(?=.*\d).+$/g)
  const specialCharRegex = new RegExp(/^(?=.*[_\W]).+$/g)

  if (!value) return 'This field is required'
  if (value.length < 12) {
    return 'Must contain at least 12 characters.'
  }

  if (!upperCaseRegex.test(value)) {
    return 'Must contain at least one uppercase character.'
  }

  if (!lowerCaseRegex.test(value)) {
    return 'Must contain at least one lowercase character.'
  }

  if (!numberRegex.test(value)) {
    return 'Must contain at least one number.'
  }

  if (!specialCharRegex.test(value)) {
    return 'Must contain at least one special character.'
  }

  if (value.indexOf(' ') > -1) {
    return 'Cannot contain spaces.'
  }

  return ''
}
