import React, { Component } from 'react'
import Popover from 'components/Popover'
import './DropdownMenu.scss'

class DropdownMenu extends Component {

  _buildItems = (items) => items && items.map((item, index) => (
    <li key={`item-${index}`} className='item'>{item}</li>
  ))

  render() {
    const { isVisible, items, } = this.props

    return (
      <div className='mp-dropdown-menu'>
        <Popover isVisible={isVisible}>
          <ul className='menu'>
            {this._buildItems(items)}
          </ul>
        </Popover>
      </div>
    )
  }
}

export default DropdownMenu
