import Immutable from 'seamless-immutable'
import { ReportTypes, } from './actions'
import moment from 'moment'

const today = moment().endOf('day').toDate()
const firstDayOfMonth = moment().startOf('month').toDate()

const initialState = Immutable({
  fromDateFilter: firstDayOfMonth,
  toDateFilter: today,
  queryString: null,
  activeReport: null,
  activeAccountId: null,
})

export default function reducer(state = initialState, action) {
  // NOTE: seamless-immutable provides a .merge() that performs a shallow merge by default
  switch (action.type) {
    case ReportTypes.UPDATE_DATE_FILTER:
      return state.merge({
        fromDateFilter: (action.fromDate && action.fromDate.toDate()) || firstDayOfMonth,
        toDateFilter: (action.toDate && action.toDate.toDate()) || today,
      })
    case ReportTypes.UPDATE_URL_QUERY:
      return state.merge({
        queryString: action.queryString,
      })
    case ReportTypes.UPDATE_ACTIVE_REPORT:
      return state.merge({
        activeReport: action.reportName,
      })
    case ReportTypes.UPDATE_ACTIVE_ACCOUNT_ID:
      return state.merge({
        activeAccountId: action.accountId,
      })
    default: return state
  }
}
