import { call, } from 'redux-saga/effects'

/**
 * Expose all endpoints for payment-plans
 */

const paymentPlanApi = (api) => ({
  * createPaymentPlan({ accessToken, payload, }) {
    return yield call(api.post, '/payment-plans', payload, { headers: { Authorization: `Bearer ${accessToken}`, }, })
  },

  * readPaymentPlans({ accessToken, }) {
    return yield call(api.get, '/payment-plans', { headers: { Authorization: `Bearer ${accessToken}`, }, })
  },

  * updatePaymentPlan({ accessToken, id, payload, }) {
    return yield call(api.put, `/payment-plans/${id}`, payload, { headers: { Authorization: `Bearer ${accessToken}`, }, })
  },

  * deletePaymentPlan({ accessToken, id, payload, }) {
    return yield call(api.put, `/payment-plans/${id}`, payload, { headers: { Authorization: `Bearer ${accessToken}`, }, })
  },
})

export default paymentPlanApi
