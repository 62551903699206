import { createReducer, createActions, } from 'reduxsauce'

// ------------------------------------
// Constants
// ------------------------------------
const { Types, Creators, } = createActions({
  showSuccess: ['title', 'message', ],
  showError: ['title', 'message', ],
  showWarning: ['title', 'message', ],
  removeNotification: ['uid', ],
  clearNotifications: ['level', ],
})

export const NotificationsTypes = Types
export default Creators
