import React from 'react'
import classnames from 'classnames'
import './Popover.scss'

const Popover = ({ children, isVisible, className, }) => (
  <div className='mp-popover-container'>
    <div className={classnames('mp-popover', {
      'visible': isVisible,
      [className]: !!className,
    })}>
      {children}
    </div>
  </div>
)

export default Popover
