import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/reports/outage-transactions',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const OutageTransactionsReportContainer = require('./containers/OutageTransactionsReportContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'outageTransactionsReport', reducer, })
      injectSagas(store, sagas)

      callback(null, OutageTransactionsReportContainer)
    }, 'outageTransactionsReport')
  },
})
