import { connect } from 'react-redux'
import NotificationCenter from './NotificationCenter'
import NotificationActions  from 'ducks/Notifications/actions'

const mapStateToProps = ({ notifications, }, ownProps) => {
  return {
    ...notifications
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeNotification: (uid) =>  dispatch(NotificationActions.removeNotification(uid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter)
