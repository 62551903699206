import _ from 'lodash'
import { syncedHistory, } from '../'
import { AccountActionTypes, } from '../ducks/Account/actions'

/**
 * Middleware function to redirect the user to a portal according to its role.
 */
const roleRedirector = () => {
  let roleChecked = false

  return (store) => (next) => (action) => {
    if (!roleChecked) {
      const { type, data, } = action
      let isAdmin = false

      if (type === AccountActionTypes.FETCH_ACCOUNT_SUCCESS) {
        const { pathname, } = syncedHistory.getCurrentLocation()
        isAdmin = data.profile.groups[0].name === 'mp_admin'

        if (isAdmin) {
          if (!pathname.startsWith('/admin') && !pathname.includes('payment-plans')
            && !pathname.includes('subscriptions') && !pathname.includes('verify-merchant-application')) {
            syncedHistory.push('/admin')
          }
        } else if (pathname.startsWith('/admin')) {
          syncedHistory.push('/dashboard')
        }

        roleChecked = true
      }
    }

    next(action)
  }
}

export default roleRedirector
