import Dashboard from './pages/Dashboard'
import Settings from './pages/Settings'
import PaymentLinks from './pages/PaymentLinks'
import PaymentLinkDetails from './pages/PaymentLinkDetails'

export default {
  Dashboard,
  Settings,
  PaymentLinks,
  PaymentLinkDetails
}
