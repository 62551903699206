import Immutable from 'seamless-immutable'
import { LOCATION_CHANGE, } from 'react-router-redux'
import ActionCreators, { NotificationsTypes, } from './actions'
import { debounce } from 'lodash';

// Helper to add a notification.

const addMessageDebounced = (state, { title, message, level, }) => {
  const customId = state.messages.length + 1
  const crtMessages = state.messages.concat([{
    uid: customId,
    title,
    message,
    level,
  }, ])

  return state.merge({
    messages: crtMessages,
  })
}

const addMessage = debounce(addMessageDebounced, 1, {
  'leading': true,
  'trailing': false
});


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [NotificationsTypes.SHOW_SUCCESS]: (state, { title, message, }) => addMessage(state, { title, message, level: 'success', }),
  [NotificationsTypes.SHOW_ERROR]: (state, { title, message, }) => addMessage(state, { title, message, level: 'error', }),
  [NotificationsTypes.SHOW_WARNING]: (state, { title, message, }) => addMessage(state, { title, message, level: 'warning', }),
  [NotificationsTypes.REMOVE_NOTIFICATION]: (state, { uid, }) => state.set('messages', state.messages.filter((m) => {
    m.uid !== uid
  })),
  [NotificationsTypes.CLEAR_NOTIFICATIONS]: (state, { level, }) => {
    if (!level || level === 'all') {
      return initialState
    }
    return state.set('messages', state.message.filter((m) => m.level !== level))
  },
  // The handler for LOCATION_CHANGE actions was removed due to notifications
  // disappear automatically.
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = Immutable({
  messages: [],
})

/**
 * The reducer will catch the normal actions and any action that have a object notify in the error.
 * The notify object need to be a valid notification object.
 *    - title
 *    - message
 *    - level
 */
export default function notificationsReducer(state = initialState, action) {
  if (action.error && action.error.notify) {
    return addMessage(state, { ...action.error.notify, })
  }
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
