import { call, } from 'redux-saga/effects'
import formDataBuilder from 'utils/formDataBuilder'
// import { updateMerchantStatus } from '../../pages/AccountInfo/modules/sagas';

// TODO REMOVE
// Artificial loading delay to simulate network response
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

// TODO REMOVE!
const fakeAxiosWrap = (status, data) => ({ status, data, })

/**
 * Expose all endponts for 'auth'.
 */
const AuthApi = (api) => ({
  * signup({ data, }) {
    return yield call(api.post, '/users', data)
  },
  logout: (email) => api.post('/users/logout', { email, }),
  validateToken: ({ token, }) => api.post(`/merchant-applications/${token}/validate`),
  authorizeUserLogin: ({ key }) => api.post(`/users/${key}/authorize`),
  fetchApplicationInfo: (token) => api.get(`/merchant-applications/${token}`),
  fetchAccountInfo: () => api.get('/users/info'),
  migrateUserToTurnstile: () => api.post('/users/migrate'),
  updateLastLogin: () => api.patch('/users/last-login'),
  updateAccountInfo: ({ data, }) => api.post('/users/info', data),
  updateEmail: ({ oldEmail, newEmail, }) => api.post('/v2/users/change-email', { oldEmail, newEmail, }),
  refreshSession: ({ email, refreshToken, }) => api.post('/users/refresh', { email, refreshToken, }),
  updatePassword: ({ oldPassword, newPassword, }) => {
    const accessToken = localStorage.getItem('accessToken')
    return api.post('/users/change-password', { oldPassword, newPassword, accessToken, })
  },

  fetchPendingApplications: () => api.get('/merchant-applications'),

  fetchPosPins: (merchantId) => api.get(`/merchants/${merchantId}/device-pins`),
  createPosPin: ({ fromDate, toDate, description, merchantId }) => {
    const data = {
      activeDate: fromDate,
      expireDate: toDate,
      description,
    }
    return api.post(`/merchants/${merchantId}/device-pins/create`, { data })
  },
  expirePosPin: (merchantId, pin) => api.post(`/merchants/${merchantId}/device-pins/expire`, { pin }),

  sendApplicationForm({ data, }) {
    const formData = formDataBuilder(data)
    return api.post('merchant-applications', formData)
  },
  sendAdditionalInfoForm({ data, merchantId, }) {
    const formData = formDataBuilder(Object.assign({}, data, { merchantId, }))
    return api.post('merchant-applications/more-info', formData)
  },
  * fetchAccountSummary({ accountId, }) {
    yield sleep(1000)
    const eventData = require('fixtures/accountSummary.json')

    return eventData
  },
  * fetchAccessRules({ accountId, }) {
    return yield call(api.get, `/merchants/${accountId}/access-rules`)
  },
  * fetchPermissions({ userId, }) {
    return yield call(api.get, `/permissions/${userId}`)
  },
  fetchMerchantInfo: (merchantId) => api.get(`merchants/${merchantId}`),
  fetchInternalMerchantInfo: (merchantId) => api.get(`merchants/${merchantId}/internal`),
  updateMerchantBankinfo: ({ merchantId, data, }) => api.post(`merchants/${merchantId}/primary-bank-account`, data),
  updateMerchantOwnerAddress: ({ merchantId, data, }) => api.post(`merchants/${merchantId}/update-owner`, data),
  updateMerchantContactAddress: ({ merchantId, data, }) => api.post(`merchants/${merchantId}/update-contact`, data),
  updateMerchantMailingAddress: ({ merchantId, data, }) => api.post(`merchants/${merchantId}/mailing-address`, data),
  updateMerchantBusinessPhone: ({ merchantId, data, }) => api.post(`merchants/${merchantId}/business-phone`, data),
  migrateUserIntoCognito: (email, password) => api.post('/migrations/user', { email, password, }),
  migrateForgotPassword: (email) => api.post('/migrations/forgot-password', { email, }),
  * updateMerchantStatus({ merchantId, newStatus, }) {
    return yield api.post(`/merchants/${merchantId}/status`, { status: newStatus, })
  },
  * updateBusinessDetails(merchantId, data) {
    return yield api.post(`/merchants/${merchantId}/business-details`, data)
  },
  * addMerchantUser(merchantId, data) {
    return yield api.post(`/merchants/${merchantId}/users`, data)
  },
  * updateMerchantUser(merchantId, userId, data) {
    return yield api.patch(`/merchants/${merchantId}/users/${userId}`, data)
  },
  * updateUser(merchantId, userId, data) {
    return yield api.patch(`/merchants/${merchantId}/user/${userId}`, data)
  },
  * updateMerchantUserSettings({ id, data }) {
    return yield api.patch(`/users/${id}/settings`, { data })
  },
  * removeMerchantUser(merchantId, userId) {
    return yield api.delete(`/merchants/${merchantId}/users/${userId}`)
  },
})

export default AuthApi
