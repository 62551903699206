import { connect, } from 'react-redux'
import AccountActionCreators from 'ducks/Account/actions'
import ReportsActionsCreator from 'ducks/Reports/actions'
import DashboardView from '../components/DashboardView'
import ActionCreators from '../modules/actions'

const mapStateToProps = ({ account, dashboard, reports }) => ({
  merchantList: account.data.merchants,
  selectedMerchantAccountId: account.selectedMerchantAccountId,
  isAccountDataFetching: account.isFetching,
  isMerchantDataFetching: dashboard.isFetching,
  merchantData: dashboard.merchantSummary,
  isUpdating: dashboard.isUpdating,
  isCtaDataFetching: dashboard.isFetching,
  isMerchantOverviewFetching: dashboard.isMerchantOverviewFetching,
  merchantOverview: dashboard.merchantOverview,
  merchantContactType: dashboard.contactType,
  ctaInfo: dashboard.ctaInfo,
  merchantInfo: dashboard.merchantInfo,
  error: dashboard.error,
  uploadedFilesStore: dashboard.uploadedFilesStore,
  isUploading: dashboard.isUploading,
  isRemoving: dashboard.isRemoving,
  ctaSuccessfullySubmitted: dashboard.ctaSuccessfullySubmitted,
  permissions: account.permissions,
  profile: account.data.profile,
  fromDateFilter: reports.fromDateFilter,
  toDateFilter: reports.toDateFilter,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAccountRequest: () => dispatch(AccountActionCreators.fetchAccountRequest()),
  setMerchantAccountId: (accountId) => dispatch(AccountActionCreators.setMerchantAccountId(accountId)),
  fetchMerchantSummary: (accountId) => dispatch(ActionCreators.merchantSummaryRequest(accountId)),
  fetchMerchantContactType: (merchantId) => dispatch(ActionCreators.merchantContactTypeRequest(merchantId)),
  fetchCtaSummary: (merchantId) => dispatch(ActionCreators.ctaSummaryRequest(merchantId)),
  fetchMerchantInfoRequest: (merchantId) => dispatch(ActionCreators.fetchMerchantInfoRequest(merchantId)),
  submitCtaSummary: (data) => dispatch(ActionCreators.submitCtaSummary(data)),
  fetchChargebackCountOverview: (mid, startDate, endDate) =>
    dispatch(ActionCreators.fetchChargebackCountOverview(mid, startDate, endDate)),
  fetchEvidenceNeededChargebackCountOverview: (mid, startDate, endDate) =>
    dispatch(ActionCreators.fetchEvidenceNeededChargebackCountOverview(mid, startDate, endDate)),
  fetchChargebackVolumeOverview: (mid, startDate, endDate) =>
    dispatch(ActionCreators.fetchChargebackVolumeOverview(mid, startDate, endDate)),
  fetchGrossVolumeOverview: (mid, startDate, endDate) =>
    dispatch(ActionCreators.fetchGrossVolumeOverview(mid, startDate, endDate)),
  fetchSuccessfulTransactionsOverview: (mid, startDate, endDate) =>
    dispatch(ActionCreators.fetchSuccessfulTransactionsOverview(mid, startDate, endDate)),
  fetchAverageTransactionOverview: (mid, startDate, endDate) =>
    dispatch(ActionCreators.fetchAverageTransactionOverview(mid, startDate, endDate)),
  fetchPayoutVolumeOverview: (mid, startDate, endDate) =>
    dispatch(ActionCreators.fetchPayoutVolumeOverview(mid, startDate, endDate)),
  fetchPermissions: (userId) => dispatch(AccountActionCreators.permissionsRequest(userId)),
  updateDateFilter: (fromDate, toDate) => dispatch(ReportsActionsCreator.updateDateFilter(fromDate, toDate)),
  uploadFile: (file, fileType) => dispatch(ActionCreators.uploadFile(file, fileType)),
  removeFile: (fileId, fileType) => dispatch(ActionCreators.removeFile(fileId, fileType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView)
