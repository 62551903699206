import React, { Component, } from 'react'
import { reduxForm, } from 'redux-form'
import Dropzone from 'react-dropzone'
import classnames from 'classnames'
import utils from 'utils/utils'
import _ from 'lodash'
import './AdditionalDocumentUploader.scss'
import validator from './validators'

class AdditionalDocumentUploader extends Component {
  constructor() {
    super()
    this.state = {
      uploaderOpening: '',
      uploadedFile: {},
      errors: [],
    }
  }

  componentDidMount = () => {
    const { uploadedFilesStore, fileType } = this.props
    const uploadedFileFromProps = uploadedFilesStore[fileType]
    if (uploadedFileFromProps) {
      this.setState({ uploadedFile: uploadedFileFromProps })
    }
  }

  componentDidUpdate = (prevProps) => {
    const prevIsUploading = prevProps.isUploading[this.props.fileType]
    const isUploading = this.props.isUploading[this.props.fileType]
    if (isUploading === false && prevIsUploading !== isUploading) {
      // Once the file is complete uploading, we need to update the state with the new file.
      // the uploadedFile state is now the source of truth for this uploader instance.
      const setSubmitDisabled = this.props.setSubmitDisabled || (() => {})
      setSubmitDisabled(false)
      const uploadedFileFromProps = this.props.uploadedFilesStore[this.props.fileType]
      if (uploadedFileFromProps) {
        const { uploadedFile, errors } = this.state
        if (uploadedFileFromProps.error) {
          const newErrors = new Set([...errors || []])
          newErrors.add(uploadedFileFromProps.error)
          this.setState({ uploadedFile: uploadedFileFromProps, errors: [...newErrors] })
        } else {
          const newUploadedFile = { ...uploadedFile, ...uploadedFileFromProps }
          this.setState({ uploadedFile: newUploadedFile, errors: [] })
        }
      }
    }
    if (this.props.isRemoving === false && prevProps.isRemoving !== this.props.isRemoving) {
      // we were deleting and now we're not. That means the removal was processed.
      if (this.props.uploadedFilesStore[this.props.fileType]
        && this.props.uploadedFilesStore[this.props.fileType].deleted === true) {
        this.setState({ uploadedFile: {} })
      }
    }

    if ((this.props.isSubmitting === false && prevProps.isSubmitting === true)) {
      // we were submitting. Now we're not.
      this.setState({ isSubmitting: false, })

      // should we show the success modal?
      if (this.props.showSuccessModal === true) {
        // open the success modal
        this.setState({ successOpen: true, })

        // close the main modal to make room for the success modal.
        this.closeModal(true)
      }
    }
  }
  componentWillUnmount = () => {
    // delete uploaded files from the s3 and the state
    const uploadedFileFromProps = this.props.uploadedFilesStore[this.props.fileType]
    if (this.state.uploadedFile
      && this.state.uploadedFile.uuid
      && this.state.uploadedFile.uuid !== null
      && this.state.uploadedFile.uuid !== 'external'
      && !this.props.formSuccessfullySubmitted
      && (uploadedFileFromProps && !uploadedFileFromProps.disableAutoDelete)
    ) {
      // remove from s3
      this.props.removeFile(this.state.uploadedFile.uuid, this.props.fileType)
      // remove from state
      this.setState({ uploadedFile: {}, errors: [] })
    }
    // reset the notification state
    const resetNotificationState = this.props.resetNotificationState || (() => {})
    resetNotificationState()
  }

  handleRemoveFile = (fileId, fileType) => {
    this.props.removeFile(fileId, fileType)
  }

  handleRemoveExternalFile = (fileType) => {
    this.props.fileUploaderObject.removeExternalFile(fileType)
    this.setState({ uploadedFile: {} })
  }

  _handleFileUpload = async (targetFile) => {
    if (targetFile) {
      const isImage = ['image/png', 'image/jpg', 'image/jpeg'].includes(targetFile.type)
      const thumbnail = isImage ?
        window.URL.createObjectURL(await utils.resizeImageFile(targetFile, 128))
        : null
      const fileType = this.props.fileType
      const displaySize = (targetFile.size / 1024) > 1024
        ? `${(targetFile.size / 1024 / 1024).toFixed(2)}mb`
        : `${Math.round(targetFile.size / 1024)}kb`

      this.setState({ uploadedFile: {
        name: targetFile.name,
        displaySize,
        thumbnail,
        size: targetFile.size,
        uuid: null,
        id: null,
        progress: 1,
        category: null,
        error: null,
        fileType: isImage ? 'image' : 'document'
      } })
      const setSubmitDisabled = this.props.setSubmitDisabled || (() => {})
      setSubmitDisabled(true)

      this.props.uploadFile(targetFile, fileType)
    }
  }

  _handleFileUploadEvent = async (event) => {
    const targetFile = event.target.files[0]
    if (targetFile) {
      this._handleFileUpload(targetFile)
    }
  }

  toggleUploaderActive = (e = null) => {
    if (e) {
      e.preventDefault()
    }
    if (!this.props.isUploading[this.props.fileType]) {
      this.setState({ uploaderOpening: true, })
      setTimeout(() => this.setState({ uploaderOpening: false, }), 1500)
    }
  }

  _onDrop = (file) => {
    this._handleFileUpload(file[0])
  }

  render() {
    const { isUploading, isUpdating, accept, children, isRemoving, fileType } = this.props
    const { uploaderOpening, uploadedFile, errors } = this.state

    const disableUploader = isUploading[this.props.fileType] || isUpdating || isRemoving

    return (
      <div>
        <form className="uploader-component" encType="multipart/form-data">
          <Dropzone
            className={classnames('additional-docs-drag-and-drop-uploader',
              { opening: uploaderOpening, disabled: disableUploader }
            )}
            onDrop={ (file) => { this._onDrop(file) } }
            activeClassName='activeDrop'
            onClick={() => {}}
            disabled={disableUploader}
            accept={accept || '.tif, .tiff, .jpg, .jpeg, .pdf'}
            multiple={ false }>
            {children}
            <label onClick={(e) => this.toggleUploaderActive(e)} htmlFor='file-uploader' className='upload-button'>
              Upload
            </label>
            <input
              id='file-uploader'
              type='file'
              name='fileUpload'
              disabled={disableUploader}
              accept={accept || '.tif, .tiff, .jpg, .jpeg, .pdf'}
              onChange={ (ev) => this._handleFileUploadEvent(ev) }/>
          </Dropzone>
        </form>
        <div className='file-list'>
          { uploadedFile &&
              <div className={classnames('uploaded-file',
                { deleting: uploadedFile.deleted === true })}>
                <div className='thumbnail'>
                  {uploadedFile.fileType === 'image' && <img src={uploadedFile.thumbnail} alt={'file'}/>}
                  {uploadedFile.fileType === 'document' && <span className='material-icons'>description</span>}
                </div>
                <div className='file-item'>
                  {uploadedFile.progress && uploadedFile.progress !== null && <div className='line-one'>
                    <div className={classnames('progress-indicator', { error: uploadedFile.error !== null })}>
                      <span
                        className='progress-percent-background'
                        style={{ width: `${uploadedFile.progress}%` }}/>
                      <span className='file-name'>{uploadedFile.name}</span>
                      <span className='progress-percent'>{uploadedFile.progress}%</span>
                      <span className='file-size'>({uploadedFile.displaySize})</span>
                    </div>
                    {uploadedFile.uuid && uploadedFile.uuid !== null && uploadedFile.uuid !== 'external' && <button
                      type='button'
                      className='remove-file'
                      disabled={disableUploader || isRemoving}
                      onClick={() => this.handleRemoveFile(uploadedFile.uuid, fileType)}
                    >
                      <span className='material-icons'>delete_forever</span>
                    </button>}
                    {uploadedFile.uuid && uploadedFile.uuid !== null && uploadedFile.uuid === 'external' && <button
                      type='button'
                      className='remove-file'
                      disabled={disableUploader || isRemoving}
                      onClick={() => this.handleRemoveExternalFile(fileType)}
                    >
                      <span className='material-icons'>delete_forever</span>
                    </button>}
                  </div> }
                  {!_.isEmpty(errors) && <div className='fileErrors'>
                    <ul>
                      {errors && errors.map((e, j) =>
                        <li key={`error-${fileType}-${j}`}>{e}</li>
                      )}
                    </ul>
                  </div>}
                </div>
              </div>
          }
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'uploadEvidence',
  validate: validator,
})(AdditionalDocumentUploader)
