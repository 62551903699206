import _ from 'lodash'
import axios from 'axios'
import { call, } from 'redux-saga/effects'
import moment from 'moment'
import { ERROR_WHITELIST, ERROR_FIRST_WORDS, ERROR_SECOND_WORDS } from '../constants/errors'

const api = axios.create({
  baseURL: process.env.SP_API_BASE_URL,
  headers: {
    'Cache-Control': 'no-cache',
  },
  timeout: 60000,
})

/**
 * Handles an Error
 * Takes an error object, gives it a code, saves it, determines whether or not to show it to the user.
 * @param {Error, Object} errorObj - the error object
 * @param {String} key - a key to identify the error in the whitelist
 * @param {String} [fallbackTitle] - specify a default title for the error
 * @param {String} [fallbackMessage] - specify a default message for the error
 * @type Function
 */
export function* errorify(
  errorObj,
  key,
  fallbackTitle = 'Unknown Error',
  fallbackMessage = 'Oops! Something went wrong! Please try again shortly'
) {
  // current time
  const time = moment().unix().toString()
  // generate an error code
  const code =
    `${_.sample(ERROR_FIRST_WORDS)} ${_.sample(ERROR_SECOND_WORDS)} ${time.substring(time.length - 4, time.length)}`
  // check whitelist
  const whitelist = _.find(ERROR_WHITELIST, { errorKey: key }) || false
  const properties = whitelist.properties || false
  // store it in the db
  const response = yield call(api.post, `/error/`, {
    code,
    error_object: JSON.stringify(errorObj, Object.getOwnPropertyNames(errorObj)),
    error_key: key,
  })
  // todo: add newrelic here

  if (response.status !== 200) {
    // Woah. Meta. How do you handle an error in the error handling method? Dump it into the console.
    // eslint-disable-next-line no-console
    console.error({ error_code: code, error_object: errorObj, error_key: key })

    // todo: add newrelic here?
  }

  const errorTitle = properties ? properties.errorTitle : fallbackTitle
  const errorMessage = properties ? properties.errorMessage : (errorObj.response.data.error_message || errorObj.message)
  const displayMessage = (whitelist !== false && errorMessage) ?
    `${errorMessage}. Error reference code: ${code}` :
    `${fallbackMessage}. Error reference code: ${code}`

  return { code, wl: whitelist, title: errorTitle, message: displayMessage }
}

export default {
  errorify,
}
