import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/reports/subscription-details/:accountId/:subscriptionId',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const SubscriptionDetailContainer = require('./containers/SubscriptionDetailContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'subscriptionDetail', reducer, })
      injectSagas(store, sagas)

      callback(null, SubscriptionDetailContainer)
    }, 'subscriptionDetail')
  }
})
