import React from 'react'
import './PercentagePill.scss'
// css defined in dashboard.scss

export default function PercentagePill({ percentage, color }) {
  return (
    <span className='pill' style={{ background: color }}>
      {percentage}
    </span>
  )
}
