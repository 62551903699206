import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/reports/payouts',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const BatchReconciliationReportContainer = require('./containers/BatchReconciliationReportContainer').default
      const reducer = require('../../../ReconciliationReport/modules/reducer').default
      const sagas = require('../../../ReconciliationReport/modules/sagas').default
      const merchantListReducer = require('../MerchantList/modules/reducer').default
      const merchantListSagas = require('../MerchantList/modules/sagas').default
      const reconciliationReportReducer = require('pages/ReconciliationReport/modules/reducer').default

      injectReducer(store, { key: 'batchReconciliationReport', reducer, })
      injectSagas(store, sagas)
      injectReducer(store, { key: 'merchantList', reducer: merchantListReducer, })
      injectSagas(store, merchantListSagas)
      injectReducer(store, { key: 'reconciliationReport', reducer: reconciliationReportReducer, })

      callback(null, BatchReconciliationReportContainer)
    }, 'batchReconciliationReport')
  },
})
