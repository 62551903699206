import React, { Component, } from 'react'
import { Link, withRouter } from 'react-router'
import classnames from 'classnames'
import _ from 'lodash'

class MerchantTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canViewDocuments: false,
    }
  }

  componentDidMount() {
    if (this.props.adminRoles) {
      this.setState({
        canViewDocuments: Object.keys(this.props.adminRoles).includes('can-view-documents'),
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminRoles && this.props.adminRoles !== prevProps.adminRoles) {
      this.setState({
        canViewDocuments: Object.keys(this.props.adminRoles).includes('can-view-documents'),
      })
    }
  }

  isActive = (name) => {
    let pageKey = this.props.location.pathname.split('/').pop()
    if (_.isFinite(parseInt(pageKey, 10)) || pageKey === '') {
      pageKey = 'profile'
    }

    return pageKey === name
  }

  render() {
    const mid = this.props.params.id
    const { canViewDocuments } = this.state

    return (
      <div className='tabs'>
        <Link to={`/admin/merchants/${mid}/profile`}
          className={classnames('tab-button', { active: this.isActive('profile'), })}>
          General</Link>
        <Link to={`/admin/merchants/${mid}/limits`}
          className={classnames('tab-button', { active: this.isActive('limits'), })}>
          Services</Link>
        <Link to={`/admin/merchants/${mid}/contact`}
          className={classnames('tab-button', { active: this.isActive('contact'), })}>
          Contact</Link>
        <Link to={`/admin/merchants/${mid}/banking`}
          className={classnames('tab-button', { active: this.isActive('banking'), })}>
          Banking</Link>
        <Link to={`/admin/merchants/${mid}/users`}
          className={classnames('tab-button', { active: this.isActive('users'), })}>
          Users</Link>
        <Link to={`/admin/merchants/${mid}/notifications`}
          className={classnames('tab-button', { active: this.isActive('notifications'), })}>
          Notifications</Link>
        {canViewDocuments && <Link to={`/admin/merchants/${mid}/documents`}
          className={classnames('tab-button', { active: this.isActive('documents'), })}>
          Documents</Link>}
        <Link to={`/admin/merchants/${mid}/history`}
          className={classnames('tab-button', { active: this.isActive('history'), })}>
          History</Link>
        <Link to={`/admin/merchants/${mid}/misc`}
          className={classnames('tab-button', { active: this.isActive('misc'), })}>
          Misc.</Link>
        <Link to={`/admin/merchants/${mid}/volume`}
          className={classnames('tab-button', { active: this.isActive('volume'), })}>
          Volume</Link>
      </div>
    )
  }
}

export default withRouter(MerchantTabs)
