import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js'

let pendingUser = null

/**
 * Returns the user that has the provided email
 * @param {String} email
 * @returns {Object}
 */
const getUser = (email) => {
  const poolData = {
    UserPoolId: process.env.SP_AWS_COGNITO_USER_POOL_ID,
    ClientId: process.env.SP_AWS_COGNITO_CLIENT_ID,
  }

  const userPool = new CognitoUserPool(poolData)
  const userData = {
    Username: email,
    Pool: userPool,
  }

  return new CognitoUser(userData)
}

const getCurrentUser = () => {
  const poolData = {
    UserPoolId: process.env.SP_AWS_COGNITO_USER_POOL_ID,
    ClientId: process.env.SP_AWS_COGNITO_CLIENT_ID,
  }

  const userPool = new CognitoUserPool(poolData)
  return userPool.getCurrentUser()
}

/**
 * Gets the user session
 * @param {String} email - The user email to get the current user
 * @returns {Promise}
 */
const getSession = () => {
  const currentUser = getCurrentUser()
  let currentSession = null
  if (currentUser !== null) {
    currentUser.getSession((error, session) => {
      if (error) {
        throw new Error(error)
      } else {
        currentSession = session
      }
    })
  }

  return currentSession
}

/**
 * Retrieves the user tokens from the session
 * @param {Object} session
 * @returns {Object} Object that wraps user tokens
 */
const getTokens = (session) => ({
  accessToken: session.getAccessToken().getJwtToken(),
  idToken: session.getIdToken().getJwtToken(),
  refreshToken: session.getRefreshToken().getToken(),
})

/**
 * Authenticates the user
 * @param {String} email
 * @param {String} password
 * @returns {Promise}
 */
const authenticateUser = (email, password) => new Promise((resolve, reject) => {
  const authenticationDetails = new AuthenticationDetails({ Username: email, Password: password, })
  const currentUser = getUser(email)

  if (currentUser !== null) {
    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => resolve(session),
      onFailure: (error) => reject(error),
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        const res = { userAttributes, requiredAttributes, }
        pendingUser = currentUser
        resolve(res)
      },
    })
  }
})

/**
 * Refreshes the user session
 * @param {String} refreshToken - The token required by Cognito to refresh the session
 * @param {String} email - The user email to get the current user
 * @returns {Promise}
 */
const refreshSession = (refreshToken, email) => new Promise((resolve, reject) => {
  const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken, })
  const currentUser = getUser(email)

  if (currentUser !== null) {
    currentUser.refreshSession(RefreshToken, (error, session) => {
      if (error) {
        reject(error)
      } else {
        resolve(session)
      }
    })
  }
})

/**
 * Checks if the current user session has expired
 * @param {String} email - The user email to get the current user
 * @returns {Boolean}
 */
const isSessionValid = async () => {
  const session = await getSession()
  return session ? session.isValid() : false
}

/**
 * Confirms a new password using a "confirmation code"
 * @param {String} code - The confirmation code
 * @param {String} newPassword - The new password
 * @param {String} email - The user email to get the current user
 * @returns {Promise}
 */
const confirmPassword = (code, newPassword, email) => new Promise((resolve, reject) => {
  const currentUser = getUser(email)
  if (currentUser !== null) {
    currentUser.confirmPassword(code, newPassword, {
      onSuccess: () => resolve(true),
      onFailure: (err) => reject(err),
    })
  }
})

/**
 * Initiates the "forgot password" flow
 * @param {String} recoveryEmail - The user email to get the current user
 * @returns {Promise}
 */
const forgotPassword = (recoveryEmail) => new Promise((resolve, reject) => {
  const currentUser = getUser(recoveryEmail)

  if (currentUser !== null) {
    currentUser.forgotPassword({
      onSuccess: (result) => resolve(result),
      onFailure: (error) => reject(error),
    })
  }
})

const confirmPasswordChallenge = (newPassword, userAttributes) => new Promise((resolve, reject) => {
  const newUserAttributes = Object.assign({}, userAttributes)
  delete newUserAttributes.email_verified
  if (pendingUser !== null) {
    pendingUser.completeNewPasswordChallenge(newPassword, newUserAttributes, {
      onSuccess: () => resolve(true),
      onFailure: (err) => reject(err),
    })
  }
})

export default {
  getUser,
  getCurrentUser,
  getSession,
  getTokens,
  authenticateUser,
  refreshSession,
  isSessionValid,
  confirmPassword,
  forgotPassword,
  confirmPasswordChallenge,
}
