import { createActions, } from 'reduxsauce'

// ------------------------------------
// Constants
// ------------------------------------
const { Types, Creators, } = createActions({
  // This generate UPDATE_DATE_DILFER action type, and action creator
  //  => Types.UPDATE_DATE_DILFER
  //  => Creators.accountSummaryRequest() => { type: 'UPDATE_DATE_DILFER', dateFilterObject: { myProp: 'myValue' } }
  updateDateFilter: ['fromDate', 'toDate', ],
  updateURLQuery: ['queryString', ],
  updateActiveReport: ['reportName', ],
  updateActiveAccountId: ['accountId', ],
})

export const ReportTypes = Types
export default Creators
