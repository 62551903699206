import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Button.scss";

/**
 * Globam button component.
 * @param {Node/String} children: content of the button
 * @param {boolean} primary: look and feel of the button (primary action)
 * @param {boolean} cancel : look and feel of the button (negative action)
 * @param {boolean} loading : if the button is loading (blocked)
 * @param {Node/String} loadingText : content when the button is loading.
 * @param {func} onClick: callback event
 */
export const Button = ({
  children,
  loading,
  loadingText,
  primary,
  onClick,
  cancel,
  danger,
  disabled = false,
  isSubmitButton,
  subTitleInline,
  noPrint,
  tableButton,
  popoverButton,
  active,
  className = ''
}) => (
  <button
    type={isSubmitButton ? 'submit' : 'button'}
    className={classnames(`button-component  ${className}`, {
      primary,
      cancel,
      danger,
      subTitleInline,
      noPrint,
      tableButton,
      popoverButton,
      active
    })}
    disabled={disabled}
    onClick={!loading ? onClick : null}
  >
    {loading ? loadingText : children}
  </button>
)

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired
  ]),
  primary: PropTypes.bool,
  cancel: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  loadingText: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired
  ]),
  isSubmitButton: PropTypes.bool,
  className: PropTypes.string
}

Button.defaultProps = {
  primary: true,
  loading: false,
  loadingText: 'Loading...',
  isSubmitButton: false,
  children: '',
  cancel: false,
  active: false,
  onClick: () => {},
  className: ''
}

export default Button
