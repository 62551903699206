import React from 'react'
import utils from 'utils/utils'
import './FileItem.scss'

/**
 * Component to render a selected file in the FileDropZone.
 * @param {Object} file: File object.
 * @param {Func} onRemove : callback to remove the current item 
 */
export const FileItemComponent = ({file, onRemove, }) => {
  return(
    <div className="file-item">
      <span className="file-icon"></span>
      <div className="info">
        <span className="name">{ file.name }</span>
        <span className="sub">{ file.type ? file.type : '-' } / { utils.getReadableFileSize(file.size) }</span>
      </div>

      <span className="btn-remove" title="Remove" onClick={ onRemove }>&#9587;</span>
    </div>
  )
}

export default FileItemComponent
