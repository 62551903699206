import LoginActionCreator from 'ducks/Auth/actions'
import cognitoUtils from 'utils/cognitoUtils'

/**
 * Blacklist of URLs to avoid kicking the user off the application when a 401 is received
 */
const URL_BLACKLIST = [
  '/users/change-email',
  '/users/change-password',
  '/payment-plans',
  '/subscriptions',
]

/**
 * Checks if the provided URL is in the blacklist
 * @param {boolean} url
 * @returns {boolean}
 */
const isURLBlacklisted = (url) => {
  let found = false

  for (let i = 0, j = URL_BLACKLIST.length; i < j && !found; ++i) {
    const regExp = new RegExp(URL_BLACKLIST[i])
    found = regExp.test(url)
  }

  return found
}

export const interceptorMiddleware = (axios) => {
  // Flag to ensure that the interceptors are only binded one time.
  let interceptorsBinded = false

  return (store) => (next) => (action) => {
    if (!interceptorsBinded) {
      interceptorsBinded = true

      axios.interceptors.response.use((response) => response, (error) => {
        console.log('interceptor...')
        const errorResponse = error.response

        if (errorResponse && (errorResponse.status === 401 || errorResponse.status === 403)
          && !isURLBlacklisted(error.config.url)) {
          store.dispatch(LoginActionCreator.logout())
        }

        return Promise.reject(error)
      })
    }
    next(action)
  }
}
