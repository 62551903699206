import React from 'react'
import ReactDOM from 'react-dom'
import { browserHistory, } from 'react-router'
import { syncHistoryWithStore, } from 'react-router-redux'
import { ThemeProvider, createTheme, } from '@mui/material/styles'
import blue from '@mui/material/colors/blue'

// eslint-disable-next-line import/extensions,import/no-unresolved
import 'material-icons'
import initStore from './store'
import { createRoutes, } from './pages'

// mdbootstrap css
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

// project css
import 'normalize.css/normalize.css'
import 'hint.css/hint.css'
import './styles/main.scss'

import { AppProvider, } from 'containers'

const theme = createTheme({
  palette: {
    primary: blue,
  },
  typography: {
    useNextVariants: true,
  },
})

// Use browser history
const store = initStore(browserHistory)
const routes = createRoutes(store)
export const syncedHistory = syncHistoryWithStore(browserHistory, store)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AppProvider
      store={store}
      routerHistory={syncedHistory}
      routes={routes}
    />
  </ThemeProvider>,
  document.getElementById('root')
)
