import React from 'react'
export default function StackPayText() {
  return (
    <svg id="stack-pay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 304 72">
      <g id="letters-stack">
        <path id="letter-s" d="M12.85,30.43c-.05.65.22,1.29.72,1.7,1.01.5,2.12.76,3.25.76l6.64.58c1.9.11,3.78.52,5.56,1.2,1.36.53,2.6,1.35,3.61,2.41.89.95,1.53,2.1,1.88,3.36.36,1.34.53,2.73.51,4.12v1.2c0,4.01-1.32,6.95-3.97,8.81-2.65,1.86-6.81,2.82-12.5,2.88h-12.29c-1.6,0-3.17-.04-4.69-.11l1.59-8.88c2.41.05,5.06.08,7.95.11h7.8c1.38.09,2.77-.08,4.08-.51.74-.42,1.16-1.25,1.05-2.09.05-.69-.21-1.37-.72-1.84-.99-.55-2.12-.81-3.25-.76l-7.08-.51c-4.01-.29-6.85-1.44-8.52-3.47-1.71-2.09-2.61-4.74-2.53-7.44v-1.37c-.03-1.62.27-3.23.88-4.73.6-1.4,1.56-2.6,2.78-3.5,1.51-1.07,3.21-1.81,5.02-2.2,2.49-.55,5.03-.8,7.58-.76h10.58l5.53.11-1.66,8.88-5.75-.07h-9.41c-1.21-.06-2.43.06-3.61.36-.67.22-1.01.81-1.01,1.77" strokeWidth="0"/>
        <path id="letter-t" d="M52.43,28.55v13.65c-.02,1.03.06,2.05.22,3.07.1.7.4,1.35.87,1.88.48.48,1.1.81,1.77.94.99.19,1.99.28,3,.25h6.76l-1.73,9.03h-6.58c-2.67.05-5.33-.18-7.94-.69-1.8-.35-3.46-1.18-4.82-2.41-1.24-1.25-2.08-2.85-2.41-4.59-.48-2.4-.7-4.85-.65-7.29V10.85l11.48-1.66v10.4h13.14l-1.73,9.03-11.37-.06Z" strokeWidth="0"/>
        <path id="letter-a" d="M104.43,49.86c.03,1.19-.12,2.39-.43,3.54-.24.89-.73,1.69-1.41,2.31-.74.61-1.62,1.02-2.56,1.2-1.26.26-2.54.38-3.83.36h-12.74c-2.27.05-4.55-.2-6.75-.76-1.61-.4-3.1-1.17-4.37-2.24-1.1-.97-1.93-2.22-2.41-3.61-.51-1.55-.75-3.18-.72-4.81v-1.41c0-4.01,1.2-6.91,3.61-8.7,2.41-1.79,6.09-2.69,11.05-2.71,1.95-.05,3.91.15,5.81.58,1.25.31,2.47.77,3.61,1.37v-.79c.01-.87-.07-1.75-.25-2.6-.15-.7-.51-1.34-1.01-1.84-.61-.56-1.36-.95-2.17-1.12-1.24-.27-2.52-.39-3.79-.36h-10.11c-1.25,0-2.41.04-3.47.11l1.66-8.88c1.4-.05,3.15-.08,5.27-.11,2.12-.02,4.57-.02,7.37,0,6.02,0,10.46,1.05,13.32,3.14,2.86,2.09,4.3,5.6,4.3,10.51v16.82ZM80.24,45.31c-.09,1.14.35,2.26,1.2,3.03,1.26.75,2.73,1.08,4.19.94h7.66v-4.19c.1-1.22-.34-2.42-1.2-3.29-.8-.7-2.47-1.05-5.02-1.05h-1.01c-1.58-.15-3.18.16-4.59.9-.88.8-1.33,1.96-1.2,3.14l-.03.51Z" strokeWidth="0"/>
        <path id="letter-c" d="M129.35,19.38h4.51c1.71,0,3.94.05,6.68.14l-1.71,9.1-2.24-.11-2.02-.07h-4.77c-1.23-.02-2.47.07-3.68.29-.87.15-1.66.57-2.27,1.2-.62.67-1.03,1.51-1.2,2.41-.24,1.38-.35,2.79-.32,4.19v3.83c-.03,1.4.08,2.81.32,4.19.16.9.57,1.74,1.2,2.41.63.62,1.44,1.04,2.31,1.2,1.19.21,2.4.31,3.61.29h5.52c1.6,0,3.4-.05,5.38-.14l-1.73,9.03-5.38.11h-4.08c-3,.06-6-.25-8.92-.94-2.14-.5-4.12-1.52-5.78-2.96-1.52-1.43-2.61-3.26-3.14-5.27-.68-2.59-.99-5.27-.94-7.94v-3.82c-.05-2.66.27-5.31.98-7.87.57-2.03,1.68-3.86,3.21-5.31,1.65-1.46,3.64-2.49,5.78-3,2.84-.68,5.76-1,8.68-.94" strokeWidth="0"/>
        <path id="letter-k" d="M158.02,33.25l12.42-13.72h13.58l-16.03,16.97,17.48,20.8h-13.94l-13.51-16.54v16.54h-11.48V3.63h11.48v29.62Z" strokeWidth="0"/>
      </g>
      <g id="letters-pay">
        <path id="letter-p" d="M226.35,42.61c0,3.19-.34,5.84-1.01,7.95-.67,2.11-1.65,3.78-2.92,5-1.28,1.22-2.85,2.08-4.73,2.57-1.88.49-4,.74-6.37.74-2.92,0-5.52-.48-7.79-1.44-2.27-.96-3.94-2.43-5.01-4.4v17.03h-2.64v-33.36c0-3.19.36-5.83,1.08-7.92.72-2.09,1.74-3.74,3.06-4.96,1.32-1.22,2.92-2.08,4.8-2.57,1.88-.49,4-.74,6.37-.74s4.35.21,6.23.63c1.88.42,3.48,1.23,4.8,2.43,1.32,1.2,2.34,2.84,3.06,4.93.72,2.09,1.08,4.78,1.08,8.06v6.05ZM223.71,36.55c0-2.82-.28-5.1-.83-6.86-.56-1.76-1.38-3.13-2.47-4.12s-2.41-1.65-3.96-2.01c-1.55-.35-3.33-.53-5.32-.53s-3.91.19-5.46.56c-1.55.38-2.86,1.07-3.93,2.08-1.07,1.01-1.87,2.39-2.4,4.15s-.8,4.05-.8,6.86v7.46c0,2.39.34,4.38,1.01,5.95.67,1.57,1.59,2.82,2.75,3.73,1.16.92,2.52,1.56,4.07,1.94,1.55.38,3.21.56,4.97.56,2.46,0,4.49-.26,6.09-.77,1.6-.52,2.86-1.34,3.79-2.46.93-1.13,1.58-2.56,1.95-4.29.37-1.74.56-3.8.56-6.19v-6.05Z" strokeWidth="0"/>
        <path id="letter-a" d="M246.74,34.86c3.38,0,6.11.35,8.17,1.06,2.06.7,3.56,1.69,4.49,2.96v-5.14c0-1.88-.2-3.45-.59-4.72s-1.07-2.26-2.02-2.99c-.95-.73-2.21-1.24-3.79-1.55-1.58-.3-3.57-.46-5.98-.46-1.62,0-3.32.01-5.08.04-1.76.02-3.41.08-4.94.18l.56-2.53c1.25-.09,2.74-.15,4.45-.18,1.71-.02,3.38-.04,5.01-.04,2.74,0,5.06.2,6.96.6,1.9.4,3.44,1.08,4.63,2.04,1.18.96,2.04,2.23,2.57,3.8.53,1.57.8,3.51.8,5.81v20.9c0,1.31-.2,2.16-.59,2.53-.39.38-1.24.56-2.54.56h-11.9c-4.69,0-8.07-.82-10.16-2.46-2.09-1.64-3.13-4.41-3.13-8.3v-.7c0-3.94,1.02-6.83,3.06-8.66,2.04-1.83,5.38-2.75,10.02-2.75ZM236.3,46.97c0,1.41.17,2.64.52,3.7.35,1.06.93,1.91,1.74,2.57.81.66,1.9,1.15,3.27,1.48,1.37.33,3.07.49,5.11.49h12.45v-9.36c0-1.74-.31-3.16-.94-4.26-.63-1.1-1.48-1.97-2.57-2.6-1.09-.63-2.39-1.07-3.9-1.3-1.51-.23-3.14-.35-4.9-.35h-.63c-3.57,0-6.16.66-7.76,1.97s-2.4,3.64-2.4,6.97v.7Z" strokeWidth="0"/>
        <path id="letter-y" d="M298.57,21.63h2.64v34.07c0,2.72-.28,5.01-.83,6.86-.56,1.85-1.45,3.34-2.68,4.47-1.23,1.13-2.84,1.94-4.83,2.43-2,.49-4.41.74-7.24.74-4.31,0-7.86-.07-10.64-.21l.56-2.53c2.41.14,5.77.21,10.09.21,2.41,0,4.45-.19,6.12-.56,1.67-.38,3-1.02,4-1.94,1-.92,1.71-2.14,2.16-3.66.44-1.53.66-3.46.66-5.81v-3.66c-1.02,1.64-2.48,2.79-4.38,3.45s-4.29.99-7.17.99-5.16-.28-6.99-.84c-1.83-.56-3.28-1.41-4.35-2.53-1.07-1.13-1.81-2.54-2.23-4.26s-.63-3.74-.63-6.09v-21.12h2.64v21.12c0,1.88.13,3.51.38,4.89.25,1.38.79,2.55,1.6,3.48.81.94,1.98,1.64,3.51,2.11,1.53.47,3.55.7,6.05.7,2.37,0,4.3-.22,5.81-.67,1.51-.45,2.68-1.13,3.51-2.04s1.41-2.08,1.74-3.48c.32-1.41.49-3.07.49-5v-21.12Z" strokeWidth="0"/>
      </g>
    </svg>
  )
}
