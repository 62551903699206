import React from 'react'
import { connect, } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Modal.scss'

/**
 * Custom modal higher-order component. Visibility to be controlled by a separate container.
 * @prop {String} title: Title of the modal
 * @prop {Function} onRequestClose: Call back function for when the close-x button is clicked
 * See defaults props.
 */
function ModalWrapper(BaseComponent) {
  const TwentyThreeModal = (props) => (
    <div className={classnames('modal-twentythree',
      {
        full: props.full,
        form: props.form,
        fixed: props.fixed,
        smallPadding: props.smallPadding
      })}>
      <div className="modal-twentythree-body">
        <div className="modal-twentythree-header">
          <h2>
            {props.title}
          </h2>
          {props.showClose && <button className="modal-twentythree-close-button" onClick={props.onRequestClose}>
            <span className='material-icons'>close</span>
          </button>}
        </div>
        <div className='modal-twentythree-content'>
          <BaseComponent {...props} />
        </div>
      </div>
    </div>
  )

  TwentyThreeModal.propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    onRequestClose: PropTypes.func,
    full: PropTypes.bool,
    form: PropTypes.bool,
    fixed: PropTypes.bool,
    smallPadding: PropTypes.bool,
    showClose: PropTypes.bool,
  }

  TwentyThreeModal.defaultProps = {
    title: '',
    full: false,
    form: false,
    fixed: false,
    smallPadding: false,
    showClose: true,
    onRequestClose: () => {},
  }

  return TwentyThreeModal
}

export default ModalWrapper
