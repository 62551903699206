import { injectReducer, injectSagas, } from 'store'


export default (store) => ({
  path: '/subscriptions-report/:accountId',


  /*  Async getComponent when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack -require callback  */
      const SubscriptionReportContainer = require('./containers/SubscriptionReportContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      /*  Add the reducer to the store on key 'dashboard'  */
      injectReducer(store, { key: 'subscriptionReport', reducer, })
      injectSagas(store, sagas)

      /*  Return getComponent   */
      cb(null, SubscriptionReportContainer)

    /* Webpack named bundle   */
    }, 'subscriptions-report')
  },
})
