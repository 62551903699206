import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/reports/invalid-1099k',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const Invalid1099KReportContainer = require('./containers/Invalid1099KReportContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'invalid1099KReport', reducer, })
      injectSagas(store, sagas)

      callback(null, Invalid1099KReportContainer)
    }, 'invalid1099KReport')
  },
})
