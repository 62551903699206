import React from 'react'
import { TextField, } from '@mui/material'
import { withStyles, } from '@mui/styles'
import styles from './styles'

const TextInput = ({
  classes,
  value,
  onChange,
  style = { width: '100%', marginTop: '30px', },
  required = false,
  disabled = false,
  error = false,
  helperText = false,
  label = false,
  max = 100000,
  onBlur = null,
  multiline = false,
  rows = 3,
}) => (
  <TextField
    style={style}
    value={value || ''}
    type="text"
    onChange={(e) => onChange(e.target.value)}
    onBlur={onBlur && onBlur}
    variant="outlined"
    required={required}
    disabled={disabled}
    error={error}
    multiline={multiline}
    rows={rows}
    helperText={helperText}
    label={label && label}
    InputLabelProps={{
      classes: {
        root: classes.cssLabel,
        shrink: classes.slimLabelShrink,
        focused: classes.cssFocused,
      },
    }}
    InputProps={{
      notched: false,
      classes: {
        root: classes.cssOutlinedInput,
        focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
      },
    }}
  />
)

export default withStyles(styles)(TextInput)
