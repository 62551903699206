import React from 'react'
import AppHeader from 'containers/AppHeader/AppHeaderContainer'
import AppFooter from 'components/AppFooter'
import Notification from 'containers/Notification/NotificationContainer'
import './CheckoutLayout.scss'

export default function CheckoutLayout({ children, props }) {
  return (<div id="app" className="checkout-layout">
    <div className='header-bg'>
      <div className='header-gradient'></div>
      <div className='header-octogons'></div>
    </div>
    <div className='main-parent'>
      <AppHeader { ...props } />
      <main id='main'>
        {React.cloneElement(children)}
      </main>
    </div>

    <AppFooter/>
    <Notification/>
  </div>)
}
