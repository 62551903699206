
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Checkbox.scss'

/**
 * Custom checkbox component.
 * @prop {Func} onCheck: Callback for the onCheck envent of the input.
 * @prop {String} className: custom class names for the component.
 * @prop {String} label : Label for the field
 * @prop {Bool} checked: If the checkbox is checked or not.
 * @prop {String} name : Input name
 */
export const CheckBoxComponent = ({ className, label, onCheck, onFocus,
  onBlur, checked, name, }) => (
    <label className={classnames('checkbox-component', { [className]: !!className, })}>
      <input
        className="checkbox"
        type="checkbox"
        checked={checked}
        onChange={onCheck || null}
        onFocus={onFocus || null}
        onBlur={onBlur || null}
        name={name}
      />
      <span className="checkbox-cover" />
      { label && <span className="label">{ label } </span> }
    </label>
  )

CheckBoxComponent.propTypes = {
  onCheck: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  name: PropTypes.string,
  checked: PropTypes.bool,
}

export default CheckBoxComponent
