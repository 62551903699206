import React, { Component } from 'react'
import _ from 'lodash'
import Loader from 'components/Loader'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { MaterialSelect } from 'components/MaterialInputs'
import { MERCHANT_STATUSES } from '../../constants'

const theme = createTheme({
  palette: {
    primary: {
      light: '#0d2b40',
      main: '#0d2240',
      dark: '#0d1740'
    },
    secondary: {
      light: '#ed2121',
      main: '#d11010',
      dark: '#910505'
    }
  },
  typography: {
    useNextVariants: true
  }
})

class ChangeStatusModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      changedStatus: '',
      closureReason: '',
      showPostSubmit: false,
    }
  }

  handleChange = (value, property) => {
    this.setState({ [property]: value })
  };

  resetState = () => {
    this.setState({
      changedStatus: '',
      closureReason: '',
      showPostSubmit: false,
    })
  };

  submit = () => {
    const { changedStatus, closureReason } = this.state
    const payload = { changedStatus, closureReason }
    this.props.onSubmit(payload)
    this.setState({ showPostSubmit: true, })
  }

  render() {
    const {
      fullScreen,
      open,
      handleClose,
      merchantStatus
    } = this.props
    const { changedStatus, closureReason, showPostSubmit } = this.state
    const currentStatus = _.find(MERCHANT_STATUSES, {
      status: merchantStatus
    })

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          this.resetState()
          handleClose()
        }}
        aria-labelledby="responsive-dialog-title"
      >
        { !currentStatus
          ? <Loader />
          : <React.Fragment>
            <DialogTitle id="responsive-dialog-title">
              {(!showPostSubmit) && <span>Account Status</span>}
              {(showPostSubmit) && <span>Account Status Change Sent</span>}
            </DialogTitle>
            {(!showPostSubmit) &&
            <div>
              <DialogContent>
                <p style={{
                  width: '360px',
                  fontSize: '14px',
                  lineHeight: '15px',
                  fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                }}>
                  <span style={{
                    color: '#666' }}>Current Status: </span>
                  <span style={{
                    color: currentStatus.color }}>{currentStatus.label}</span>
                </p>
                <MaterialSelect
                  name="changedStatus"
                  label="New Status"
                  required={true}
                  onChange={((val) => this.handleChange(val, 'changedStatus'))}
                  style={{ width: '360px', marginTop: '14px' }}
                  value={changedStatus}
                  displayEmpty={true}
                  options={[{
                    value: 1,
                    label: 'Activate'
                  }, {
                    value: 66,
                    label: 'Pending'
                  }, {
                    value: 33,
                    label: 'Closed'
                  }].filter(option => option.value !== currentStatus.status)}
                />
              </DialogContent>
              {(changedStatus === 33) &&
              <DialogContent>
                <p style={{
                  width: '360px',
                  fontSize: '14px',
                  lineHeight: '24px',
                  fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                  color: '#666'
                }}>
                    Please select a closure reason and confirm that you want to request this account to be closed.</p>
                <MaterialSelect
                  value={closureReason}
                  name="closureReason"
                  label="Closure Reason"
                  displayEmpty={true}
                  onChange={((val) => this.handleChange(val, 'closureReason'))}
                  required={true}
                  style={{ width: '360px', marginTop: '14px', marginBottom: '24px', }}
                  options={[{
                    value: 'No Longer Using',
                    label: 'No Longer Using'
                  }, {
                    value: 'Too Expensive',
                    label: 'Too Expensive'
                  }, {
                    value: 'Created in Error',
                    label: 'Created in Error'
                  }, {
                    value: 'Fraud/Suspicious Activity',
                    label: 'Fraud/Suspicious Activity'
                  }, {
                    value: 'Processor Decision',
                    label: 'Processor Decision'
                  }, {
                    value: 'Collections',
                    label: 'Collections'
                  }, {
                    value: 'Collections/Write-off',
                    label: 'Collections/Write-off'
                  }]}
                />
              </DialogContent>}
            </div>
            }
            {(((changedStatus === 1 || changedStatus === 66) || (changedStatus === 33 && closureReason))
            && !showPostSubmit) &&
              <div>
                <DialogContent>
                  <p style={{
                    width: '360px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                    color: '#666'
                  }}>
                    <p style={{
                      textAlign: 'center'
                    }}>Are you sure you want to update the status?</p></p>
                </DialogContent>

                <DialogActions>
                  <React.Fragment>
                    <ThemeProvider theme={theme}>
                      <div style={{ width: '100%', marginBottom: '10px', textAlign: 'center', }}>
                        <Button
                          onClick={() => {
                            this.resetState()
                            handleClose()
                          }}
                          color="secondary"
                          variant="contained"
                          style={{ marginRight: '10px', }}
                        >
                          No
                        </Button>
                        <Button
                          onClick={() => {
                            this.submit()
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Yes
                        </Button>
                      </div>
                    </ThemeProvider>
                  </React.Fragment>
                </DialogActions>
              </div>
            }
            {(showPostSubmit) &&
            <div>
              <DialogContent>
                <p style={{
                  width: '360px',
                  fontSize: '14px',
                  lineHeight: '24px',
                  fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                  color: '#666'
                }}>
                  Account status request has been sent to the gateway for confirmation.</p>
              </DialogContent>

              <DialogActions>
                <React.Fragment>
                  <ThemeProvider theme={theme}>
                    <div style={{ width: '100%', marginBottom: '10px', textAlign: 'center', }}>
                      <Button
                        onClick={() => {
                          if (this.state.changedStatus === 33) {
                            setTimeout(() => window.location.reload(), 7000)
                          }
                          this.resetState()
                          handleClose()
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Done
                      </Button>
                    </div>
                  </ThemeProvider>
                </React.Fragment>
              </DialogActions>
            </div>
            }
          </React.Fragment>
        }
      </Dialog>
    )
  }
}

export default ChangeStatusModal
