import _ from 'lodash'
import { validEmail, } from '../../../../utils/validations'

/**
 * Validations for `signIn` form.
 * @param {Object} fields values of the form.
 * @return {Object} with the errors. Return an empty object if all data is valid.
 */
export const validator = ({ recoveryEmail, }) => {
  const emailErrors = _.without([
    !recoveryEmail ? 'Please enter your email' : '',
    !validEmail(recoveryEmail) ? 'The email is invalid' : '',
  ], '')

  return Object.assign({},
    emailErrors.length > 0 ? { recoveryEmail: emailErrors, } : {},
  )
}

export default validator
