import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path : '/admin/users',
  getComponent (nextState, callback) {
    require.ensure([], (require) => {
      const UserAdministrationLayout = require('./components/UserAdministrationLayout').default
      callback(null, UserAdministrationLayout)
    }, 'user-administration-layout')
  },

  getIndexRoute(nextState, callback) {
    require.ensure([], (require) => {
      const UserAdministratorContainer = require('./containers/UserAdministratorContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'userAdministrator', reducer, })
      injectSagas(store, sagas)

      callback(null, { component: UserAdministratorContainer, })
    })
  },

  getChildRoutes(nextState, callback) {
    require.ensure([], (require) => {
      callback(null, [
        {
          path: '/admin/users/:id',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const UserInfoContainer = require('./containers/UserInfoContainer').default
              const reducer = require('./modules/reducer').default
              const sagas = require('./modules/sagas').default

              injectReducer(store, { key: 'userAdministrator', reducer, })
              injectSagas(store, sagas)

              callback(null, UserInfoContainer)
            }, 'user-info-view')
          }
        },
        {
          path: '/admin/users/:id/m/:mid',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const UserInfoContainer = require('./containers/UserInfoContainer').default
              const reducer = require('./modules/reducer').default
              const sagas = require('./modules/sagas').default

              injectReducer(store, { key: 'userAdministrator', reducer, })
              injectSagas(store, sagas)

              callback(null, UserInfoContainer)
            }, 'user-info-view')
          }
        },
        {
          path: '/admin/users/:id/edit/:type',
          getComponent(nextState, callback) {
            require.ensure([], (require) => {
              const UserEditionContainer = require('./containers/UserEditionContainer').default
              const reducer = require('./modules/reducer').default
              const sagas = require('./modules/sagas').default

              injectReducer(store, { key: 'userAdministrator', reducer, })
              injectSagas(store, sagas)

              callback(null, UserEditionContainer)
            }, 'user-edition')
          }
        },
      ])
    })
  },
})
