import _ from 'lodash'
import Immutable from 'seamless-immutable'
import { LoginTypes, } from './actions'
import { REHYDRATE, } from 'redux-persist/constants'
import { api, } from 'services/api'

const setAuthorizationHeader = (token) => {
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

const unsetAuthorizationHeader = () => {
  api.defaults.headers.common.Authorization = `null`
}

const saveTokensToLocalStorage = (tokens) => {
  _.keys(tokens).map((key) => {
    localStorage.setItem(key, tokens[key])
  })
}

const saveRememberToLocalStorage = () => {
  localStorage.setItem('remember', true)
}

const deleteCachedTokens = (keepRefreshToken = false) => {
  unsetAuthorizationHeader()
  localStorage.removeItem('remember')
  localStorage.removeItem('idToken')
  localStorage.removeItem('accessToken')
  if (!keepRefreshToken) {
    localStorage.removeItem('refreshToken')
  }
}

const initialState = Immutable({
  isFetching: false,
  isAuthenticated: null,
  authToken: null,
  error: null,
  recoveryError: null,
  isSendingRecoveryEmail: false,
  showResetPasswordForm: false,
  resetPasswordSuccessful: false,
  recoveryEmail: null,
  resetPasswordError: false,
  newPasswordChallenge: false,
  newPasswordChallengeSuccess: false,
  newPasswordUserAttributes: {},
})

const actionHandlers = {
  // Action from `redux-persist`. When a rehydration happens, we set the `Authorization` header
  // with the value of the token persisted in the local storage.
  [REHYDRATE]: (state, { payload, }) => {
    if (payload.auth) {
      setAuthorizationHeader(payload.auth.authToken)
    }

    return state
  },

  [LoginTypes.NEW_PASSWORD_CHALLENGE]: (state, action) => state.merge({
    isFetching: false,
    isAuthenticated: false,
    error: null,
    authToken: null,
    newPasswordChallenge: true,
    newPasswordUserAttributes: action.userData.userAttributes,
  }),

  [LoginTypes.LOGIN_REQUEST]: (state) => state.merge({
    isFetching: true,
    isAuthenticated: false,
    error: null,
    authToken: null,
  }),

  [LoginTypes.LOGIN_SUCCESS]: (state, { tokens, remember, }) => {
    setAuthorizationHeader(tokens.idToken)
    saveTokensToLocalStorage(tokens)
    if (remember) saveRememberToLocalStorage(remember)

    return state.merge({
      isFetching: false,
      isAuthenticated: true,
      authToken: tokens.idToken,
      error: null,
      resetPasswordSuccessful: false,
    })
  },

  [LoginTypes.LOGIN_FAILURE]: (state, { error, }) => state.merge({
    isFetching: false,
    isAuthenticated: false,
    error,
    result: null,
    resetPasswordSuccessful: false,
  }),

  [LoginTypes.AUTHORIZE_USER_LOGIN]: (state) => state.merge({
    isFetching: true,
    isAuthenticated: false,
    error: null,
    authToken: null,
  }),

  [LoginTypes.LOGOUT]: (state) => {
    // We delete the cached tokens
    deleteCachedTokens()
    localStorage.clear()

    return initialState
  },

  [LoginTypes.PASSIVE_LOGOUT]: (state) => {
    // We delete the cached tokens
    // - except the refresh token because other tabs that are open to stackpay might need it
    deleteCachedTokens(true)

    return initialState
  },

  [LoginTypes.LOGIN_REDIRECT]: (state) => initialState,

  [LoginTypes.RECOVER_ACCESS_REQUEST]: (state, { email, }) => state.merge({
    recoveryEmail: email.recoveryEmail,
    isSendingRecoveryEmail: true,
    showResetPasswordForm: false,
    recoveryError: null,
  }),

  [LoginTypes.RECOVER_ACCESS_SUCCESS]: (state) => state.merge({
    isSendingRecoveryEmail: false,
    showResetPasswordForm: true,
  }),

  [LoginTypes.RECOVER_ACCESS_ERROR]: (state, { error, }) => state.merge({
    isSendingRecoveryEmail: false,
    showResetPasswordForm: false,
    recoveryError: error.error.response && error.error.response.status === 400 ? error : null,
    recoveryEmail: null,
  }),

  [LoginTypes.RESET_PASSWORD_REQUEST]: (state) => state.merge({
    resetPasswordSuccessful: false,
    recoveryEmail: null,
    showResetPasswordForm: false,
    newPasswordChallenge: false,
  }),

  [LoginTypes.RESET_PASSWORD_SUCCESS]: (state) => state.merge({
    showResetPasswordForm: false,
    resetPasswordSuccessful: true,
    isFetching: false,
    recoveryEmail: null,
    newPasswordChallenge: false,
  }),

  [LoginTypes.RESET_PASSWORD_ERROR]: (state, { error, }) => state.merge({
    showResetPasswordForm: false,
    resetPasswordSuccessful: false,
    error,
    resetPasswordError: true,
    recoveryEmail: null,
    newPasswordChallenge: false,
  }),

  [LoginTypes.RESET_FORM_FLAG]: (state) => state.merge({
    isFetching: false,
    showResetPasswordForm: false,
    recoveryEmail: null,
    newPasswordChallenge: false,
  }),

  [LoginTypes.REFRESH_USER_SESSION]: (state) => state,

  [LoginTypes.REFRESH_USER_SESSION_SUCCESS]: (state, { tokens, }) => {
    setAuthorizationHeader(tokens.idToken)
    saveTokensToLocalStorage(tokens)

    return state.merge({
      authToken: tokens.idToken,
      error: null,
    })
  },

  [LoginTypes.REFRESH_USER_SESSION_ERROR]: (state, { error, }) => state.merge({ error, }),
}

export default function applicationFormReducer(state = initialState, action) {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}
