import React from 'react'
import AppHeader from 'containers/AppHeader/AppHeaderContainer'
import AppFooter from 'components/AppFooter'
import Notification from 'containers/Notification/NotificationContainer'
import './SignupLayout.scss'

export const SignupLayout = ({ children, }) =>
  (<div id="app" className="signup-layout">
    <AppHeader { ...this.props } publicMode className={'signup'} />
    <main>
      {children}
    </main>
    <Notification />
    <AppFooter />
  </div>)
