import React, { Component } from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import NavigationItem from '../NavigationItem'
import DropdownMenu from 'components/DropdownMenu'
import Icon from 'components/Icon'
import { DASHBOARD, PARTNER_PROFILES, MERCHANT_PROFILES, RATE_PROFILES, USER_ADMIN, REPORTS, TOOLS, } from './constants'
import './NavigationBar.scss'

class NavigationBar extends Component {
  state = {
    activeNavigationItem: '',
    canViewMerchant: false,
    canViewPartner: false,
    canViewRate: false,
    canViewUser: false,
    canMigrateMerchants: false,
    canPerformPayout: false,
    canManageCTAs: false,
    canViewTransactionReport: false,
    canViewDepositReport: false,
    canViewProcessorReconciliationReport: false,
    canViewChargebackReport: false,
    canViewOutageReport: false,
    canViewFundingHoldReport: false,
    canViewInvalid1099KReport: false,
    canViewEmailHistoryReport: false,
    canViewLimitReport: false,
    canResidualFee: false,
    canManageBulkOperations: false,
  }

  _onNavigationItemClick = (navigationItem) => {
    const { activeNavigationItem, } = this.state

    if (activeNavigationItem !== navigationItem) {
      this.setState({ activeNavigationItem: navigationItem, })
    }

    if (navigationItem !== REPORTS && navigationItem !== TOOLS) {
      this.props.onNavItemClick(navigationItem) // propagate up
    } else {
      this.props.onMenuOpen(navigationItem) // propagate up
    }
  }

  componentDidMount() {
    if (!this.props.adminRoles) {
      this.props.fetchAdminRoles()
    } else {
      this.setState({
        canViewMerchant: Object.keys(this.props.adminRoles).includes('can-view-merchant-info'),
        canViewPartner: Object.keys(this.props.adminRoles).includes('can-view-partners'),
        canViewRate: Object.keys(this.props.adminRoles).includes('can-view-rates'),
        canViewUser: Object.keys(this.props.adminRoles).includes('can-view-users'),
        canViewTransactionReport: Object.keys(this.props.adminRoles).includes('can-view-transaction-report'),
        canViewDepositReport: Object.keys(this.props.adminRoles).includes('can-view-deposit-report'),
        canViewProcessorReconciliationReport:
          Object.keys(this.props.adminRoles).includes('can-view-processor-reconciliation-report'),
        canViewChargebackReport: Object.keys(this.props.adminRoles).includes('can-view-chargeback-report'),
        canViewOutageReport: Object.keys(this.props.adminRoles).includes('can-view-outage-report'),
        canViewFundingHoldReport: Object.keys(this.props.adminRoles).includes('can-view-funding-hold-report'),
        canViewInvalid1099KReport: Object.keys(this.props.adminRoles).includes('can-view-invalid-1099k-report'),
        canViewEmailHistoryReport: Object.keys(this.props.adminRoles).includes('can-view-email-history-report'),
        canViewLimitReport: Object.keys(this.props.adminRoles).includes('can-view-limit-report'),
        canResidualFee: Object.keys(this.props.adminRoles).includes('can-residual-fee'),
        canViewPendingAccountsReport: Object.keys(this.props.adminRoles).includes('can-view-pending-accounts-report'),
        canMigrateMerchants: Object.keys(this.props.adminRoles).includes('can-migrate-merchants'),
        canPerformPayout: Object.keys(this.props.adminRoles).includes('can-perform-payout'),
        canManageCTAs: Object.keys(this.props.adminRoles).includes('can-manage-ctas'),
        canManageBulkOperations: Object.keys(this.props.adminRoles).includes('can-bulk-operations'),
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminRoles && prevProps.adminRoles !== this.props.adminRoles) {
      this.setState({
        canViewMerchant: Object.keys(this.props.adminRoles).includes('can-view-merchant-info'),
        canViewPartner: Object.keys(this.props.adminRoles).includes('can-view-partners'),
        canViewRate: Object.keys(this.props.adminRoles).includes('can-view-rates'),
        canViewUser: Object.keys(this.props.adminRoles).includes('can-view-users'),
        canViewTransactionReport: Object.keys(this.props.adminRoles).includes('can-view-transaction-report'),
        canViewDepositReport: Object.keys(this.props.adminRoles).includes('can-view-deposit-report'),
        canViewProcessorReconciliationReport:
          Object.keys(this.props.adminRoles).includes('can-view-processor-reconciliation-report'),
        canViewChargebackReport: Object.keys(this.props.adminRoles).includes('can-view-chargeback-report'),
        canViewOutageReport: Object.keys(this.props.adminRoles).includes('can-view-outage-report'),
        canViewFundingHoldReport: Object.keys(this.props.adminRoles).includes('can-view-funding-hold-report'),
        canViewInvalid1099KReport: Object.keys(this.props.adminRoles).includes('can-view-invalid-1099k-report'),
        canViewEmailHistoryReport: Object.keys(this.props.adminRoles).includes('can-view-email-history-report'),
        canViewLimitReport: Object.keys(this.props.adminRoles).includes('can-view-limit-report'),
        canResidualFee: Object.keys(this.props.adminRoles).includes('can-residual-fee'),
        canViewPendingAccountsReport: Object.keys(this.props.adminRoles).includes('can-view-pending-accounts-report'),
        canMigrateMerchants: Object.keys(this.props.adminRoles).includes('can-migrate-merchants'),
        canPerformPayout: Object.keys(this.props.adminRoles).includes('can-perform-payout'),
        canManageCTAs: Object.keys(this.props.adminRoles).includes('can-manage-ctas'),
        canManageBulkOperations: Object.keys(this.props.adminRoles).includes('can-bulk-operations'),
      })
    }

    if (this.props.activeLink !== prevProps.activeLink && this.props.activeLink) {
      const { activeNavigationItem, } = this.state
      if (activeNavigationItem !== this.props.activeLink) {
        this.setState({ activeNavigationItem: this.props.activeLink, })
      }
    }
  }

  render() {
    const { activeNavigationItem, canViewMerchant, canViewPartner, canViewRate, canViewUser, canMigrateMerchants,
      canViewTransactionReport, canViewDepositReport, canViewProcessorReconciliationReport,
      canViewChargebackReport, canViewOutageReport, canViewFundingHoldReport, canViewInvalid1099KReport,
      canViewEmailHistoryReport, canViewLimitReport, canViewPendingAccountsReport, canPerformPayout,
      canManageCTAs, canResidualFee, canManageBulkOperations,
    } = this.state

    return (
      <div className='navigation-bar no-print'>
        <NavigationItem
          isActive={activeNavigationItem === DASHBOARD}
          name={DASHBOARD}
          url='/admin'
          onClick={(navigationItem) => { this._onNavigationItemClick(navigationItem) }}
          render={() => (
            <Link to='/admin'>
              <span className='nav-icon material-icons mi-outlined'>home</span>
              <span className='link-label'>Dashboard</span>
            </Link>
          )}
        />

        {canViewMerchant &&
          <NavigationItem
            isActive={activeNavigationItem === MERCHANT_PROFILES}
            name={MERCHANT_PROFILES}
            url='/admin/merchants'
            onClick={(navigationItem) => { this._onNavigationItemClick(navigationItem) }}
            render={(isActive) => (
              <Link to='/admin/merchants'>
                <span className='nav-icon material-icons mi-outlined'>home_work</span>
                <span className='link-label'>Merchants</span>
              </Link>
            )}
          />
        }

        {canViewPartner &&
          <NavigationItem
            isActive={activeNavigationItem === PARTNER_PROFILES}
            name={PARTNER_PROFILES}
            url='/admin/partners'
            onClick={(navigationItem) => { this._onNavigationItemClick(navigationItem) }}
            render={(isActive) => (
              <Link to='/admin/partners'>
                <span className='nav-icon material-icons mi-outlined'>business_center</span>
                <span className='link-label'>Partners</span>
              </Link>
            )}
          />
        }

        {canViewRate &&
          <NavigationItem
            isActive={activeNavigationItem === RATE_PROFILES}
            name={RATE_PROFILES}
            url='/admin/rates'
            onClick={(navigationItem) => { this._onNavigationItemClick(navigationItem) }}
            render={(isActive) => (
              <React.Fragment>
                <Link to='/admin/rates'>
                  <span className='nav-icon material-icons mi-outlined'>attach_money</span>
                  <span className='link-label'>Rates</span>
                </Link>
              </React.Fragment>
            )}
          />
        }

        <NavigationItem
          isActive={activeNavigationItem === REPORTS}
          name={REPORTS}
          url='/admin/reports/'
          onClick={(navigationItem) => { this._onNavigationItemClick(navigationItem) }}
          render={(isActive) => (
            <React.Fragment>
              <div className='label-wrapper'>
                <span className='nav-icon material-icons mi-outlined'>analytics</span>
                <span className='link-label'>Reports</span>
                <Icon iconName='icon-down-open-big'/>
              </div>
              <DropdownMenu
                isVisible={isActive}
                items={[
                  <div>
                    {canViewTransactionReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/transaction-details`}>
                        Transaction Details
                      </Link>
                    }
                  </div>,
                  <div>
                    {canViewDepositReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/payouts`}>
                        Payouts
                      </Link>
                    }
                  </div>,
                  <div>
                    {canViewProcessorReconciliationReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/processor-reconciliation`}>
                        Processor Reconciliation
                      </Link>
                    }
                  </div>,
                  <div>
                    {canViewChargebackReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/disputes`}>
                        Disputes
                      </Link>
                    }
                  </div>,
                  <div>
                    {canViewOutageReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/outage-transactions`}>
                        Outage Transactions
                      </Link>
                    }
                  </div>,
                  <Link
                    onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                    to={`/admin/reports/interchange`}>
                    Interchange Report
                  </Link>,
                  <div>
                    {canViewEmailHistoryReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/email-history`}>
                        Email History
                      </Link>
                    }
                  </div>,
                  <div>
                    {canViewFundingHoldReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/funding-hold`}>
                        Funding Hold
                      </Link>
                    }
                  </div>,
                  <div>
                    {canViewInvalid1099KReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/invalid-1099k`}>
                        Invalid 1099K
                      </Link>
                    }
                  </div>,
                  <div>
                    {canViewPendingAccountsReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/pending-accounts`}>
                        Pending Accounts
                      </Link>
                    }
                  </div>,
                  <div>
                    {canViewLimitReport &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/limits`}>
                        Limits
                      </Link>
                    }
                  </div>,
                  <Link
                    onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                    to={`/admin/reports/partner-volume`}>
                    Volume by Partner
                  </Link>,
                  <div>
                    {canResidualFee &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                        to={`/admin/reports/residual-fees`}>
                        Residual Fees
                      </Link>
                    }
                  </div>,
                  <Link
                    onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('reports-sub') }}
                    to={`/admin/reports/subscriptions`}>
                    Subscriptions
                  </Link>,
                ]}
              />
            </React.Fragment>
          )}
        />

        <NavigationItem
          isActive={activeNavigationItem === TOOLS}
          name={TOOLS}
          url='/admin/tools/'
          onClick={(navigationItem) => { this._onNavigationItemClick(navigationItem) }}
          render={(isActive) => (
            <React.Fragment>
              <div className='label-wrapper'>
                <span className='nav-icon material-icons mi-outlined'>handyman</span>
                <span className='link-label'>Tools</span>
                <Icon iconName='icon-down-open-big'/>
              </div>
              <DropdownMenu
                isVisible={isActive}
                items={[
                  <div>
                    {canMigrateMerchants &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('tools-sub') }}
                        to={`/admin/tools/migrate-accounts`}>
                        Processor Migration
                      </Link>
                    }
                  </div>,
                  <div>
                    {canPerformPayout &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('tools-sub') }}
                        to={`/admin/tools/platform-payout`}>
                        Platform Payout
                      </Link>
                    }
                  </div>,
                  <div>
                    {canManageCTAs &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('tools-sub') }}
                        to={`/admin/tools/ctas`}>
                        Call to Action Manager
                      </Link>
                    }
                  </div>,
                  <div>
                    {canManageBulkOperations &&
                      <Link
                        onClick={(e) => { e.stopPropagation(); this._onNavigationItemClick('tools-sub') }}
                        to={`/admin/bulk-tools`}>
                        Bulk Operations
                      </Link>
                    }
                  </div>
                ]}
              />
            </React.Fragment>
          )}
        />

        {canViewUser &&
          <NavigationItem
            isActive={activeNavigationItem === USER_ADMIN}
            name={USER_ADMIN}
            url='/admin/users'
            onClick={(navigationItem) => { this._onNavigationItemClick(navigationItem) }}
            render={(isActive) => (
              <Link to='/admin/users'>
                <span className='nav-icon material-icons mi-outlined'>group</span>
                <span className='link-label'>Users</span>
              </Link>
            )}
          />
        }
      </div>
    )
  }
}

export default NavigationBar
