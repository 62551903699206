import { connect, } from 'react-redux'
import AccountActionCreators from 'ducks/Account/actions'
import NavigationBar from '../../components/NavigationBar'

const mapStateToProps = ({ account, }) => {
  return ({
    merchantList: account.data.merchants,
    adminRoles: account.roles
  })
}

const mapDispatchToProps = (dispatch) => ({
  fetchAccountRequest: () => dispatch(AccountActionCreators.fetchAccountRequest()),
  fetchAdminRoles: () => dispatch(AccountActionCreators.fetchAdminRoles()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
