import React from 'react'
import Notification from 'containers/Notification/NotificationContainer'

const BlankLayout = ({ children, }) => (
  (<div id="app" className="blank-layout">
    <main>
      {children}
    </main>
    <Notification />
  </div>
  ))

export default BlankLayout
