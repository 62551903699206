import {
    validInsuranceNumber,
    validZipCodes,
    validPostalCodes,
    validDateOfBirth,
    validPhoneNumbers,
    validSSNNumber,
    validSSNLast4Number,
} from 'utils/validations'

const CTAValidators = {
  businessEin: {
    name: 'businessEin',
    validate: (value) => {
      if (!value) return { name: 'businessEin', message: 'You must enter a Tax ID/EIN' }
      if (!validInsuranceNumber(value.replaceAll('-', ''))) {
        return { name: 'businessEin', message: 'Tax ID/EIN must be 9 digits' }
      }
    },
  },

  businessLegalName: {
    name: 'businessLegalName',
    validate: (value) => {
      if (!value) return { name: 'businessLegalName', message: 'You must enter a Legal Name' }
    },
  },

  businessAddress1: {
    name: 'businessAddress1',
    validate: (value) => {
      if (!value) return { name: 'businessAddress1', message: 'You must enter an Address' }
      if (/(P(\.|ost)?[\s_-]*O(\.|ffice)?[\s_-]*Box[\s_-]*\d+|\bP(\.|ost)?[\s_-]*Box[\s_-]*\d+)\b/i.test(value)) {
        return { name: 'businessAddress1', message: 'This must be a physical address and P.O. Boxes or Paid Addresses are not allowed.' }
      }
    },
  },

  businessAddress2: {
    name: 'businessAddress2',
    validate: (value) => {
      if (value && (value.split('').filter((letter, i, arr) => arr.indexOf(letter) === i).join('') === ' ')) {
        return { name: 'businessAddress2', message: 'An Address containing only spaces is not a valid address.', }
      }
    },
  },

  businessCity: {
    name: 'businessCity',
    validate: (value) => {
      if (!value) return { name: 'businessCity', message: 'You must enter a City' }
    },
  },

  businessState: {
    name: 'businessState',
    validate: (value) => {
      if (!value) return { name: 'businessState', message: 'You must enter a State' }
    },
  },

  businessZip: {
    name: 'businessZip',
    validate: (value) => {
      if (!value) return { name: 'businessZip', message: 'You must enter a Postal Code' }
      else if (!validZipCodes(value)) return { name: 'businessZip', message: 'You must enter a valid ZIP/Postal code' }
    },
  },

  firstName: {
    name: 'firstName',
    validate: (value) => {
      if (!value) return { name: 'firstName', message: 'You must enter a First Name' }
    },
  },

  lastName: {
    name: 'lastName',
    validate: (value) => {
      if (!value) return { name: 'lastName', message: 'You must enter a Last Name' }
    },
  },

  contactAddress1: {
    name: 'contactAddress1',
    validate: (value) => {
      if (!value) return { name: 'contactAddress1', message: 'You must enter an Address' }
    },
  },

  contactAddress2: {
    name: 'contactAddress2',
    validate: (value) => {
      if (value && (value.split('').filter((letter, i, arr) => arr.indexOf(letter) === i).join('') === ' ')) {
        return { name: 'contactAddress2', message: 'An address containing only spaces is not a valid address.', }
      }
    },
  },

  contactCity: {
    name: 'contactCity',
    validate: (value) => {
      if (!value) return { name: 'contactCity', message: 'You must enter a City' }
    },
  },

  contactState: {
    name: 'contactState',
    validate: (value) => {
      if (!value) return { name: 'contactState', message: 'You must enter a State' }
    },
  },

  contactZip: {
    name: 'contactZip',
    validate: (value) => {
      if (!value) return { name: 'contactZip', message: 'You must enter a Postal Code' }
      else if (!validZipCodes(value)) return { name: 'contactZip', message: 'You must enter a valid ZIP/Postal code' }
    },
  },

  contactDOB: {
    name: 'contactDOB',
    validate: (value) => {
      if (!value) return { name: 'contactDOB', message: 'You must enter a Date of Birth' }
      if (!validDateOfBirth(value)) return { name: 'contactDOB', message: 'Value must be mm/dd/yyyy' }
    },
  },

  contactSsn: {
    name: 'contactSsn',
    validate: (value) => {
      const cleanValue = value.replace(/-/g, '')
      if (!cleanValue) return { name: 'contactSsn', message: 'Invalid format, please enter a valid Social Security Number' }
      if (!validSSNNumber(cleanValue)) return { name: 'contactSsn', message: 'You must enter 9 digits' }

      // Check for zeros
      const segments = cleanValue.match(/(\d{3})(\d{2})(\d{4})/)
      if (segments && (segments[1] === '000' || segments[2] === '00' || segments[3] === '0000')) {
        return { name: 'contactSsn', message: 'Invalid format, please enter a valid Social Security Number' }
      }

      // Check for 9 identical digits
      if (/^(\d)\1{8}$/.test(cleanValue)) {
        return { name: 'contactSsn', message: 'Invalid format, please enter a valid Social Security Number' }
      }

      // Check for consecutive digits 1-9 or 9-1
      const consecutive = ['123456789', '987654321']
      if (consecutive.includes(cleanValue)) {
        return { name: 'contactSsn', message: 'Invalid format, please enter a valid Social Security Number' }
      }
    },
  },

  contactSsnLast4: {
    name: 'contactSsnLast4',
    validate: (value) => {
      if (!value) return { name: 'contactSsnLast4', message: 'You must enter the last 4 digits of your SSN' }
      if (!validSSNLast4Number(value)) return { name: 'contactSsnLast4', message: 'You must enter only 4 digits' }
      if (value === '0000') return { name: 'contactSsnLast4', message: 'Value cannot be "0000"' }
    },
  },
}

export default CTAValidators
