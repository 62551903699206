import React from 'react'
import { TextField, } from '@mui/material'
import { withStyles, } from '@mui/styles'
import styles from './styles'

const IntegerInput = ({
  classes,
  value,
  onChange,
  style = { width: '100%', marginTop: '30px', },
  required = false,
  disabled = false,
  error = false,
  helperText = false,
  label = false,
  max = 100000,
  min = 0,
}) => (
    <TextField
      style={style}
      value={value || ''}
      type="number"
      onChange={(e) => onChange(Math.round(Number(e.target.value.toString().replace(/([^0-9]+)/gi, ''))))}
      variant="outlined"
      required={required}
      disabled={disabled}
      error={error}
      helperText={helperText}
      label={label && label}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          shrink: classes.slimLabelShrink,
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        inputProps: { min, max, },
        notched: false,
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
      }}
    />
  )

export default withStyles(styles)(IntegerInput)
