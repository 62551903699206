import { injectReducer, injectSagas } from 'store'

export default (store) => ({
  path: '/subscription-details/:accountId/:subscriptionId',
  getComponent(nextState, cb) {
    require.ensure([], (require) => {
      const SubscriptionReportContainer = require('./containers/SubscriptionDetailContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'subscriptionDetail', reducer })
      injectSagas(store, sagas)

      cb(null, SubscriptionReportContainer)
    }, 'subscriptionDetail')
  }
})
