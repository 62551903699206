import { injectReducer, injectSagas, } from 'store'

export default (store) => ({
  path: '/admin/tools/ctas/:id/edit',
  getComponent(nextState, callback) {
    require.ensure([], (require) => {
      const CTAEditContainer = require('./containers/CTAEditContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      injectReducer(store, { key: 'ctaEdit', reducer, })
      injectSagas(store, sagas)

      callback(null, CTAEditContainer)
    }, 'ctaEdit')
  }
})
