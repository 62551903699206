import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import Button from 'components/Button'
import FileItem from './FileItem'
import './FileDropZone.scss'

/**
 * Component with a drop zone for select or drag/drop files
 * @prop {String} buttonTxt: text for the button.
 * @prop {String} message: message for the dropzone area.
 * @prop {String} addMoreTxt: message for the add more file button.
 * @prop {Boolean} multiple : if allow or not multiple file selection.
 * @prop {Func} onChange: callback when a file its added o removed.
 */
class FileDropZone extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      files: []
    }
  }

  _onDrop = (files) => {
    let newFiles = files

    if (this.props.multiple) {
      newFiles = files.concat(this.state.files)
    }

    this.setState({ files: newFiles, }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.files)
      }
    })
  }

  _removeFile = (idx) => {
    let files = this.state.files
    files.splice(idx, 1)

    this.setState({ files, }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.files)
      }
    })
  }

  render() {
    const { message, buttonTxt, multiple, addMoreTxt, } = this.props
    const { files, } = this.state

    return (
      <div className="file-dropzone">
        {
          files.length > 0 &&
            <div className="file-queue">
              {
                files.map((file, key) =>
                  <FileItem key={key} file={file} onRemove={() => { this._removeFile(key) }} />
                )
              }
            </div>
        }

        <Dropzone
          className="dropzone"
          onDrop={ (files) => { this._onDrop(files) } }
          multiple={ multiple }
        >
          {
            files.length === 0 ?
              <div className="upload-message">
                <span className="icon"></span>
                <Button primary onClick={(e) => {e.preventDefault}}>{ buttonTxt }</Button>
                <p>{ message }</p>
              </div> :
              <div className="upload-message-mini">
                <span className="icon"></span>
                <div className="message-content">
                  <span>{ addMoreTxt }</span>
                </div>
              </div>
          }
        </Dropzone>
      </div>
    )
  }
}

FileDropZone.propTypes = {
  buttonTxt : PropTypes.string,
  message   : PropTypes.string,
  addMoreTxt: PropTypes.string,
  multiple  : PropTypes.bool,
  onChange  : PropTypes.func.isRequired,
}

FileDropZone.defaultProps = {
  buttonTxt : 'Chose files to upload',
  message   : 'or drop a file here for upload.',
  addMoreTxt: 'Add more files',
  multiple  : true,
}

export default FileDropZone
