import Immutable from 'seamless-immutable'
import { AccountActionTypes, } from './actions'

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [AccountActionTypes.SET_ACCOUNT_DROPDOWN]: (state, { data, }) => state.merge({
    isFetching: true,
    selectedMerchant: data,
  }),

  [AccountActionTypes.FETCH_ACCOUNT_REQUEST]: (state) => state.merge({
    isFetching: true,
    data: {},
  }),

  [AccountActionTypes.FETCH_ACCOUNT_SUCCESS]: (state, { data, }) => state.merge({
    isFetching: false,
    expiredToken: false,
    data,
  }),

  [AccountActionTypes.FETCH_ACCOUNT_INVALID]: (state, { data, }) => state.merge({
    isFetching: false,
    errors: data,
    turnstileUserData: { email: data.error_message.email },
    notFound: data.status == 404,
  }),

  [AccountActionTypes.FETCH_ACCOUNT_FAILURE]: (state, { error, }) => state.merge({
    isFetching: false,
    errors: error,
    expiredToken: error.status == 401,
  }),

  [AccountActionTypes.UPDATE_LAST_LOGIN]: (state) => state.merge({
    isFetching: true,
    data: {},
  }),

  [AccountActionTypes.UPDATE_LAST_LOGIN_FAILURE]: (state, { error, }) => state.merge({
    isFetching: false,
    errors: error,
    expiredToken: error.status == 401,
    notFound: error.status == 404,
  }),

  [AccountActionTypes.SEND_MERCHANT_TOKEN_REQUEST]: (state) => state.merge({
    sendingToken: true,
    errors: {},
  }),

  [AccountActionTypes.SEND_MERCHANT_TOKEN_SUCCESS]: (state) => state.merge({
    sendingToken: false,
    expiredToken: false,
  }),

  [AccountActionTypes.SEND_MERCHANT_TOKEN_FAILURE]: (state, { error, }) => state.merge({
    sendingToken: false,
    errors: error,
  }),

  [AccountActionTypes.CLEAR_ACCOUNT]: (state) => state.merge({
    isFetching: false,
    data: {},
    errors: null,
    expiredToken: false,
    sendingToken: false,
  }),

  [AccountActionTypes.UPDATE_ACCOUNT_SUCCESS]: (state, { data, }) => {
    const fullUser = state.data.merge({
      profile: data,
    })
    return state.merge({
      data: fullUser,
    })
  },

  [AccountActionTypes.UPDATE_EMAIL_SUCCESS]: (state, { data, }) => {
    const newProfile = state.data.profile.merge({
      login: data,
      email: data,
    })

    const fullUser = state.data.merge({
      profile: newProfile,
    })

    return state.merge({
      data: fullUser,
    })
  },

  [AccountActionTypes.VALIDATE_TOKEN]: (state) => state.merge({
    validatingToken: true,
  }),

  [AccountActionTypes.VALIDATE_TOKEN_SUCCESS]: (state) => state.merge({
    validatingToken: false,
    validToken: true,
  }),

  [AccountActionTypes.VALIDATE_TOKEN_FAILURE]: (state, { error, }) => state.merge({
    validatingToken: false,
    validToken: false,
    errors: error.data,
  }),
  [AccountActionTypes.ACCESS_RULES_REQUEST]: (state) => state.merge({
    isFetching: true,
  }),
  [AccountActionTypes.ACCESS_RULES_SUCCESS]: (state, { result, }) => state.merge({
    isFetching: false,
    accessRules: result,
  }),
  [AccountActionTypes.ACCESS_RULES_FAILURE]: (state, { error, }) => state.merge({
    isFetching: false,
    errors: error,
  }),
  [AccountActionTypes.PERMISSIONS_REQUEST]: (state) => state.merge({
    isFetching: true,
  }),
  [AccountActionTypes.PERMISSIONS_SUCCESS]: (state, { result, }) => state.merge({
    isFetching: false,
    permissions: result,
  }),
  [AccountActionTypes.PERMISSIONS_FAILURE]: (state, { error, }) => state.merge({
    isFetching: false,
    errors: error,
  }),

  [AccountActionTypes.FETCH_ADMIN_ROLES]: (state) => state.merge({
    isFetching: true,
  }),

  [AccountActionTypes.FETCH_ADMIN_ROLES_ALREADY_FETCHED]: (state) => state.merge({
    isFetching: false,
  }),

  [AccountActionTypes.FETCH_ADMIN_ROLES_SUCCESS]: (state, { data, }) => {
    const roles = {}
    data.forEach((element) => {
      roles[element.slug] = true
    })
    return state.merge({
      isFetching: true,
      roles
    })
  },

  [AccountActionTypes.FETCH_ADMIN_ROLES_ERROR]: (state, { error, }) => state.merge({
    isFetching: false,
    errors: error
  }),

  [AccountActionTypes.FETCH_SITE_SETTINGS]: (state) => state.merge({
    isFetching: true,
    settings: {},
  }),

  [AccountActionTypes.FETCH_SITE_SETTINGS_SUCCESS]: (state, { data, }) => {
    const settings = []

    data.map((s) => {
      settings[Object.keys(s)[0]] = Object.values(s)[0]
      return s
    })

    return state.merge({
      isFetching: false,
      settings: { loaded: true, ...settings },
    })
  },

  [AccountActionTypes.FETCH_SITE_SETTINGS_ERROR]: (state, { error, }) => state.merge({
    isFetching: false,
    errors: error
  }),


  [AccountActionTypes.SET_MERCHANT_ACCOUNT_ID]: (state) => state.merge({
    selectedMerchantAccountId: null,
  }),

  [AccountActionTypes.SET_MERCHANT_ACCOUNT_ID_SUCCESS]: (state, { accountId, }) => state.merge({
    selectedMerchantAccountId: parseInt(accountId, 10),
  }),

  [AccountActionTypes.SET_MERCHANT_ACCOUNT_ID_ERROR]: (state, { error, }) => state.merge({
    errors: error
  }),

  [AccountActionTypes.GET_SELECTED_MERCHANT_ACCOUNT_ID]: (state) => state,

  [AccountActionTypes.GET_SELECTED_MERCHANT_ACCOUNT_ID_SUCCESS]: (state) => state.merge({
    selectedMerchantAccountId: state.selectedMerchantAccountId,
  }),

  [AccountActionTypes.GET_SELECTED_MERCHANT_ACCOUNT_ID_ERROR]: (state, { error, }) => state.merge({
    errors: error
  }),

  [AccountActionTypes.UPDATE_MERCHANT_USER_SETTINGS]: (state) => state.merge({}),
  [AccountActionTypes.UPDATE_MERCHANT_USER_SETTINGS_FAILURE]: (state, { error, }) => state.merge({
    errors: error,
    expiredToken: error.status == 401,
    notFound: error.status == 404,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = Immutable({
  isFetching: false,
  data: {},
  roles: {},
  settings: {},
  userSettings: {},
  turnstileUserData: {},
  errors: null,
  expiredToken: false,
  notFound: false,
  sendingToken: false,
  validatingToken: false,
  validToken: false,
  selectedMerchant: null,
  selectedMerchantAccountId: null,
  accessRules: null,
  permissions: null,
})

export default function accountReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
