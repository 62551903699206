import { connect, } from 'react-redux'
import NotificationActionCreators from 'ducks/Notifications/actions'
import MerchantActionCreators from 'ducks/Merchant/actions'
import AccountActionCreators from 'ducks/Account/actions'
import BulkToolsView from '../../pages/BulkTools/components/BulkToolsView'

const mapStateToProps = ({ merchant, account }) => ({
  adminRoles: account.roles,
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(BulkToolsView)
